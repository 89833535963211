<app-header></app-header>
<h1>Admin</h1>

<!-- <div>
  <input type="text" [(ngModel)]="permission" />
  <button class="button" (click)="authorizeRoot()">Authorize Root</button>
</div> -->

<div>
  <h2>Global Permissions Modification</h2>
  For every location where a user
  <select [(ngModel)]="conditionalPermissions.action">
    <option value="conditionalGrant">has</option>
    <option value="conditionalRevoke">does not have</option>
  </select>
  permission to <input type="text" [(ngModel)]="conditionalPermissions.findKey" />, {{conditionalPermissions.action}} <input type="text" [(ngModel)]="conditionalPermissions.changeKey" /> permission on that location.
  <div>
    <button class="button" (click)="conditionalPermissionsUpdate(false)">Test Permission Modification</button>
  </div>
  <div>
    <button class="button red" (click)="conditionalPermissionsUpdate(true)">Run Permission Modification</button>
  </div>
</div>

<div>
  <h2>Test Route</h2>
  <input type="text" [(ngModel)]="testRoute" />
  <button class="button" (click)="postToRoute()">Post to Route</button>
  <!-- <button class="button" (click)="postToRepeatedly()">Post Repeatedly</button> -->
</div>

<!-- <div class="socket-test">
  <h2>Test Web Socket</h2>
  <div>
    <textarea [(ngModel)]="socketInput"></textarea>
  </div>
  <div>
    Speed: <input type="number" [(ngModel)]="socketSpeed" />
  </div>
  <button class="button" (click)="testSocket()">Test Socket</button>
  <button class="button" (click)="continuousSocketTest()">Continuous Test</button>
</div> -->


<div class="traffic-simulator">
  <h2>Traffic Simuator</h2>
  <div>
    Average Sales per Minute <input type="number" [(ngModel)]="trafficSimulator.averageSalesPerMinute" />
  </div>
  <div>{{trafficSimulator.purchaseCount | number}}</div>
  <div class="log"></div>
  <button class="button" (click)="startTrafficSimulator()">Start</button>
  <button class="button red" (click)="stopTrafficSimulator()">Stop</button>
</div>

<!-- <div>
  <button class="button" (click)="generateMonthEnd()">Generate Studio Sales & Royalty Summary</button>
</div> -->
<!-- <div>
  <h2>Import from Remote</h2>
  <label>Minimum Purchase ID</label>
  <input type="text" [(ngModel)]="importConfig.minId" />
  <label># of Records</label>
  <input type="number" [(ngModel)]="importConfig.limit" />
  <button class="button" (click)="remoteExport()">Start</button>
</div> -->
<!-- <div>
  <h2>Remote Add Promo Code</h2>
  <label>Name</label>
  <input type="text" [(ngModel)]="promoConfig.name" />
  <label>Description</label>
  <input type="text" [(ngModel)]="promoConfig.description" />
  <label>Amount</label>
  $<input type="text" [(ngModel)]="promoConfig.amount" />
  <label>Valid for X Days</label>
  <input type="number" [(ngModel)]="promoConfig.validDayCount" />
  <button class="button" (click)="addRemotePromo()">Add Promo</button>
</div> -->

<!-- <div>
  <h2>Batch Controls</h2>
  <button class="button" (click)="closeBatch()">Close Batch</button>
</div> -->

<!-- <div>
  <h2>Unimport</h2>
  <input #unimport class="invisible" type="file" name="import" (change)="onFileChange($event)" />
  <div *ngIf="!unimportingTransactions" class="button" (click)="unimportTransactions()">Unimport Auth.net Transacton .CSV</div>
</div>

<div>
  <button class="button" (click)="patchAccountsType()">patchAccountsType</button>
</div>
<div>
  <button class="button" (click)="patchAccountsLocationId()">patchAccountsLocationId</button>
</div>
<div>
  <button class="button" (click)="patchCustomerPhone()">patchCustomerPhone</button>
</div> -->
