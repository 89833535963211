import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HistoryService } from '../../../services/history/history.service';
import { IoService } from '../../../services/io/io.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';
import { LocationService } from '../../../services/location/location.service';
import { ReportService } from '../../../services/report/report.service'

@Component({
  selector: 'app-detail-customer',
  templateUrl: './detail-customer.component.html',
  styleUrls: ['./detail-customer.component.scss']
})
export class DetailCustomerComponent implements OnInit {

  documentId$: Observable<any>;
  documentId;

  showBackButton;

  user;

  states = [
    { name: 'Alabama', key: 'AL' },
    { name: 'Alaska', key: 'AK' },
    { name: 'Arizona', key: 'AZ' },
    { name: 'Arkansas', key: 'AR' },
    { name: 'California', key: 'CA' },
    { name: 'Colorado', key: 'CO' },
    { name: 'Connecticut', key: 'CT' },
    { name: 'Delaware', key: 'DE' },
    { name: 'Florida', key: 'FL' },
    { name: 'Georgia', key: 'GA' },
    { name: 'Hawaii', key: 'HI' },
    { name: 'Idaho', key: 'ID' },
    { name: 'Illinois', key: 'IL' },
    { name: 'Indiana', key: 'IN' },
    { name: 'Iowa', key: 'IA' },
    { name: 'Kansas', key: 'KS' },
    { name: 'Kentucky', key: 'KY' },
    { name: 'Louisiana', key: 'LA' },
    { name: 'Maine', key: 'ME' },
    { name: 'Maryland', key: 'MD' },
    { name: 'Massachusetts', key: 'MA' },
    { name: 'Michigan', key: 'MI' },
    { name: 'Minnesota', key: 'MN' },
    { name: 'Mississippi', key: 'MS' },
    { name: 'Missouri', key: 'MO' },
    { name: 'Montana', key: 'MT' },
    { name: 'Nebraska', key: 'NE' },
    { name: 'Nevada', key: 'NV' },
    { name: 'New Hampshire', key: 'NH' },
    { name: 'New Jersey', key: 'NJ' },
    { name: 'New Mexico', key: 'NM' },
    { name: 'New York', key: 'NY' },
    { name: 'North Carolina', key: 'NC' },
    { name: 'North Dakota', key: 'ND' },
    { name: 'Ohio', key: 'OH' },
    { name: 'Oklahoma', key: 'OK' },
    { name: 'Oregon', key: 'OR' },
    { name: 'Pennsylvania', key: 'PA' },
    { name: 'Rhode Island', key: 'RI' },
    { name: 'South Carolina', key: 'SC' },
    { name: 'South Dakota', key: 'SD' },
    { name: 'Tennessee', key: 'TN' },
    { name: 'Texas', key: 'TX' },
    { name: 'Utah', key: 'UT' },
    { name: 'Vermont', key: 'VT' },
    { name: 'Virginia', key: 'VA' },
    { name: 'Washington', key: 'WA' },
    { name: 'West Virginia', key: 'WV' },
    { name: 'Wisconsin', key: 'WI' },
    { name: 'Wyoming', key: 'WY' }
  ];

  private permissionsLoaded: Subscription;
  private detailDocumentSet: Subscription;

  constructor(
    private locationR: Location,
    private route: ActivatedRoute,
    private router: Router,
    private historyService: HistoryService,
    private authorizationService: AuthorizationService,
    private locationService: LocationService,
    private ioService: IoService,
    private reportService: ReportService
  ) { }

  ngOnInit() {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.init();
      }
    });

    if (this.historyService.getPreviousLinkDepth() > 3) {
      this.showBackButton = true;
    }

  }
  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  back() {
    this.locationR.back();
  }

  loadDetail(type, id) {
    console.log('loadDetail');
    this.router.navigate(['../../'+type+'/'+id], { relativeTo: this.route });
  }

  init = async () => {
    console.log('detail-user init: ');

    this.user = null;

    // Get General User Data
    let user: any = await this.ioService.post('/customer/getCustomer', {
      customerId: this.documentId
    });

    user.activeLocation = null;

    // See if we can manage this user
    let authenticatedUser = this.authorizationService.getUser();
    user.managableLocations = []

    if (user.locationId) {
      for (let locationId of user.locationId) {
        if (this.authorizationService.checkPermission('manageCustomers', locationId)) {
          user.canManage = true;
          break;
        }
      }
    }

    // Get User Authorizations
    if (user.canManage) {
      for (let location of this.locationService.getLocations()) {
        if (location._id) {
          if (user.locationId.indexOf(location._id) != -1) {
            location.active = true;
          }
          user.managableLocations.push(location);
        }
      }
    }

    // Sort managable locations
    user.managableLocations.sort((a, b) => (a.name > b.name) ? 1 : (a.name === b.name) ? ((a.active > b.active) ? 1 : -1) : -1 );

    if (!user.address) {
      user.address = [];
    }

    if (!user.note) {
      user.note = [];
    }

    this.user = user;

    console.log(this.user);
  }

  addAddress() {
    this.user.address.push({});
  }

  saveChanges() {
    if (!this.user.saving) {
      this.user.saving = true;
      this.ioService.post('/customer/updateCustomer', {
        user: this.user,
        // locationId: this.locationService.getActiveLocationId()
      }).then((saveResponse: any) => {
        // this.init();
        this.reportService.loadReport(0);
        this.close();
      }).catch((error: any) => {
        this.user.error = error.error.msg;
        this.user.saving = false;
      });
    }
  }

  deleteCustomer() {
    if (!this.user.saving) {
      this.user.saving = true;
      this.ioService.post('/customer/deleteCustomer', {
        userId: this.user._id
      }).then((saveResponse: any) => {
        this.reportService.loadReport(null);
        this.close();
      }).catch((error: any) => {
        this.user.error = error.error.msg;
        this.user.saving = false;
      });
    }
  }


  toggleBoolean(toggleTarget) {
    this.user[toggleTarget] = !this.user[toggleTarget];
  }


}
