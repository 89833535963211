import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel, RoutesRecognized } from '@angular/router';
import { ClientService } from '../../services/client/client.service';
import { IoService } from '../../services/io/io.service';
import { LocationService } from '../../services/location/location.service';
import { AuthorizationService } from '../../services/authorization/authorization.service';
import { ChartConfiguration } from 'chart.js';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  widgetsIdIndex: any = {};
  widgets = [];
  pendingUpdates = [];

  constructor(
    private ioService: IoService,
    private locationService: LocationService,
    private authorizationService: AuthorizationService,
    private clientService: ClientService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.loadWidgets();
  }

  ngOnDestroy(): void {
    console.log('onDestroy');
    this.stopUpdates();
  }

  loadWidgets = async (widgetIds = null) => {
console.log('pendingUpdates', this.pendingUpdates);

    let widgets: any = await this.ioService.post('/widget/loadWidgets', {
      widgetIds,
      utcOffset: new Date().getTimezoneOffset(),
    });

    for (let widget of widgets) {
      if (widget.updateFrequency) {
        let pendingUpdate = setTimeout(() => {
          this.pendingUpdates.splice(this.pendingUpdates.indexOf(pendingUpdate), 1);
          this.loadWidgets([widget._id]);
        }, 1000 * widget.updateFrequency);
        this.pendingUpdates.push(pendingUpdate);
      }

      if (this.widgetsIdIndex[widget._id] == undefined) {
        this.widgetsIdIndex[widget._id] = this.widgets.length;
        this.widgets.push({});
      }

      if (widget.data) {
        if (widget.data.options) {
          let chartOptions: ChartConfiguration<'doughnut'>['options'] = widget.data.options;

          // let chartOptions: ChartOptions = widget.data.options;
          widget.data.options = chartOptions;
        }
      }

      this.widgets[this.widgetsIdIndex[widget._id]] = widget;
    };
  }

  widgetClick = (widget) => {
    if (widget.data && widget.data.reportKey) {
      this.router.navigate(['reports', widget.data.reportKey]);
    }
    // this.router.navigate([screen.path]);
  }

  stopUpdates = () => {
    console.log('stopUpdates');
    for (let pendingUpdate of this.pendingUpdates) {
      clearTimeout(pendingUpdate);
    }
    return;
  }
}
