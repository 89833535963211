<div class="detail" [class.visible]="user">
  <header>
    <div class="close" (click)="close()"></div>
    <div *ngIf="showBackButton" class="back" (click)="back()"></div>
    <ng-container *ngIf="user">
      <h2 *ngIf="user.name">{{user.name}}</h2>
      <h2 *ngIf="!user.name">New Customer</h2>
    </ng-container>
  </header>
  <section>
    <div *ngIf="user" class="user">

      <div *ngIf="user.type == 'customer'" class="user-form">
        <div class="field">
          <label>First Name</label>
          <input type="text" [(ngModel)]="user.firstName" />
        </div>
        <div class="field">
          <label>Last Name</label>
          <input type="text" [(ngModel)]="user.lastName" />
        </div>
        <div class="field">
          <label>Email</label>
          <input type="email" id="email" [(ngModel)]="user.email" />
        </div>
        <div class="field">
          <label>Phone</label>
          <input type="tel" id="telephone" [(ngModel)]="user.telephone" mask="0 (000) 000-0000" />
        </div>

        <div class="field">
          <label>Notes</label>
          <textarea [(ngModel)]="user.note[0]"></textarea>
        </div>


        <ng-container *ngIf="user.address && user.address.length > 0">
          <div *ngFor="let address of user.address">
            <div class="field">
              <label>Address</label>
              <input type="text" [(ngModel)]="address.streetAddress" />
            </div>
            <div class="field">
              <label>Address Ext.</label>
              <input type="text" [(ngModel)]="address.streetAddressExt" />
            </div>
            <div class="field">
              <label>City</label>
              <input type="text" [(ngModel)]="address.addressLocality" />
            </div>
            <div class="field">
              <label>State</label>
              <!-- <input type="text" [(ngModel)]="address.addressRegion" /> -->
              <select [(ngModel)]="address.addressRegion">
                <option *ngFor="let state of states" [value]="state.key">{{state.name}}</option>
              </select>
            </div>
            <div class="field">
              <label>Zip</label>
              <input type="text" [(ngModel)]="address.postalCode" />
            </div>
          </div>
        </ng-container>
        <div *ngIf="user.address.length == 0" class="add-address" (click)="addAddress()">
          <div class="btn-add"></div>
          Add Address
        </div>


        <div class="line-field">
          <div class="label-large">Tax Exempt</div>
          <app-toggle (valueChanged)="toggleBoolean('taxExempt')" [checked]="user.taxExempt"></app-toggle>
        </div>
        <div class="line-field">
          <div class="label-large">Marketing Allowed</div>
          <app-toggle (valueChanged)="toggleBoolean('marketingAllowed')" [checked]="user.marketingAllowed"></app-toggle>
        </div>

        <div class="error">{{user.error}}</div>
        <div class="buttons" *ngIf="!user.saving">
          <div class="button green" (click)="saveChanges()">Save</div>
          <div class="button red" (click)="deleteCustomer()">Delete</div>
        </div>
      </div>

      <div *ngIf="user.type == 'user'" class="user-summary">
        <div *ngIf="user.firstName">
          <label>First Name</label>
          <div class="value">{{user.firstName}}</div>
        </div>
        <div *ngIf="user.lastName">
          <label>Last Name</label>
          <div class="value">{{user.lastName}}</div>
        </div>
        <div *ngIf="user.email">
          <label>Email</label>
          <div class="value">{{user.email}}</div>
        </div>
        <div *ngIf="user.telephone">
          <label>Phone</label>
          <div class="value">{{user.telephone | rf:'phone'}}</div>
        </div>
      </div>

      <div>
        <div *ngFor="let purchase of user.purchases" class="purchase-link" (click)="loadDetail('purchase', purchase._id)">
          <div class="location">{{purchase.locationName}}</div>
          <div class="total-date">
            <div class="total">{{purchase.total | rf:'currency'}}</div>
            <div class="date">{{purchase.timeUpdated | rf:'dateTime'}}</div>
          </div>
        </div>
      </div>


      <!-- <pre style="padding-top: 200px;">{{user | json}}</pre> -->

    </div>
  </section>
</div>
