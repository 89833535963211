import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Subscription } from 'rxjs';
import { IoService } from '../../../../services/io/io.service';
import { ReportService } from '../../../../services/report/report.service';
import { DropdownOptionGroup, DropdownOption, DropdownSelectionMode } from '../../../shared/interfaces/option-interfaces';
import { DateRangeSettings } from '../../filter-date-range/date-config-interface';
import { Discount } from '../../../shared/interfaces/discounts/discount-account';
import { DiscountFilter } from '../../../shared/interfaces/discounts/discount-filter';
import { DiscountPurchaseQualifier } from '../../../shared/interfaces/discounts/discount-purchase-qualifier';

@Component({
  selector: 'app-discount-advanced',
  templateUrl: './discount-advanced.component.html',
  styleUrls: ['./discount-advanced.component.scss']
})
export class DiscountAdvancedComponent implements OnInit {
  @Input()
  set discount( value: Discount) {
    this.account = value;
    this.loadDiscountDetail();
  }

  public account: Discount;

  showBackButton;
  conversationActive;
  conversationActiveUpdated: Subscription;

  locations;
  canManage;
  saving;

  startDateSettings = new DateRangeSettings();
  endDateSettings = new DateRangeSettings();

  optionFields: Array<DropdownOptionGroup> = [
    {
      key: 'locationId',
      name: 'Available at Locations',
      options: new Array<DropdownOption>(),
      selection: new Array<DropdownOption>(),
      opposingIdKey: null,
      selectMode: DropdownSelectionMode.Multi,
    },
    {
      key: 'categoryId',
      name: 'Category',
      options: new Array<DropdownOption>(),
      selection: new Array<DropdownOption>(),
      opposingIdKey: null,
      selectMode: DropdownSelectionMode.One,
    },
    {
      key: 'groupId',
      name: 'Group(s)',
      options: new Array<DropdownOption>(),
      selection: new Array<DropdownOption>(),
      opposingIdKey: null,
      selectMode: DropdownSelectionMode.Multi,
    }
  ];

  unitOptionsQualifier: Array<DropdownOption> = [
    {
      _id: 'qty',
      name: 'Quantity',
    },
    {
      _id: 'price',
      name: 'Price',
    }
  ];

  unitOptionsQualified: Array<DropdownOption> = [
    {
      _id: 'fixed',
      name: 'Fixed Discount',
    },
    {
      _id: 'fixedPrice',
      name: 'Fixed Price',
    },
    {
      _id: 'percentage',
      name: 'Percentage',
    }
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ioService: IoService,
    private reportService: ReportService,
  ) { }

  ngOnInit() {
    this.startDateSettings.dateConfig.singleDate = this.endDateSettings.dateConfig.singleDate = true;
    this.startDateSettings.dateConfig.opens = this.endDateSettings.dateConfig.opens = 'left';
    this.startDateSettings.dateConfig.autoApply = this.endDateSettings.dateConfig.autoApply = false;
    this.startDateSettings.dateConfig.showRanges = this.endDateSettings.dateConfig.showRanges = false;
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  loadDiscountDetail = async () => {
    console.log('loadDiscountDetail: ');
    if ( !this.account ) { return; }
    this.optionFields.forEach( g => {
      g.options = new Array<DropdownOption>();    // Options that can be selected (search results)
      g.selection = new Array<DropdownOption>();  // Selected options
      if ( this.account[g.key] ) {
        if (Array.isArray(this.account[g.key])) {
          this.account[g.key].forEach(p => {
            if ( this.account.selection[p] ) {
              g.selection.push( new DropdownOption(this.account.selection[p]._id, this.account.selection[p].name));
            }
          });
        } else {
          const opt = this.account[g.key];
          if (this.account.selection[opt]) {
            g.selection.push( new DropdownOption(this.account.selection[opt]._id, this.account.selection[opt].name) );
          }
        }
      } else {
        console.log ( 'Discount account has no field \'' + g.key + '\'.');
      }
    });

    this.updatePurchaseQualifier();
  }

  updatePurchaseQualifier() {
    if (!this.account.purchaseQualifier) {
      this.account.purchaseQualifier = new DiscountPurchaseQualifier();
    } else {
      this.startDateSettings.dateRange.startDate = this.account.purchaseQualifier.timeStart ?
                                                  moment(this.account.purchaseQualifier.timeStart)
                                                  : null;
      this.endDateSettings.dateRange.startDate = this.account.purchaseQualifier.timeEnd ?
                                                moment(this.account.purchaseQualifier.timeEnd)
                                                : null;
    }
  }

  saveDiscount = async () => {
    if (!this.saving) {
      this.saving = true;
      const updateAccount = JSON.parse(JSON.stringify(this.account));

      // Pull current selections from dropdowns
      this.optionFields.forEach( g => {
        switch ( g.selectMode ) {
          case DropdownSelectionMode.One:
            updateAccount[g.key] = g.selection[0]?._id;
            break;
          case DropdownSelectionMode.Multi:
            updateAccount[g.key] = g.selection.map( s => s._id );
            break;
        }
      });

      await this.ioService.post('/discount/updateDiscount', {
        account: updateAccount
      });

      this.saving = false;
      this.reportService.loadReport(null);
      this.close();
    }
  }

  deleteDiscount = async () => {
    this.account.active = false;
    this.saveDiscount();
  }

  toDecimal(inputValue) {
    return Number.parseFloat((Number.parseFloat(inputValue) / 100).toPrecision(4));
  }

  toPennies(inputValue) {
    return parseInt(String(inputValue * 100));
  }

  toDollars(inputValue: number) {
    if (!inputValue) {
      inputValue = 0;
    }
    return this.numberRound(inputValue / 100, 4);
  }

  toPercentage(inputValue) {
    if (!inputValue) {
      inputValue = 0;
    }
    return (inputValue * 100).toFixed(4);
  }

  numberRound = (value: number, decimals: number) => {
    return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
  }

  search = async (optionGroup: DropdownOptionGroup, searchText: string) => {
    optionGroup.options = null;
    const searchParameters: any = {
        accountId: this.account._id,
        idField: optionGroup.key,
        type: 'discount',
        search: searchText,
        limit: 10,
        omit: optionGroup.selection.map( s => s._id )
      };

    optionGroup.options = (await this.ioService.post('/discount/searchOptions', searchParameters) as any[])
                          .map( o => Object.assign(new DropdownOption(o._id, o.name), o));

    return;
  }

  toggleBoolean(toggleTarget) {
    this.account[toggleTarget] = !this.account[toggleTarget];
  }

  togglePurchaseQualifierStart() {
    if ( this.account.purchaseQualifier.timeStart ) {
      this.disablePurchaseQualifierStart();
    } else {
      this.enablePurchaseQualifierStart();
    }
  }
  togglePurchaseQualifierEnd() {
    if ( this.account.purchaseQualifier.timeEnd ) {
      this.disablePurchaseQualifierEnd();
    } else {
      this.enablePurchaseQualifierEnd();
    }
  }

  disablePurchaseQualifierStart() {
    this.account.purchaseQualifier.timeStart = null;
    this.updatePurchaseQualifier();
  }
  enablePurchaseQualifierStart() {
    this.account.purchaseQualifier.timeStart = moment().toISOString();
    this.updatePurchaseQualifier();
  }
  disablePurchaseQualifierEnd() {
    this.account.purchaseQualifier.timeEnd = null;
    this.updatePurchaseQualifier();
  }
  enablePurchaseQualifierEnd() {
    this.account.purchaseQualifier.timeEnd = moment().toISOString();
    this.updatePurchaseQualifier();
  }

  startDateChanged(dateRange) {
    if (!this.account ) {
      return;
    }
    this.account.purchaseQualifier.timeStart = (dateRange.startDate as Moment).toISOString();
  }
  endDateChanged(dateRange) {
    if (!this.account ) {
      return;
    }
    this.account.purchaseQualifier.timeEnd = (dateRange.startDate as Moment).toISOString();
  }

  addPurchaseQualifierFilter() {
    this.account.purchaseQualifier.filters.push( new DiscountFilter() );
  }
  removePurchaseQualifierFilter(filter: DiscountFilter) {
    const f = this.account.purchaseQualifier.filters;
    const idx = f.indexOf( filter );
    if ( idx < 0 ) {
      console.error('Attempted to remove an invalid purchase qualifier filter.');
      return;
    }
    f.splice( idx, 1 );
  }

  addItemQualifierFilter() {
    this.account.itemQualifier.filters.push( new DiscountFilter() );
  }
  removeItemQualifierFilter(filter: DiscountFilter) {
    const f = this.account.itemQualifier.filters;
    const idx = f.indexOf( filter );
    if ( idx < 0 ) {
      console.error('Attempted to remove an invalid item qualifier filter.');
      return;
    }
    f.splice( idx, 1 );
  }

  addQualifiedFilter() {
    this.account.itemQualified.filters.push( new DiscountFilter() );
  }
  removeQualifiedFilter(filter: DiscountFilter) {
    const f = this.account.itemQualified.filters;
    const idx = f.indexOf( filter );
    if ( idx < 0 ) {
      console.error('Attempted to remove an invalid item qualified filter.');
      return;
    }
    f.splice( idx, 1 );
  }

  toggleAllowPartial() {
    this.account.itemQualifier.allowPartial = !this.account.itemQualifier.allowPartial;
  }
}
