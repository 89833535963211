<div class="detail" [class.visible]="account">
    <header>
      <div class="close" (click)="close()"></div>
      <div *ngIf="account && account.showBackButton" class="back" (click)="back()"></div>
      <h2 *ngIf="!account">Inventory Item</h2>
      <h2 *ngIf="account" class="upper">{{account.subtype}}</h2>
    </header>
    <section>
      <div *ngIf="!account" class="loading">Loading . . .</div>
      <div *ngIf="account" class="account">

        <div class="column-general">
            <div class="field">
                <label>Account Name</label>
                <input type="text" [(ngModel)]="account.name">
            </div>









            <div class="buttons">
                <div class="button" (click)="save()">Save</div>
            </div>

        </div>


      </div>
    </section>
</div>
