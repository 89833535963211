import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// RF Modules
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { FulfillmentModule } from './modules/fulfillment/fulfillment.module';
// import { MarketingModule } from './modules/marketing/marketing.module';
import { ReportModule } from './modules/report/report.module';
import { PurchaseModule } from './modules/purchase/purchase.module';
import { SharedModule } from './modules/shared/shared.module';
import { VirtualTerminalModule } from './modules/virtual-terminal/virtual-terminal.module';
import { TemplateModule } from './modules/template/template.module';
import { AdminModule } from './modules/admin/admin.module';
import { TransferModule } from './modules/transfer/transfer.module';
import { InventoryModule } from './modules/inventory/inventory.module';
import { CountModule } from './modules/count/count.module';
import { ErrorHandlerModule } from './modules/errors/error-handler-module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AuthenticationModule,
    DashboardModule,
    FulfillmentModule,
    // MarketingModule,
    ReportModule,
    PurchaseModule,
    SharedModule,
    VirtualTerminalModule,
    TemplateModule,
    AdminModule,
    TransferModule,
    InventoryModule,
    CountModule,
    ErrorHandlerModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
