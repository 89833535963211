<div class="user-authorization">
    <div *ngIf="userGroup && userGroup.managableLocations">
        <div class="authorization-header">
            <!-- <div>{{userGroup.name}}</div> -->
            <div class="group-name"><input type="text" [(ngModel)]="userGroup.name" (blur)="saveConfigChange()" /></div>
            <div class="default-toggle">
                <app-quick-toggle
                    [label]="'Default New Users to this Group'"
                    [(datapoint)]="userGroup.default"
                    (toggled)="saveConfigChange()"
                ></app-quick-toggle>
            </div>
            <div class="close-button" (click)="close()">X</div>
        </div>
        <div class="authorization-grid">
            <div class="authorization-grid-header">
                <div class="permission-name">Permission</div>
                <div class="location-name" *ngFor="let location of userGroup.managableLocations">{{location.name}}</div>
                <div class="scrollbar-spacer">.</div>
            </div>
            <div class="authorization-grid-body">

                <ng-container *ngFor="let permission of allPermissions">
                    <div *ngIf="!permission.locationSpecific" class="authorization-grid-row">
                        <div class="permission-name" [class]="permission.class" (click)="toggleAuthorization('general', permission.key)">{{permission.name}}</div>
                        <div class="authorization-grid-cell" [class.authorized]="targetUserGroupAuthorizations['general'] && targetUserGroupAuthorizations['general'].indexOf(permission.key) !== -1" [style.flex-basis.px]="userGroup.managableLocations.length*100" (click)="toggleAuthorization('general', permission.key)">*</div>
                    </div>
                </ng-container>

                <ng-container *ngFor="let permission of allPermissions">
                    <div *ngIf="permission.locationSpecific" class="authorization-grid-row">
                        <div class="permission-name" [class]="permission.class" (click)="toggleAuthorizationAllLocations(permission.key)">{{permission.name}}</div>
                        <div *ngFor="let location of userGroup.managableLocations" class="authorization-grid-cell" [class.authorized]="targetUserGroupAuthorizations[location._id] && targetUserGroupAuthorizations[location._id].indexOf(permission.key) !== -1" (click)="toggleAuthorization(location._id, permission.key)">
                            *
                        </div>
                    </div>
                </ng-container>

            </div>
        </div>

    </div>

    

</div>