import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HistoryService } from '../../../services/history/history.service';
import { IoService } from '../../../services/io/io.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';
import { LocationService } from '../../../services/location/location.service';
import { ReportService } from '../../../services/report/report.service'
import { RfPipe } from 'src/app/pipes/rf/rf.pipe';


@Component({
  selector: 'app-detail-user-group',
  templateUrl: './detail-user-group.component.html',
  styleUrls: ['./detail-user-group.component.scss']
})
export class DetailUserGroupComponent implements OnInit {

  documentId$: Observable<any>;
  documentId;
  showBackButton;
  activeUserIsDeveloper;
  userGroup;
  targetUserGroupAuthorizations;
  allPermissions;

  delay = 500;


  constructor(
    private locationR: Location,
    private route: ActivatedRoute,
    private router: Router,
    private historyService: HistoryService,
    private authorizationService: AuthorizationService,
    private locationService: LocationService,
    private ioService: IoService,
    private reportService: ReportService,
    private rf: RfPipe,
  ) { }

  ngOnInit(): void {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.init();
      }
    });

    if (this.historyService.getPreviousLinkDepth() > 3) {
      this.showBackButton = true;
    }
  }

  init = async () => {
    this.userGroup = null;

    this.allPermissions = await this.ioService.post('/authorization/getPermissions');


    // Get all User Groups
    const userGroups: any = await this.ioService.post('/userGroup/getUserGroups');

    for (const userGroup of userGroups) {
      const permission = {
        name: 'Manage / Grant User Group: '+userGroup.name,
        key: 'userGroup.'+userGroup._id,
        class: 'user-group'
      };
      this.allPermissions.push(permission);
    }


    // Get all reports
    const reportCategories = this.reportService.getReportCategories();

    if (reportCategories) {

      for (const category of reportCategories) {
        for (const categoryReport of category.options) {
          const permission = {
            name: 'Report: '+category.name+': '+categoryReport.name,
            key: 'report.'+categoryReport._id,
            class: 'report',
            locationSpecific: true
          };
          this.allPermissions.push(permission);
        }
      }

      this.targetUserGroupAuthorizations = await this.ioService.post('/authorization/getUserGroupAuthorizations', {
        userGroupId: this.documentId
      });

      this.userGroup = await this.ioService.post('/userGroup/getUserGroup', {
        userGroupId: this.documentId
      });

      this.userGroup.managableLocations = [];

      for (const location of await this.locationService.getLocationsByType('retail')) {
        if (this.authorizationService.checkPermission('admin', location._id)) {
          this.userGroup.managableLocations.push(location);
          this.userGroup.canManage = true;
        }
      }

      // Sort managable locations
      this.userGroup.managableLocations.sort((a, b) => (a.name > b.name) ? 1 : (a.name === b.name) ? ((a.active > b.active) ? 1 : -1) : -1 );

      this.userGroup.members = await this.ioService.post('/userGroup/getMembers', {
        userGroupId: this.documentId
      });

      console.log('this.userGroup: ', this.userGroup);
    } else {
      setTimeout(() => {
        this.delay *= 2;
        this.init();
      }, this.delay);
    }
  }

  toggleAuthorization = async (locationId, permissionKey) => {

    let authorized = false;
    if (this.targetUserGroupAuthorizations[locationId] && this.targetUserGroupAuthorizations[locationId].includes(permissionKey)) {
      authorized = true;
    }

    let action = 'grantToUserGroup';
    if (authorized) {
      action = 'revokeFromUserGroup';
      this.targetUserGroupAuthorizations[locationId] = this.targetUserGroupAuthorizations[locationId].filter(item => item !== permissionKey);
    } else {
      if (!this.targetUserGroupAuthorizations[locationId]) {
        this.targetUserGroupAuthorizations[locationId] = [];
      }
      this.targetUserGroupAuthorizations[locationId].push(permissionKey);
    }

    if (locationId === 'general') {
      locationId = null;
    }

    await this.ioService.post('/authorization/'+action, {
      userGroupId: this.documentId,
      key: permissionKey,
      locationId: locationId
    });

  }

  toggleAuthorizationAllLocations = async (permissionKey) => {

    let locationsToAuthorize = [];
    for (const location of this.userGroup.managableLocations) {
      if (this.targetUserGroupAuthorizations[location._id] && this.targetUserGroupAuthorizations[location._id].includes(permissionKey)) {
      } else {
        locationsToAuthorize.push(location._id);
      }
    }

    if (locationsToAuthorize.length > 0) {
      for (const locationId of locationsToAuthorize) {
        await this.ioService.post('/authorization/grantToUserGroup', {
          userGroupId: this.documentId,
          key: permissionKey,
          locationId: locationId
        });

        if (!this.targetUserGroupAuthorizations[locationId]) {
          this.targetUserGroupAuthorizations[locationId] = [];
        }

        this.targetUserGroupAuthorizations[locationId].push(permissionKey);
      }
    } else {
      for (const location of this.userGroup.managableLocations) {
        await this.ioService.post('/authorization/revokeFromUserGroup', {
          userGroupId: this.documentId,
          key: permissionKey,
          locationId: location._id
        });
        this.targetUserGroupAuthorizations[location._id] = this.targetUserGroupAuthorizations[location._id].filter(item => item !== permissionKey);
      }
    }

  }

  saveConfigChange = async () => {
    await this.ioService.post('/userGroup/updateUserGroup', {
      _id: this.documentId,
      name: this.userGroup.name,
      default: this.userGroup.default
    });
  }

  close = () => {
    this.reportService.loadReport(null);
    this.locationR.back();
  }

}
