<div class="detail" [class.visible]="account">
    <header>
      <div class="close" (click)="close()"></div>
      <div *ngIf="account && account.showBackButton" class="back" (click)="back()"></div>
      <h2 *ngIf="!account">Return Type</h2>
      <h2 *ngIf="account" class="upper">Return Type</h2>
    </header>
    <section>
      <div *ngIf="!account" class="loading">Loading . . .</div>
      <div *ngIf="account" class="account">
        <div class="column-general">
          <div class="field">
            <label>Name</label>
            <input type="text" [(ngModel)]="account.name" />
          </div>

          <div class="line-field">
            <div class="label-large">Price $</div>
            <input type="number" [(ngModel)]="account.price" />
          </div>



          <div class="buttons">
            <div class="button red" (click)="deleteAccount()">Delete Item</div>
            <div *ngIf="account.deleteError">
              <div class="error">{{account.deleteError.msg}}</div>
            </div>
            <div class="button green" (click)="save()">Save</div>
            <div *ngFor="let error of account.errors" class="error">{{error}}</div>
                </div>
            </div>
        </div>
    </section>
</div>
