import { Injectable } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel, RoutesRecognized } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AuthorizationService } from '../authorization/authorization.service';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {

  private screenOptions = [
    {
      key: 'dashboard',
      name: 'Dashboard',
      icon: 'th-list.svg',
      link: '/dashboard',
      permission: 'view.dashboard',
      locationAll: true
    },
    {
      key: 'reports',
      name: 'Reports',
      icon: 'th-list.svg',
      link: '/reports',
      permission: 'view.reports',
      locationAll: true
    },
    {
      key: 'virtual-terminal',
      name: 'Virtual Terminal',
      icon: 'credit-card-alt.svg',
      link: '/virtual-terminal',
      permission: 'view.virtualTerminal',
      locationAll: true
    },
    // {
    //   name: 'Purchase',
    //   icon: 'Shopping-Cart.svg',
    //   link: '/purchase',
    //   permission: 'view.purchase',
    //   locationAll: true
    // },
    // {
    //   name: 'Communications',
    //   icon: 'commenting-o.svg',
    //   link: '/marketing',
    //   permission: 'view.communications',
    //   locationAll: true
    // },
    {
      name: 'Transfers',
      icon: 'transfers.svg',
      link: '/transfers',
      permission: 'view.transfers',
      locationAll: true
    },
    // {
    //   name: 'Inventory',
    //   icon: 'barcode.svg',
    //   link: '/inventory',
    //   permission: 'view.inventory',
    //   locationAll: true
    // },
    // {
    //   name: 'Count',
    //   icon: 'barcode.svg',
    //   link: '/count',
    //   permission: 'view.count',
    //   locationAll: true
    // },
    {
      name: 'Templates',
      icon: 'code.svg',
      link: '/templates',
      permission: 'view.templates',
      locationAll: false
    },
    // {
    //   name: 'Fulfillment',
    //   icon: 'credit-card.svg',
    //   link: '/fulfillment',
    //   permission: 'location.fulfillment',
    //   locationAll: false
    // },
    {
      name: 'Admin',
      icon: 'exclamation.svg',
      link: '/admin',
      permission: 'admin',
      locationAll: true
    }
  ];

  screens;
  activeScreen;

  private permissionsLoaded: Subscription;

  screensUpdated = new Subject<any>();
  activeScreenSet = new Subject<any>();

  constructor(
    private router: Router,
    private authorizationService: AuthorizationService
  ) {
    this.screens = [];

    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // console.log('**************************');
      // console.log(event.url);
      // console.log(event.url.split('/')[1]);
      this.setActiveScreenByPath(event.url.split('/')[1]);
    });

    // Change the active screen options whenever permissions are loaded
    this.permissionsLoaded = authorizationService.permissionsLoadedObservable().subscribe(() => {
      // console.log('screen service notified that permissions have been loaded');
      this.updateScreens();
    });

    this.setActiveScreenByPath(this.router.url.split('/')[1]);
  }

  updateScreens() {
    this.screens = [];
    for (let screen of this.screenOptions) {
      if (this.authorizationService.checkPermission(screen.permission, 'any')) {
        this.screens.push(screen);
      }
    }
    this.screensUpdated.next(this.screens);
  }

  setActiveScreenByPath(screenPath) {
    for (let screen of this.screenOptions) {
      if (screen.link == '/'+screenPath) {
        this.setActiveScreen(screen);
        break;
      }
    }
  }

  setActiveScreen(screen) {
    if (screen != this.activeScreen) {
      this.activeScreen = screen;
      // this.router.navigate([screen.path]);
      this.activeScreenSet.next(this.activeScreen);
    }
  }

  getScreens() {
    return this.screens;
  }

  getActiveScreen() {
    return this.activeScreen;
  }

  screensUpdatedObservable(): Observable<any> {
    return this.screensUpdated.asObservable();
  }

  activeScreenSetObservable(): Observable<any> {
    return this.activeScreenSet.asObservable();
  }
}
