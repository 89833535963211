<app-header></app-header>
<!-- <div class="icon-stack">
    <div>ICON 1</div>
    <div>ICON 2</div>
    <div>ICON 3</div>
    <div>ICON 4</div>
</div> -->
<div class="dashboard-body">
    <div *ngIf="widgets" class="widgets">

        <div *ngFor="let widget of widgets" class="widget" [class]="widget.size" (click)="widgetClick(widget)">
            <div class="widget-inner">
                <div class="widget-header">
                    <div class="widget-title">{{widget.name}}</div>
                </div>



                <div *ngIf="widget.type == 'total' && widget.data" class="widget-total">
                    {{widget.data.value | rf:widget.data.field.type}}
                </div>


                <div *ngIf="widget.type == 'table' && widget.data" class="widget-table">
                    <div class="fields">
                        <div *ngFor="let field of widget.data.fields" class="field" [class]="field.type" [style.width.%]="field.width">
                            {{field.name}}
                        </div>
                    </div>
                    <div *ngIf="widget.data.records">
                        <div *ngFor="let record of widget.data.records" class="record">
                            <div *ngFor="let field of widget.data.fields" class="field" [class]="field.type" [style.width.%]="field.width">
                                {{record[field.path] | rf:field.type}}
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="widget.type == 'pie' && widget.data" class="widget-pie">
                   <canvas baseChart
                    [labels]="widget.data.labels"
                    [datasets]="widget.data.datasets"
                    [options]="widget.data.options"
                    [legend]="widget.data.legend"
                    [type]="widget.data.type">
                    </canvas>
                </div>


            </div>
        </div>

    </div>
</div>