<div class="max-detail" [class.visible]="account">
    <!-- <header>
      <div class="close" (click)="close()"></div>
      <div *ngIf="account && account.showBackButton" class="back" (click)="back()"></div>
      <h2 *ngIf="!account">Inventory Item</h2>
      <h2 *ngIf="account" class="upper">EDIT ITEM</h2>
    </header> -->
    <!-- <section> -->
      <div *ngIf="!account" class="loading">Loading . . .</div>
      <div *ngIf="account" class="window">


        <section>
          <div class="section-header">Overview</div>
          <div class="element">
            <div class="label">Name</div>
            <input type="text" [(ngModel)]="account.name" placeholder="Name">
          </div>
          <div class="element">
            <div class="img-mgmt">
              <div *ngIf="account.imgPreview" class="img-preview">
                <img [src]="account.imgPreview" />
              </div>
              <div *ngIf="image && image.uploaderUrl" class="img-upload">
                <iframe id="rffile" title="RF File Upload" [src]="image.uploaderUrl" frameborder="0" height="80px" width="100%"></iframe>
              </div>
            </div>
          </div>
          <div class="element">
            <div class="label">Price</div>
            <input type="text" [(ngModel)]="account.price" placeholder="$0.00">
          </div>
        </section>


        <div class="controls">
          <div class="button red" (click)="close()">Cancel</div>
          <div class="button green" (click)="save()">Save</div>
        </div>

      </div>
    <!-- </section> -->
</div>
