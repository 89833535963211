import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-quick-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class QuickToggleComponent implements OnInit {

  @Input() label = '';
  @Input() datapoint = false;
  @Input() tooltip = '';
  @Output() datapointChange = new EventEmitter<boolean>();
  @Output() toggled = new EventEmitter<boolean>();

  ngOnInit(): void {
    if (this.label && this.tooltip) {
      this.tooltip = this.label+': '+this.tooltip;
    }
  }

  setValue = (value: boolean) => {
    this.datapoint = value;
    this.datapointChange.emit(value);
    this.toggled.emit(value);
  }

}
