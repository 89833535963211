<div class="user-authorization">
    <div *ngIf="user && user.managableLocations">
        <div class="authorization-header">
            <div>{{user.firstName}} {{user.lastName}}</div>
            <div class="close-button" (click)="close()">X</div>
        </div>

        <div class="authorization-grid">
            <div class="authorization-grid-header">
                <div class="permission-name">Permission</div>
                <div class="location-name" *ngFor="let location of user.managableLocations">{{location.name}}</div>
                <div class="scrollbar-spacer">.</div>
            </div>
            <div class="authorization-grid-body">
                <div *ngFor="let permission of allPermissions" class="authorization-grid-row">
                    <div class="permission-name">{{permission.name}}</div>
                    <div *ngFor="let location of user.managableLocations" class="authorization-grid-cell" [class.authorized]="targetUserAuthorizations[location._id] && targetUserAuthorizations[location._id].indexOf(permission.key) !== -1" (click)="toggleAuthorization(location._id, permission.key)">
                        *
                    </div>
                </div>
            </div>
        </div>

    </div>

    

</div>