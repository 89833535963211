import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../services/report/report.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  constructor(
    private reportService: ReportService,
  ) { }

  ngOnInit() {
    this.reportService.init();
    // this.router.navigate(['reports', this.activeReportConfig.alias]);
    console.log('report screen init');
  }

}
