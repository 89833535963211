import { Component, OnInit, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IoService } from '../../../services/io/io.service';
import { ReportService } from '../../../services/report/report.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';
import { DropdownOptionGroup, DropdownOption, DropdownSelectionMode } from '../../shared/interfaces/option-interfaces';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-detail-return-type',
  templateUrl: './detail-return-type.component.html',
  styleUrls: ['./detail-return-type.component.scss']
})
export class DetailReturnTypeComponent implements OnInit {

  documentId$: Observable<any>;
  documentId;

  account;

  image;

  @HostListener('window:message', ['$event']) onMessage(e) {
    console.log('e.data: ', e.data);
  
    if (e.data && e.data.type == 'fileApiResponse') {
      console.log('UPLOADER RESPONSE PASSES!');
      this.image.url = e.data.url;
      this.image.name = e.data.name;
      this.imageUpdated();
    }
  }

  optionFields: Array<DropdownOptionGroup> = [
    {
      key: 'locationId',
      name: 'Available at Locations',
      options: new Array<DropdownOption>(),
      selection: new Array<DropdownOption>(),
      opposingIdKey: null,
      selectMode: DropdownSelectionMode.Multi,
    },
    {
      key: 'categoryId',
      name: 'Category',
      options: new Array<DropdownOption>(),
      selection: new Array<DropdownOption>(),
      opposingIdKey: null,
      selectMode: DropdownSelectionMode.One,
    },
    {
      key: 'groupId',
      name: 'Group(s)',
      options: new Array<DropdownOption>(),
      selection: new Array<DropdownOption>(),
      opposingIdKey: null,
      selectMode: DropdownSelectionMode.Multi,
    },
    {
      key: 'taxId',
      name: 'Taxes',
      options: new Array<DropdownOption>(),
      selection: new Array<DropdownOption>(),
      opposingIdKey: null,
      selectMode: DropdownSelectionMode.Multi,
    }
  ];

  constructor(
    private locationR: Location,
    private route: ActivatedRoute,
    private router: Router,
    private ioService: IoService,
    private reportService: ReportService,
    private authorizationService: AuthorizationService,
    public sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.loadAccount();
      }
    });
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  back() {
    this.locationR.back();
  }

  loadAccount = async () => {
    console.log('loadInventoryDetail: ');
    this.account = null;

    const account: any = await this.ioService.post('/inventory/getInventoryAccount', {
      accountId: this.documentId
    });

    // Define Required fields
    if (!account.locationId) {
      account.locationId = [];
    }

    if (!Array.isArray(account.barcode)) {
      account.barcode = [account.barcode];
    }

    const barcodeObjects = [];
    for (const barcode of account.barcode) {
      barcodeObjects.push({
        value: barcode
      });
    }
    account.barcode = barcodeObjects;

    // Temporary Conversions
    account.price = (account.price / 100).toFixed(2);
  
    this.buildDropdown( 'locationId', account.authorizedLocations, account.locationId);
    this.buildDropdown( 'groupId', account.authorizedGroups, account.groupId);


    if (account.imgUrl) {
      account.imgPreview = "https://redfirefile.com/render?response=file&width=200&height=120&src="+account.imgUrl;
    }

    this.account = account;

    if (this.authorizationService.checkPermission('uploadImages', 'any')) {
      this.initializeFileUploader();
    }
    
  }

  initializeFileUploader = async () => {
    this.image = {};
    
    const uploader: any = await this.ioService.post('/file/requestUploader', {
      render: true,
      width: 300,
      // height: 1024,
    });
    let sanitizedIframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(uploader.uploader.iFrameSrc);
    this.image.uploaderUrl = sanitizedIframeSrc;

  }

  imageUpdated = () => {
    this.image.preview = true;

    this.account.imgUrl = this.image.url;
    this.account.imgPreview = "https://redfirefile.com/render?width=200&height=120&src="+this.account.imgUrl;

  }

  buildDropdown( key: string, rootOptions: any, rootSelections: any ) {
    const optionField = this.optionFields.find( (f) => f.key === key );
    if ( !optionField ) {
      return;
    }
    optionField.options = new Array<DropdownOption>();
    optionField.selection = new Array<DropdownOption>();
    for (const opt of rootOptions) {
      if ( !opt ) {
        continue;
      }
      const newOption = Object.assign(new DropdownOption( opt._id, opt._name ), opt );
      if (rootSelections && (Array.isArray(rootSelections) ? rootSelections.indexOf(opt._id) !== -1 : rootSelections === opt._id)) {
        optionField.selection.push( newOption );
      } else {
        optionField.options.push( newOption );
      }
    }
    rootOptions = null;
  }

  save = async (redirectAfter = true) => {
    this.account.errors = [];

    // Run validation
    if (!this.account.name) {
      this.account.errors.push('Name is required.');
    }

    if (!this.account.locationId || this.account.locationId.length === 0) {
      this.account.errors.push('You must select at least one location.');
    }

    // Create a clone of the account object
    const account = JSON.parse(JSON.stringify(this.account));

    this.updateDropdownKeys( account );

    // Remove Temporary Fields
    account.accounts = null;
    account.category = null;
    account.authorizedLocations = null;
    account.authorizedGroups = null;
    account.views = null;
    account.activeOptionGroup = null;

    let simpleBarcodes = [];
    if (account.barcode) {
      for (const barcode of account.barcode) {
        simpleBarcodes.push(barcode.value);
      }

      if (simpleBarcodes.length === 1) {
        simpleBarcodes = simpleBarcodes[0];
      }
      account.barcode = simpleBarcodes;
    }

    // Reverse Temporary Conversions
    if (!account.price || isNaN(account.price)) {
      account.price = null;
    } else {
      account.price = account.price * 100;
    }

    if (this.account.active && this.account.errors.length > 0) {
      return false;
    }

    // console.log('account to be saved:', account);

    await this.ioService.post('/account/updateAccount', {
      account
    });

    if (redirectAfter) {
      this.reportService.loadReport(null);
      if (this.account.subtype === 'component') {
        this.back();
      } else {
        this.close();
      }
    }

    return true;
  }

  updateDropdownKeys( account: any ) {
    for ( const field of this.optionFields ) {
      if ( field.selectMode === DropdownSelectionMode.One ) {
        account[field.key] = field.selection[0]?._id;
      } else {
        account[field.key] = field.selection.map((s) => s._id);
      }
    }
  }

  deleteAccount = async () => {
    try {
      const deleteResponse = await this.ioService.post('/inventory/deleteInventoryAccount', {
        accountId: this.account._id
      });

      setTimeout(() => {
        this.reportService.loadReport(null);
        this.close();
      }, 500);
    } catch (e) {
      // console.log('delete error: ', e.error);
      this.account.deleteError = e.error;
    }
  }

  addBarcode() {
    this.account.barcode.push({
      value: ''
    });
  }

  removeBarcode(barcodeIndex) {
    this.account.barcode.splice(barcodeIndex, 1);
  }

  async search( optionGroup: DropdownOptionGroup, searchText: string ) {
    let searchResults: Array<any>;
    const selection = optionGroup.selection.map( (s) => s._id );
    switch ( optionGroup.key ) {
      case 'locationId':
        searchResults = await this.searchLocations( selection, searchText );
        break;
      case 'groupId':
        searchResults = await this.searchGroups( selection, searchText );
        break;
      case 'categoryId':
        searchResults = await this.searchCategories( selection, searchText );
        break;
      case 'taxId':
        searchResults = await this.searchTaxes( selection, searchText );
        break;
    }
    optionGroup.options = searchResults.map((r) => Object.assign( new DropdownOption( r._id, r.name), r));
  }

  async searchLocations(currentSelection: any, searchText: string): Promise<Array<any>> {
    return await this.ioService.post('/location/getLocations', {
      active: true,
      authorizationKey: 'account.inventory.manage',
      type: 'retail',
      search: searchText,
      limit: 10,
      omit: currentSelection
    }) as Array<any>;
  }

  async searchTaxes(currentSelection: any, searchText: string): Promise<Array<any>> {
    return await this.ioService.post('/account/getAccounts', {
      active: true,
      type: 'tax',
      locationId: this.account.locationId,
      search: searchText,
      limit: 10,
      omit: currentSelection
    }) as Array<any>;
  }

  async searchCategories(currentSelection: any, searchText: string): Promise<Array<any>> {
    return await this.ioService.post('/group/getGroups', {
      active: true,
      type: 'inventory',
      subtype: 'category',
      locationId: this.account.locationId,
      search: searchText,
      limit: 10,
      omit: currentSelection
    }) as Array<any>;
  }

  async searchGroups(currentSelection: any, searchText: string): Promise<Array<any>> {
    return await this.ioService.post('/group/getGroups', {
      active: true,
      type: 'inventory',
      subtype: 'group',
      locationId: this.account.locationId,
      search: searchText,
      limit: 10,
      omit: currentSelection
    }) as Array<any>;
  }

  convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  }

  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }

  toggleBoolean(toggleTarget) {
    this.account[toggleTarget] = !this.account[toggleTarget];
  }

  toggleViewOptions() {
    this.account.viewOptions = !this.account.viewOptions;
  }

}
