import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, of, pipe } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IoService } from 'src/app/services/io/io.service';
import { LocationService } from 'src/app/services/location/location.service';
import { ReportService } from 'src/app/services/report/report.service';

@Component({
  selector: 'app-discount-wizard',
  templateUrl: './discount-wizard.component.html',
  styleUrls: ['./discount-wizard.component.scss']
})
export class DiscountWizardComponent implements OnInit {

  documentId$: Observable<any>;
  documentId: string;

  campaign: any;

  // Set a start that is equal to 1 week ago
  startDate: Date;
  endDate: Date;

  discountTypes = {
    percent: {
      name: '% Off',
      description: 'Discount a percentage off the list price of an item',
    },
    amount: {
      name: '$ Off',
      description: 'Discount a fixed amount off the list price of an item',
    },
    salePrice: {
      name: 'Sale Price',
      description: 'Set a fixed sale price for an item',
    },
    buyOneGetOne: {
      name: 'Buy One Get One',
      description: 'Buy one item, get one free',
    },
    discountedMultiples: {
      name: 'Discounted Multiples',
      description: 'Discount items when purchased in multiples',
    }
  };

  steps: any = {
    name: {
      key: 'name',
      name: 'How should this discount be labeled?',
      fields: [
        {
          key: 'name',
          required: true,
          placeholder: 'discount name',
          type: 'text',
        }
      ]
    },
    subtype: {
      key: 'subtype',
      name: 'What type of discount is this?',
      fields: [
        {
          key: 'subtype',
          required: true,
          type: 'select',
          options: [
            // {
            //   key: 'percent',
            //   name: '% Off',
            // },
            // {
            //   key: 'amount',
            //   name: '$ Off',
            // },
            {
              key: 'salePrice',
              name: 'Sale Price',
            },
            {
              key: 'buyOneGetOne',
              name: 'Buy One Get One',
            },
            {
              key: 'discountedMultiples',
              name: 'Discounted Multiples',
            }
          ]
        }
      ]
    },
    locationId: {
      key: 'locationId',
      name: 'Where is this discount available?',
      fields: [
        {
          key: 'locationId',
          required: true,
          type: 'selectMultiple',
          optionSource: 'locations',
          options: []
        }
      ]
    },
    items: {
      key: 'items',
      name: 'What item(s) should this discount apply to?',
      fields: [
        {
          key: 'accountId',
          required: true,
          type: 'selectMany',
          subtype: 'retail',
        }
      ]
    },
    buyOneGetOne: {
      key: 'buyOneGetOne',
      name: 'BOGO Configuration',
      fields: [
        {
          key: 'sameItemOnly',
          name: 'Same Item Only',
          type: 'checkbox',
        },
      ]
    },
    discountedMultiples: {
      key: 'discountedMultiples',
      name: 'Discounted Multiples Configuration',
      fields: [
        {
          key: 'sameItemOnly',
          name: 'Same Item Only',
          type: 'checkbox',
        },
      ]
    },
    discountedMultiplesAmount: {
      key: 'discountedMultiplesAmount',
      name: 'Discounted Multiples Amount',
      showSaveButton: true,
      columns: [
        {
          name: 'Item',
          key: 'name',
          class: 'item-name',
          editable: false,
          pipe: 'string',
        },
        {
          name: 'Unit',
          key: 'unitDescription',
          class: 'item-unit',
          editable: false,
          pipe: 'string',
        },
        {
          name: 'List Price',
          key: 'price',
          class: 'item-price',
          editable: false,
          pipe: 'currency',
        },
        {
          name: 'Minimum Buy',
          key: 'threshold',
          class: 'item-threshold',
          editable: true,
          pipe: 'number',
        },
        {
          name: 'Discounted Total Price',
          key: 'discountedTotalPrice',
          class: 'item-discounted-total-price',
          editable: true,
          pipe: 'currency',
        },
        {
          name: 'Discounted Unit Price',
          key: 'discountedUnitPrice',
          class: 'item-discounted-unit-price',
          editable: true,
          pipe: 'currency',
        },
        {
          name: 'Discount $',
          key: 'discountAmount',
          path: 'discountSettings',
          class: 'item-discount-amount',
          editable: false,
          pipe: 'currency',
        },
        {
          name: 'Discount %',
          key: 'discountPercent',
          path: 'discountSettings',
          class: 'item-discount-percent',
          editable: false,
          pipe: 'percentage',
        },
        {
          name: 'Margin $',
          key: 'margin',
          path: 'discountSettings',
          class: 'item-margin',
          editable: false,
          pipe: 'currency',
        },
        {
          name: 'Margin %',
          key: 'marginPercent',
          path: 'discountSettings',
          class: 'item-margin-percent',
          editable: false,
          pipe: 'percentage',
        }
      ]
    },
    discountedMultiplesAmountAnyItem: {
      key: 'discountedMultiplesAmountAnyItem',
      name: 'Discounted Multiples Amount',
      showSaveButton: true,
      fieldsDirection: 'horizontal',
      fields: [
        {
          key: 'threshold',
          name: 'Minimum Buy',
          type: 'number',
          class: 'text-right',
          placeholder: 'Minimum Buy',
        },
        {
          key: 'discountedTotalPrice',
          name: 'Discounted Total Price',
          type: 'currency',
          class: 'text-right',
          placeholder: 'Discounted Total Price',
        },
        {
          key: 'discountedUnitPrice',
          name: 'Discounted Unit Price',
          type: 'currency',
          class: 'text-right',
          placeholder: 'Discounted Unit Price',
        }
      ],
      columns: [
        {
          name: 'Item',
          key: 'name',
          class: 'item-name',
          editable: false,
          pipe: 'string',
        },
        {
          name: 'Unit',
          key: 'unitDescription',
          class: 'item-unit',
          editable: false,
          pipe: 'string',
        },
        {
          name: 'List Price',
          key: 'price',
          class: 'item-price',
          editable: false,
          pipe: 'currency',
        },
        {
          name: 'Discount $',
          key: 'discountAmount',
          path: 'discountSettings',
          class: 'item-discount-amount',
          editable: false,
          pipe: 'currency',
        },
        {
          name: 'Discount %',
          key: 'discountPercent',
          path: 'discountSettings',
          class: 'item-discount-percent',
          editable: false,
          pipe: 'percentage',
        },
        {
          name: 'Cost',
          key: 'cost',
          class: 'item-cost',
          editable: false,
          pipe: 'currency',
        },
        {
          name: 'Margin $',
          key: 'margin',
          path: 'discountSettings',
          class: 'item-margin',
          editable: false,
          pipe: 'currency',
        },
        {
          name: 'Margin %',
          key: 'marginPercent',
          path: 'discountSettings',
          class: 'item-margin-percent',
          editable: false,
          pipe: 'percentage',
        }
      ]
    },
    when: {
      key: 'when',
      name: 'When is this discount available?',
      fields: [
        {
          key: 'timeRange',
          name: 'Time Range',
          type: 'timeRange',
        }
      ]
    },
    amount: {
      key: 'amount',
      name: 'How much should this discount be?',
      showSaveButton: true,
      columns: [
        {
          name: 'Item',
          key: 'name',
          class: 'item-name',
          editable: false,
          pipe: 'string',
        },
        {
          name: 'Unit',
          key: 'unitDescription',
          class: 'item-unit',
          editable: false,
          pipe: 'string',
        },
        {
          name: 'List Price',
          key: 'price',
          class: 'item-price',
          editable: false,
          pipe: 'currency',
        },
        {
          name: 'Sale Price',
          key: 'salePrice',
          path: 'discountSettings',
          class: 'item-sale-price',
          editable: true,
          pipe: 'currency',
        },
        {
          name: 'Discount $',
          key: 'discountAmount',
          path: 'discountSettings',
          class: 'item-discount-amount',
          editable: true,
          pipe: 'currency',
        },
        {
          name: 'Discount %',
          key: 'discountPercent',
          path: 'discountSettings',
          class: 'item-discount-percent',
          editable: true,
          pipe: 'percentage',
        },
        {
          name: 'Cost',
          key: 'cost',
          class: 'item-cost',
          editable: false,
          pipe: 'currency',
        },
        {
          name: 'Margin $',
          key: 'margin',
          path: 'discountSettings',
          class: 'item-margin',
          editable: true,
          pipe: 'currency',
        },
        {
          name: 'Margin %',
          key: 'marginPercent',
          path: 'discountSettings',
          class: 'item-margin-percent',
          editable: true,
          pipe: 'percentage',
        }
      ]
    },
    customerId: {
      key: 'customerId',
      name: 'Who should this discount apply to?',
    },
    userId: {
      key: 'userId',
      name: 'Who can apply this discount?',
    },
    review: {
      key: 'review',
      name: 'Review Details',
    }
  };

  currencyFields = [
    'discountAmount',
    'salePrice',
    'discountedTotalPrice',
    'discountedUnitPrice',
    'margin'
  ];

  percentFields = [
    'discountPercent',
    'marginPercent'
  ];

  searchText: any;
  searchTimeout: any;
  results: any = [];
  resultIds: any = [];
  selected: any = [];
  categories: any;
  selectedCategory: any = '';
  groups: any;
  selectedGroup: any = '';
  unitDescription: any;
  selectedUnitDescription: any = 'Any';
  indexedItems: any = {};
  locations: any = null;


  constructor(
    private route: ActivatedRoute,
    private ioService: IoService,
    private locationService: LocationService,
    private router: Router,
    private reportService: ReportService,
  ) { }


  ngOnInit(): void {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.getCampaign();
      }
    });
  }

  arrayToObject = (array, keyField) => array.reduce((obj, item) => {
      obj[item[keyField]] = item;
      return obj;
  }, {});

  getCampaign = async () => {
    this.campaign = null;

    this.locations = await this.locationService.getLocationsByType('retail');

    let campaign: any = await this.ioService.post('/group/getCampaign', {
      groupId: this.documentId,
      returnInventoryAccounts: true,
    });

    if (!campaign.activeStep) {
      campaign.activeStep = 'name';
    }


    if (campaign.timeRange) {
      this.startDate = new Date(campaign.timeRange.startDate);
      this.endDate = new Date(campaign.timeRange.endDate);
    } else {
      this.startDate = new Date();
      this.endDate = new Date();
      this.endDate.setDate(this.endDate.getDate() + 31);
    }

    campaign.timeRange = {
      startDate: this.startDate,
      endDate: this.endDate,
    };

    if (campaign.discountedUnitPrice) {
      campaign.discountedUnitPrice = campaign.discountedUnitPrice / 100;
    }

    if (campaign.discountedTotalPrice) {
      campaign.discountedTotalPrice = campaign.discountedTotalPrice / 100;
    }

    if (campaign.inventoryAccountSettings) {
      for (let item of campaign.inventoryAccounts) {
        if (!item.discountSettings) {
          item.discountSettings = {};
        }

        // Loop through discountSettings keys
        for (let settingKey in campaign.inventoryAccountSettings[item._id]) {
          if (campaign.inventoryAccountSettings[item._id][settingKey]) {

            let multiplier = 1;
            if (this.percentFields.indexOf(settingKey) > -1) {
              multiplier = 100;
            }

            if (this.currencyFields.indexOf(settingKey) > -1) {
              multiplier = 0.01;
            }

            item.discountSettings[settingKey] = campaign.inventoryAccountSettings[item._id][settingKey] * multiplier;

            if (multiplier != 1) {
              item.discountSettings[settingKey] = item.discountSettings[settingKey].toFixed(2);
            }

          }
        }

        console.log('item: ', item);

      }
    }
console.log('populated campaign: ', campaign);

    this.campaign = campaign;

    this.campaign.step = await this.getStep(campaign.activeStep);
  };

  getSelectedItems = async () => {
    if (!this.campaign.inventoryAccountId) {
      this.campaign.inventoryAccountId = [];
    }

    this.campaign.inventoryAccounts = [];

    let items: any;
    
    if (this.campaign.inventoryAccountId.length > 0) {
      items = await this.ioService.post('/inventory/findInventoryAccounts', {
        _id: this.campaign.inventoryAccountId
      });
    }

    if (items?.inventoryAccounts.length > 0) {
      this.indexedItems = this.arrayToObject(items.inventoryAccounts, '_id');
      for (let item of this.campaign.inventoryAccountId) {
        let itemDetail = this.indexedItems[item];
        if (itemDetail) {
          if (!itemDetail.discountSettings) {
            itemDetail.discountSettings = {};
          }
          this.campaign.inventoryAccounts.push(itemDetail);
        }
      }
    }
  };

  getStep = async (stepKey) => {

    console.log('stepKey: ', stepKey);

    if (stepKey === 'amount') {
      console.log('load items to get discount data');
      
    }

    if (stepKey === 'items') {
      this.selectedUnitDescription = 'Any';
      this.getCategories();
      this.getGroups();
    }

    if (stepKey === 'discountedMultiplesAmountAnyItem') {
      this.updateItemCalculations();
    }

    if (stepKey === 'review') {

    }

    if (this.steps[stepKey].fields?.length > 0) {
      for (let field of this.steps[stepKey].fields) {
        if (field.optionSource) {
          field.options = this[field.optionSource];
        }
      }
    }

    return this.steps[stepKey];
  };

  prevStep = async () => {
    let prevStep = null;

    switch (this.campaign.activeStep) {
      case 'subtype':
        prevStep = 'name';
        break;

      case 'locationId':
        prevStep = 'subtype';
        break;

      case 'items':
        prevStep = 'locationId';
        break;

      case 'buyOneGetOne':
        prevStep = 'items';
        break;

      case 'amount':
        prevStep = 'items';
        break;

      case 'discountedMultiples':
        prevStep = 'items';
        break;

      case 'discountedMultiplesAmount':
        prevStep = 'discountedMultiples';
        break;

      case 'discountedMultiplesAmountAnyItem':
        prevStep = 'discountedMultiples';
        break;

      case 'when':
        switch (this.campaign.subtype) {
          case 'buyOneGetOne':
            prevStep = 'buyOneGetOne';
            break;

          case 'discountedMultiples':
            if (this.campaign.sameItemOnly) {
              prevStep = 'discountedMultiplesAmount';
            } else {
              prevStep = 'discountedMultiplesAmountAnyItem';
            }
            break;

          default:
            prevStep = 'amount';
            break;
        }
        break;

      case 'review':
        prevStep = 'when';
        break;

      default:
        break;
    }

    if (prevStep) {
      this.campaign.activeStep = prevStep;
      this.campaign.step = await this.getStep(prevStep);

      await this.saveUpdates();
    }
  };

  nextStep = async () => {
    let nextStep = null;

    switch (this.campaign.activeStep) {
      case 'name':
        nextStep = 'subtype';
        break;

      case 'subtype':
        nextStep = 'locationId';
        break;

      case 'locationId':
        nextStep = 'items';
        break;

      case 'items':
        switch (this.campaign.subtype) {
          case 'buyOneGetOne':
            nextStep = 'buyOneGetOne';
            break;

          case 'discountedMultiples':
            nextStep = 'discountedMultiples';
            break;

          default:
            nextStep = 'amount';
            break;
        }
        break;

      case 'discountedMultiples':
        if (this.campaign.sameItemOnly) {
          nextStep = 'discountedMultiplesAmount';
        } else {
          nextStep = 'discountedMultiplesAmountAnyItem';
        }
        break;

      case 'discountedMultiplesAmount':
        nextStep = 'when';
        break;

      case 'discountedMultiplesAmountAnyItem':
        nextStep = 'when';
        break;

      case 'amount':
        nextStep = 'when';
        break;

      case 'buyOneGetOne':
        nextStep = 'when';
        break;

      case 'when':
        nextStep = 'review';
        break;

      default:
        break;
    }

    if (nextStep) {
      this.campaign.activeStep = nextStep;
      this.campaign.step = await this.getStep(nextStep);
console.log('campaign step: ', this.campaign.step);
      await this.saveUpdates();
    }

    // let i = this.campaign.step.index;
    // this.campaign.step = this.getStep(this.steps[i + 1].key);
    console.log('campaign: ', this.campaign);
  };

  goToStep = async (stepKey) => {
    this.campaign.activeStep = stepKey;
    this.campaign.step = await this.getStep(stepKey);
    await this.saveUpdates();
  }

  selectOption = (fieldKey, optionKey) => {
    this.campaign[fieldKey] = optionKey;
  };

  toggleOption = (fieldKey, optionKey) => {
    if (this.campaign[fieldKey].indexOf(optionKey) !== -1) {
      this.campaign[fieldKey].splice(this.campaign[fieldKey].indexOf(optionKey), 1);
    } else {
      this.campaign[fieldKey].push(optionKey);
    }

    // console.log('campaign: ', this.campaign);
    console.log('field', this.campaign[fieldKey]);
  };

  updateSearch = async (event: any) => {
    this.searchText = event.target.value;
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(async () => {
      this.getItems();
    }, 800);
  };

  getItems = async () => {
    let itemsFind: any = {
      subtype: 'retail',
    };

    if (this.searchText) {
      itemsFind.search = this.searchText;

      itemsFind.searchConfig = {
        fuseOptions: {
          threshold: 0.0
        }
      };
    }

    if (this.selectedCategory) {
      itemsFind.categoryId = this.selectedCategory;
    }

    if (this.selectedGroup) {
      itemsFind.groupId = this.selectedGroup;
    }

    if (this.selectedUnitDescription && this.selectedUnitDescription !== 'Any') {
      itemsFind.unitDescription = this.selectedUnitDescription;
    }

    let searchFilterResponse: any = await this.ioService.post('/inventory/findInventoryAccounts', itemsFind);

    let unitDescription = [];

    let resultIds = [];
    for (let item of searchFilterResponse.inventoryAccounts) {
      if (this.campaign.inventoryAccountId && this.campaign.inventoryAccountId.indexOf(item._id) > -1) {
        item.active = true;
      }
      if (item.unitDescription && unitDescription.indexOf(item.unitDescription) === -1) {
        unitDescription.push(item.unitDescription);
      }

      if (!item.discountSettings) {
        item.discountSettings = {};
      }

      resultIds.push(item._id);
    }

    if (unitDescription.length > 0) {
      unitDescription.sort();
      unitDescription.unshift('Any');
      this.unitDescription = unitDescription;
    } else {
      this.unitDescription = ['Any'];
    }

    this.results = searchFilterResponse.inventoryAccounts;
    this.resultIds = resultIds;

    if (this.results.length === 0 && this.selectedUnitDescription !== 'Any') {
      this.selectedUnitDescription = 'Any';
      this.getItems();
    }

  };

  populateItemDiscountSettings(item) {
    if (!item.discountSettings) {
      item.discountSettings = {};
    }

    // for (let settingKey of [
    //   'price',
    //   'cost'
    // ]) {
    //     item.discountSettings[settingKey] = item[settingKey];
    // }
  }

  getCategories = async () => {
    let categories: any = await this.ioService.post('/group/getGroups', {
      type: 'inventory',
      subtype: 'category',
      anyLocation: true
    });

    categories.unshift({
      name: 'Any',
      _id: ''
    });

    this.categories = categories;
  }

  getGroups = async () => {
    let groups: any = await this.ioService.post('/group/getGroups', {
      type: 'inventory',
      subtype: 'group',
      anyLocation: true
    });

    groups.unshift({
      name: 'Any',
      _id: ''
    });

    this.groups = groups;
  }

  addOption = (option) => {
    if (!option.active) {
      this.campaign.inventoryAccounts.push(option);
      if (!this.campaign.inventoryAccountId) {
        this.campaign.inventoryAccountId = [];
      }

      option.active = true;

      this.campaign.inventoryAccountId.push(option._id);
    }
  }

  removeOption = (option) => {
    this.campaign.inventoryAccounts.splice(this.campaign.inventoryAccounts.indexOf(option), 1);
    this.campaign.inventoryAccountId.splice(this.campaign.inventoryAccountId.indexOf(option._id), 1);

    let resultIndex = this.resultIds.indexOf(option._id);
    if (resultIndex > -1) {
      this.results[resultIndex].active = false;
    }

  }

  addAll = () => {
    for (let option of this.results) {
      this.addOption(option);
    }
  }

  clearSelected = () => {
    this.campaign.inventoryAccounts = [];
    this.campaign.inventoryAccountId = [];
    for (let option of this.results) {
      option.active = false;
    }
  }

  fieldTab = (fieldKey, itemIndex) => {
    console.log('fieldTab: ', fieldKey, itemIndex);

    itemIndex++;

    if (itemIndex < this.campaign.inventoryAccounts.length) {
      setTimeout(() => {
        const targetInput = document.getElementById('item-'+itemIndex+'-'+fieldKey) as HTMLInputElement;
        targetInput.focus();
        targetInput.select();
      }, 0);
    }

  }

  rangeChanged = (event) => {
    console.log('rangeChanged: ', event);
    // console.log(event.startDate.toDate());
    // console.log('campaign: ', this.campaign);
  }

  fieldUpdated(field, $event) {
    console.log('fieldUpdated: ', field, $event);

    switch (field.key) {
      case 'threshold':
        if (this.campaign.threshold && this.campaign.discountedUnitPrice) {
          this.campaign.discountedTotalPrice = this.campaign.threshold * this.campaign.discountedUnitPrice;
        }
        this.updateItemCalculations();
        break;

      case 'discountedUnitPrice':
        if (this.campaign.threshold && this.campaign.discountedUnitPrice) {
          this.campaign.discountedTotalPrice = this.campaign.threshold * this.campaign.discountedUnitPrice;
        }
        this.updateItemCalculations();
        break;

      case 'discountedTotalPrice':
        if (this.campaign.threshold && this.campaign.discountedTotalPrice) {
          this.campaign.discountedUnitPrice = this.campaign.discountedTotalPrice / this.campaign.threshold;
        }
        this.updateItemCalculations();
        break;
    }

  }

  updateItemCalculations() {
    console.log('updateItemCalculations');
    for (let item of this.campaign.inventoryAccounts) {
      item.discountSettings.discountAmount = item.price - (this.campaign.discountedUnitPrice*100);
      item.discountSettings.discountPercent = item.discountSettings.discountAmount / item.price;
      item.discountSettings.margin = ((this.campaign.discountedUnitPrice*100) - item.cost);
      item.discountSettings.marginPercent = item.discountSettings.margin / this.campaign.discountedUnitPrice/100;
    }
  }

  removeItem(item) {
    this.campaign.inventoryAccounts.splice(this.campaign.inventoryAccounts.indexOf(item), 1);
    this.campaign.inventoryAccountId.splice(this.campaign.inventoryAccountId.indexOf(item._id), 1);
  }

  itemFieldUpdated(item, field, $event) {
    console.log('itemFieldUpdated: ', item, field, $event);
    if (!item.discountSettings) {
      item.discountSettings = {};
    }
    item.discountSettings[field.key] = $event;

    if (!item.cost) {
      item.cost = 0;
    }

    switch (field.key) {
      case 'discountedUnitPrice':
        if (item.discountSettings.threshold && item.discountSettings.discountedUnitPrice) {
          item.discountSettings.discountedTotalPrice = (item.discountSettings.threshold * item.discountSettings.discountedUnitPrice).toFixed(2);
          this.updateCalculatedFields(item);
        }
        break;

        case 'threshold':
          if (item.discountSettings.threshold && item.discountSettings.discountedUnitPrice) {
            item.discountSettings.discountedTotalPrice = (item.discountSettings.threshold * item.discountSettings.discountedUnitPrice).toFixed(2);
            this.updateCalculatedFields(item);
          }
          break;

        case 'discountedTotalPrice':
          if (item.discountSettings.threshold && item.discountSettings.discountedTotalPrice) {
            item.discountSettings.discountedUnitPrice = (item.discountSettings.discountedTotalPrice / item.discountSettings.threshold).toFixed(2);
            this.updateCalculatedFields(item);
          }
          break;

        case 'salePrice':
          item.discountSettings.discountAmount = Number((item.price - (item.discountSettings.salePrice * 100)) / 100).toFixed(2);
          item.discountSettings.discountPercent = (item.discountSettings.discountAmount / item.price * 10000).toFixed(2);
          item.discountSettings.margin = Number(((item.discountSettings.salePrice * 100) - item.cost) / 100).toFixed(2);
          item.discountSettings.marginPercent = Number(item.discountSettings.margin / item.discountSettings.salePrice * 100).toFixed(2);
          break;

        case 'discountAmount':
          item.discountSettings.discountPercent = Number(item.discountSettings.discountAmount / item.price * 10000).toFixed(2);
          item.discountSettings.salePrice = Number((item.price - (item.discountSettings.discountAmount * 100)) / 100).toFixed(2);
          item.discountSettings.margin = Number(((item.discountSettings.salePrice * 100) - item.cost) / 100).toFixed(2);
          item.discountSettings.marginPercent = Number(item.discountSettings.margin / item.discountSettings.salePrice * 100).toFixed(2);
          break;

        case 'discountPercent':
          item.discountSettings.discountAmount = Number(item.price * item.discountSettings.discountPercent / 100 / 100).toFixed(2);
          item.discountSettings.salePrice = Number((item.price - (item.discountSettings.discountAmount * 100)) / 100).toFixed(2);
          item.discountSettings.margin = Number(((item.discountSettings.salePrice * 100) - item.cost) / 100).toFixed(2);
          item.discountSettings.marginPercent = Number(item.discountSettings.margin / item.discountSettings.salePrice * 100).toFixed(2);
          break;

        case 'margin':
          item.discountSettings.salePrice = Number((item.cost + (item.discountSettings.margin * 100)) / 100).toFixed(2);
          item.discountSettings.discountAmount = Number((item.price - (item.discountSettings.salePrice * 100)) / 100).toFixed(2);
          item.discountSettings.discountPercent = Number(item.discountSettings.discountAmount / item.price * 10000).toFixed(2);
          item.discountSettings.marginPercent = Number(item.discountSettings.margin / item.discountSettings.salePrice * 100).toFixed(2);
          break;

        case 'marginPercent':
          item.discountSettings.salePrice = Number((item.cost / (1 - (item.discountSettings.marginPercent / 100))) / 100).toFixed(2);
          item.discountSettings.discountAmount = Number((item.price - (item.discountSettings.salePrice * 100)) / 100).toFixed(2);
          item.discountSettings.discountPercent = Number(item.discountSettings.discountAmount / item.price * 10000).toFixed(2);
          item.discountSettings.margin = Number(((item.discountSettings.salePrice * 100) - item.cost) / 100).toFixed(2);
          break;
    }

    // Validate Row

  }

  updateCalculatedFields = (item) => {
    console.log('updateCalculatedFields: ', item);
    if (!item.discountSettings) {
      item.discountSettings = {};
    }
    item.discountSettings.discountAmount = (item.price - (item.discountSettings.discountedUnitPrice*100)).toFixed(2);
    item.discountSettings.discountPercent = (item.discountSettings.discountAmount / item.price).toFixed(2);
    item.discountSettings.margin = ((item.discountSettings.discountedUnitPrice*100) - item.cost).toFixed(2);
    item.discountSettings.marginPercent = (item.discountSettings.margin / item.discountSettings.discountedUnitPrice/100).toFixed(2);
  };

  toggleLive = async () => {
    this.campaign.live = !this.campaign.live;
    await this.saveUpdates();
  }

  reset = async () => {
    this.getCampaign();
  }

  saveUpdates = async () => {
    let inventoryAccountSettings = {};
    if (this.campaign.inventoryAccounts) {
      for (let inventoryAccount of this.campaign.inventoryAccounts) {
        // inventoryAccountSettings[inventoryAccount._id] = inventoryAccount.discountSettings;

        inventoryAccountSettings[inventoryAccount._id] = {};

        // Loop through discountSettings keys
        for (let settingKey in inventoryAccount.discountSettings) {
          if (inventoryAccount.discountSettings[settingKey]) {

            let multiplier = 1;
            if (this.percentFields.indexOf(settingKey) > -1) {
              multiplier = 0.01;
            }

            if (this.currencyFields.indexOf(settingKey) > -1) {
              multiplier = 100;
            }

            inventoryAccountSettings[inventoryAccount._id][settingKey] = inventoryAccount.discountSettings[settingKey] * multiplier;

            // if (this.currencyFields.indexOf(settingKey) > -1) {
            //   inventoryAccountSettings[inventoryAccount._id][settingKey] = inventoryAccount.discountSettings[settingKey] * 100;
            // } else {
            //   inventoryAccountSettings[inventoryAccount._id][settingKey] = inventoryAccount.discountSettings[settingKey];
            // }
          }
        }

      }
    }

    console.log('inventoryAccountSettings: ', inventoryAccountSettings);

    let campaignUpdate: any = {
      _id: this.campaign._id,
      name: this.campaign.name,
      live: this.campaign.live,
      subtype: this.campaign.subtype,
      inventoryAccountId: this.campaign.inventoryAccountId,
      activeStep: this.campaign.activeStep,
      sameItemOnly: this.campaign.sameItemOnly,
      locationId: this.campaign.locationId,
      timeRange: this.campaign.timeRange,
      threshold: this.campaign.threshold,
      inventoryAccountSettings,
    };

    if (this.campaign.discountedUnitPrice) {
      campaignUpdate.discountedUnitPrice = this.campaign.discountedUnitPrice * 100;
    }

    if (this.campaign.discountedTotalPrice) {
      campaignUpdate.discountedTotalPrice = this.campaign.discountedTotalPrice * 100;
    }


    let updateResponse = await this.ioService.post('/group/updateCampaign', campaignUpdate);

  }


  
  deleteSaleCampaign = async () => {
    if (confirm("Are you sure you want to delete this campaign?")) {
      let campaignDelete: any = {
        _id: this.campaign._id,
      };
  
      let deleteResponse = await this.ioService.post('/group/deleteCampaign', campaignDelete);
  
      this.close();
    }
  }

  printTags() {
    this.ioService.print('/report/loadRecords', {
      key: 'sale-campaign-tags',
      templateKey: 'sale-tags',
      utcOffset: new Date().getTimezoneOffset(),
      clientConfig: {
        download: true,
        clientFilters: [
          {
            path: 'groupId',
            type: 'id',
            value: this.campaign._id
          }
        ]
      }
    });
  }

  close() {
    this.reportService.loadReport(null);
    this.router.navigate(['../../'], { relativeTo: this.route });
  }



}
