import { Component, OnInit } from '@angular/core';
import { IoService } from '../../../services/io/io.service';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ReportService } from '../../../services/report/report.service';

@Component({
  selector: 'app-detail-billing-account',
  templateUrl: './detail-billing-account.component.html',
  styleUrls: ['./detail-billing-account.component.scss']
})
export class DetailBillingAccountComponent implements OnInit {

  documentId$: Observable<any>;
  documentId;

  account;

  constructor(
    private ioService: IoService,
    private locationR: Location,
    private route: ActivatedRoute,
    private router: Router,
    private reportService: ReportService,
  ) { }

  ngOnInit(): void {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.loadAccount();
      }
    });
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  back() {
    this.locationR.back();
  }

  loadAccount = async () => {
    console.log('loadAccount');
    this.account = null;

    let account: any = await this.ioService.post('/account/getAccount', {
      accountId: this.documentId
    });

    // Account deficit maximum

    // Credit Maximum

    // Associated Customers

    // Require Customer Selection

    // Get a list of account purchases


    if (account) {
      this.account = account;

      // Get a list of account purchases

    }
  }

  save = async () => {
    console.log('save');
    
    await this.ioService.post('/account/updateAccount', {
      account: this.account
    });

    setTimeout(() => {
      this.reportService.loadReport(null);
      this.close();
    }, 500);

  }

}
