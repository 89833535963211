import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HistoryService } from '../../../services/history/history.service';
import { IoService } from '../../../services/io/io.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';
import { LocationService } from '../../../services/location/location.service';
import { ReportService } from '../../../services/report/report.service'

import { RfPipe } from 'src/app/pipes/rf/rf.pipe';



@Component({
  selector: 'app-detail-user-authorization',
  templateUrl: './detail-user-authorization.component.html',
  styleUrls: ['./detail-user-authorization.component.scss']
})
export class DetailUserAuthorizationComponent implements OnInit {

  documentId$: Observable<any>;
  documentId;
  showBackButton;
  activeUserIsDeveloper;
  user;
  targetUserAuthorizations;
  allPermissions;

  private permissionsLoaded: Subscription;
  private detailDocumentSet: Subscription;

  constructor(
    private locationR: Location,
    private route: ActivatedRoute,
    private router: Router,
    private historyService: HistoryService,
    private authorizationService: AuthorizationService,
    private locationService: LocationService,
    private ioService: IoService,
    private reportService: ReportService,
    private rf: RfPipe,
  ) { }

  ngOnInit(): void {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.init();
      }
    });

    if (this.historyService.getPreviousLinkDepth() > 3) {
      this.showBackButton = true;
    }
  }

  init = async () => {
    this.user = null;

    this.allPermissions = await this.ioService.post('/authorization/getPermissions');

    // Get all reports
    const reportCategories = this.reportService.getReportCategories();
    console.log('reportCategories: ', reportCategories);
    for (const category of reportCategories) {
      for (const categoryReport of category.options) {
        const permission = {
          name: 'Report: '+category.name+': '+categoryReport.name,
          key: 'report.'+categoryReport._id
        };
        this.allPermissions.push(permission);
      }
    }


    this.targetUserAuthorizations = await this.ioService.post('/authorization/getUserAuthorizations', {
      userId: this.documentId
    });

    // Get General User Data
    this.user = await this.ioService.post('/user/getUser', {
      userId: this.documentId
    });

    // See if we can manage this user
    const authenticatedUser = this.authorizationService.getUser();
    this.user.managableLocations = [];

    for (const location of await this.locationService.getLocationsByType('retail')) {
      if (this.authorizationService.checkPermission('manageUsers', location._id)) {
        this.user.managableLocations.push(location);
        this.user.canManage = true;
      }
    }

    // Sort managable locations
    this.user.managableLocations.sort((a, b) => (a.name > b.name) ? 1 : (a.name === b.name) ? ((a.active > b.active) ? 1 : -1) : -1 );

    // Get other possible authorizations
    this.activeUserIsDeveloper = this.authorizationService.checkPermission('Developer', 'general');
    console.log('activeUserIsDeveloper: ', this.activeUserIsDeveloper);

    console.log('User: ', this.user);

  }

  toggleAuthorization = async (locationId, permissionKey) => {
    console.log('toggleAuthorization: ', locationId, permissionKey);
    // this.ioService.post('/authorization/toggleAuthorization

    let authorized = false;
    if (this.targetUserAuthorizations[locationId] && this.targetUserAuthorizations[locationId].includes(permissionKey)) {
      authorized = true;
    }

    console.log('authorized: ', authorized);

    let action = 'grant';
    if (authorized) {
      action = 'revoke';
      this.targetUserAuthorizations[locationId] = this.targetUserAuthorizations[locationId].filter(item => item !== permissionKey);
    } else {
      if (!this.targetUserAuthorizations[locationId]) {
        this.targetUserAuthorizations[locationId] = [];
      }
      this.targetUserAuthorizations[locationId].push(permissionKey);
    }

    await this.ioService.post('/authorization/'+action, {
      userId: this.documentId,
      key: permissionKey,
      locationId: locationId
    });

  }

  close = () => {
    this.locationR.back();
  }

}
