import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';


import { ClientService } from '../../../services/client/client.service';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { IoService } from '../../../services/io/io.service';

@Component({
  selector: 'app-remote-access',
  templateUrl: './remote-access.component.html',
  styleUrls: ['./remote-access.component.scss']
})
export class RemoteAccessComponent implements OnInit {

  rfId;
  remoteAccessKey;
  status = 'Initializing . . .';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private clientService: ClientService,
    private authenticationService: AuthenticationService,
    private ioService: IoService
  ) { }

  ngOnInit(): void {
    let rfId = this.route.snapshot.queryParamMap.get('rfId');
    let remoteAccessKey = this.route.snapshot.queryParamMap.get('remoteAccessKey');

    if (rfId && remoteAccessKey) {
      this.status = 'rfId and remoteAccessKey received';
      this.rfId = rfId;
      this.remoteAccessKey = remoteAccessKey;

      this.validateRemoteAccessKey();
    } else {
      this.router.navigate(['/']);
    }

  }

  validateRemoteAccessKey = async () => {
    this.status = 'Requesting Client Config from Config Server';

    let clientConfig = await this.clientService.requestClientConfig(this.rfId);
    this.status = 'Client Config response received';
    console.log('clientConfig: ', clientConfig);
    if (clientConfig) {
      this.status = 'Client Config match found. Validating remote access key.';
      try {
        await this.authenticationService.validateRemoteAccess(this.remoteAccessKey);
        this.status = 'Remote access key validated. Redirecting reporting root.';
        this.router.navigate(['/']);
      } catch (err) {
        console.error('error validating remote access key: ', err);
        this.status = 'Error validating remote access key: '+err;
      }
    } else {
      console.log('error getting client config');
      this.status = 'Error getting client config';
    }

  }

}
