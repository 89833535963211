import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from '../../app-routing.module';

import { SharedModule } from '../shared/shared.module';

import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

import { FormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxMaskModule } from 'ngx-mask';
import { DragulaModule } from 'ng2-dragula';

import { ReportComponent } from './report.component';
import { MenuComponent } from './menu/menu.component';
import { DetailComponent } from './detail/detail.component';
import { ReportDataComponent } from './report-data/report-data.component';
import { FilterDateRangeComponent } from './filter-date-range/filter-date-range.component';
import { DetailPurchaseComponent } from './detail-purchase/detail-purchase.component';
import { DetailLocationComponent } from './detail-location/detail-location.component';
import { DetailUserComponent } from './detail-user/detail-user.component';
import { DetailTransactionComponent } from './detail-transaction/detail-transaction.component';
import { DetailAccountComponent } from './detail-account/detail-account.component';
import { DetailDynamicComponent } from './detail-dynamic/detail-dynamic.component';
import { DetailReportComponent } from './detail-report/detail-report.component';
import { DetailCustomerComponent } from './detail-customer/detail-customer.component';
import { DetailBatchComponent } from './detail-batch/detail-batch.component';
import { DetailInventoryComponent } from './detail-inventory/detail-inventory.component';
import { DetailDiscountComponent } from './detail-discount/detail-discount.component';
import { DetailFormComponent } from './detail-form/detail-form.component';
import { DetailTaxComponent } from './detail-tax/detail-tax.component';
import { DetailPermissionComponent } from './detail-permission/detail-permission.component';
// import { ConversationComponent } from './conversation/conversation.component';
import { ConversationsComponent } from './conversations/conversations.component';
import { DetailGroupComponent } from './detail-group/detail-group.component';
import { DetailEmployeeComponent } from './detail-employee/detail-employee.component';
import { DetailTerminalComponent } from './detail-terminal/detail-terminal.component';
import { DetailPermissionGroupComponent } from './detail-permission-group/detail-permission-group.component';
import { DetailVendorInventoryComponent } from './detail-vendor-inventory/detail-vendor-inventory.component';
import { DetailVendorComponent } from './detail-vendor/detail-vendor.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { DetailMenuActionComponent } from './detail-menu-action/detail-menu-action.component';
import { DiscountFilterComponent } from './detail-discount/discount-filter/discount-filter.component';
import { DiscountAdvancedComponent } from './detail-discount/discount-advanced/discount-advanced.component';
import { DiscountSalePriceComponent } from './detail-discount-group/discount-sale-price/discount-sale-price.component';
import { SaleItemComponent } from './detail-discount-group/discount-sale-price/sale-item/sale-item.component';
import { DetailDiscountGroupComponent } from './detail-discount-group/detail-discount-group.component';
import { DiscountItemListComponent } from './detail-discount-group/discount-sale-price/discount-item-list/discount-item-list.component';
import { DiscountGroupCategoryComponent } from './detail-discount-group/discount-sale-price/discount-group-category/discount-group-category.component';
import { ListBuilderComponent } from './list-builder/list-builder.component';
import { DiscountWizardComponent } from './discount-wizard/discount-wizard.component';
import { DetailUserAuthorizationComponent } from './detail-user-authorization/detail-user-authorization.component';
import { DetailUserGroupComponent } from './detail-user-group/detail-user-group.component';
import { DetailReturnTypeComponent } from './detail-return-type/detail-return-type.component';
import { DetailInventoryBasicComponent } from './detail-inventory-basic/detail-inventory-basic.component';
import { DetailBillingAccountComponent } from './detail-billing-account/detail-billing-account.component';

@NgModule({
  declarations: [
    MenuComponent,
    DetailComponent,
    ReportComponent,
    ReportDataComponent,
    FilterDateRangeComponent,
    DetailPurchaseComponent,
    DetailLocationComponent,
    DetailUserComponent,
    DetailTransactionComponent,
    DetailAccountComponent,
    DetailDynamicComponent,
    DetailReportComponent,
    DetailCustomerComponent,
    DetailBatchComponent,
    DetailInventoryComponent,
    DetailDiscountComponent,
    DetailFormComponent,
    DetailTaxComponent,
    DetailPermissionComponent,
    ConversationsComponent,
    DetailGroupComponent,
    DetailEmployeeComponent,
    DetailTerminalComponent,
    DetailPermissionGroupComponent,
    DetailVendorInventoryComponent,
    DetailVendorComponent,
    DetailMenuActionComponent,
    DiscountFilterComponent,
    DiscountAdvancedComponent,
    DiscountSalePriceComponent,
    SaleItemComponent,
    DetailDiscountGroupComponent,
    DiscountItemListComponent,
    DiscountGroupCategoryComponent,
    ListBuilderComponent,
    DiscountWizardComponent,
    DetailUserAuthorizationComponent,
    DetailUserGroupComponent,
    DetailReturnTypeComponent,
    DetailInventoryBasicComponent,
    DetailBillingAccountComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    SharedModule,
    AngularMultiSelectModule,
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    NgxMaskModule.forRoot(),
    DragulaModule.forRoot(),
    ColorPickerModule
  ]
})
export class ReportModule { }
