import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

interface ClientConfigResponse {
  success: boolean,
  msg: string
}

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  private clientConfig;

  private clientConfigChanged = new Subject<any>();


  constructor(
    private http: HttpClient,
    private router: Router,
    private titleService: Title,
    private route: ActivatedRoute
  ) {
    const clientConfig = localStorage.getItem('clientConfig');
    if (clientConfig) {
      this.clientConfig = JSON.parse(clientConfig);
      if ( !this.clientConfig.key ) {
        this.resetClientConfig();
      }
      console.log('CLIENT CONFIG: ', this.clientConfig);
      this.updateTitle();
    } else {
      this.getConfigKeyFromSubdomain();
    }
  }

  getConfigKeyFromSubdomain() {
    console.log('hostname: ', window.location.hostname);
    // count periods in hostname to see if we are in a subdomain
    let subdomain = null;
    let domain = window.location.hostname;

    try {
      let periods = (domain.match(/\./g) || []).length;
      if (periods == 2) {
        let domainParts = domain.split(".");
        console.log('domainParts: ', domainParts);
        subdomain = domainParts[0];
      }
    } catch (err) {
      console.log('error parsing domain: ', err);
    }

    console.log('subdomain: ', subdomain);
    if (subdomain) {
      this.requestClientConfig(subdomain);
    } else {
      console.log('no subdomain');

      this.route.queryParams.subscribe(params => {
        console.log('params: ', params);
        if (params.rfId && params.remoteAccessKey) {

        }
      });

      // console.log('rfId: ', this.route.snapshot);
      // console.log(this.route.snapshot.queryParamMap.get('rfId'));

    }
  }

  updateTitle() {
    let clientLabel = this.clientConfig.rfId;
    if (this.clientConfig.name) {
      clientLabel = this.clientConfig.name;
    }

    this.titleService.setTitle(clientLabel+' | Red Fire Reporting');
  }

  getConfig() {
    return this.clientConfig;
  }

  getClientKey() {
    return this.clientConfig?.key;
  }

  getRfId() {
    return this.clientConfig?.rfId;
  }

  getServerPath() {
    let serverPath = this.clientConfig?.serverUrl;

    // If the last character is not /, add it
    if (serverPath && serverPath.charAt(serverPath.length - 1) != '/') {
      serverPath += '/';
    }

    return serverPath;
  }

  getLogoUrl() {
    return this.clientConfig?.logoUrl;
  }

  clientConfigChangedObservable(): Observable<any> {
    return this.clientConfigChanged.asObservable();
  }

  requestClientConfig(key: string = null, configServers: any = null): Promise<ClientConfigResponse> {

    if ( null == key ) {
      if ( this.clientConfig  ) {
        key = this.clientConfig.key;
      } else {
        return new Promise((resolve, reject) => {
          console.error('invalid config code');
          reject( 'Invalid config.' );
        });
      }
    }

    if (configServers == null) {

      configServers = [
        {
          name: 'Fire Tower',
          url: 'https://firetower-api-5nvmx.ondigitalocean.app/public/getClientConfig'
        },
        {
          name: 'Stoker',
          url: 'https://stoker.rftapi.com/ise/getClientConfig'
        },
        {
          name: 'Fire Tower local',
          url: 'http://localhost:8080/public/getClientConfig'
        }
      ];

    }

    let configError = null;

    return new Promise((resolve, reject) => {

      let configServer = configServers[0];

      // for (let configServer of configServers) {

        console.log('Requesting client config from: ', configServer.name);

        try {

          this.requestClientConfigFromSource(key, configServer.url)
            .then(clientConfigResponse => {

              console.log('|||| clientConfigResponse: ', clientConfigResponse);

              if (clientConfigResponse.success) {
                resolve(clientConfigResponse);
              }
            })
            .catch(err => {
              configError = err;
              console.log('(1) Failed to get response from config server: ', configServer.name);

              if (configServers.length > 1) {
                configServers.shift();
                this.requestClientConfig(key, configServers)
                  .then(clientConfigResponse => {
                    resolve(clientConfigResponse);
                  })
                  .catch(err => {
                    configError = err;
                    console.log('(3) Failed to get response from config server: ', configServer.name);
                  });
              }

            });

        } catch (err) {
          configError = err;
          console.log('(2) Failed to get response from config server: ', configServer.name);
        }
      // }

      // resolve(configError);

    });

  }



  requestClientConfigFromSource(key: string = null, configServerUrl: string): Promise<ClientConfigResponse> {
    return new Promise((resolve, reject) => {
      this.http.post<any>(configServerUrl, {
        key
      }, {}).toPromise()
        .then(clientConfigResponse => {

          if (clientConfigResponse.success) {
            this.clientConfig = clientConfigResponse.clientConfig;

            if (clientConfigResponse.configResponse && clientConfigResponse.configResponse.name) {
              this.clientConfig.name = clientConfigResponse.configResponse.name;
            }

            if (!environment.production && environment.localServer ) {
              this.clientConfig.serverUrl = 'http://localhost:3000/';
            }

            if (!this.clientConfig.rfId && this.clientConfig.key) {
              this.clientConfig.rfId = this.clientConfig.key;
            }

            localStorage.setItem('clientConfig', JSON.stringify(this.clientConfig));

            console.log('clientConfigUpdated: ', this.clientConfig);

            this.clientConfigChanged.next(this.clientConfig);

            this.updateTitle();

            resolve(clientConfigResponse);
          } else {
            console.error('requestClientConfig failed');
            reject(clientConfigResponse);
          }

        })
        .catch(err => {
          console.error('requestClientConfig failed');
          // resolve(err.error);
          reject(err);
        });
    });
  }

  resetClientConfig() {
    localStorage.removeItem('clientConfig');
    this.clientConfig = null;
    this.clientConfigChanged.next(this.clientConfig);
  }
}
