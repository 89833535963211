import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import * as moment from 'moment';

import { IoService } from '../io/io.service';
import { ScreenService } from '../screen/screen.service';
import { LocationService } from '../location/location.service';
import { DateRange } from 'src/app/modules/report/filter-date-range/date-config-interface';
import { LoadingComponent } from 'src/app/modules/shared/loading/loading.component';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private reportCategories;
  private reportsByKey = {};
  private pendingReportKey;

  private defaultReport;
  private activeReportConfig;

  private dateRange = new DateRange(null, null);

  private sorts;
  private indexedSorts;
  private activeFields;
  private search: string;
  private valueFilters;
  // private searchVisible: boolean = false;

  private records;
  private totals;

  private chunkLoaderTimeout;
  private searchTextTimeout;

  private detailDocumentId;
  private detailDocumentMeta;
  private adding = false;

  private reportsLoaded = new Subject<any>();
  private reportSelected = new Subject<any>();
  private reportLoaded = new Subject<any>();
  private recordsUpdated = new Subject<any>();
  private valueFiltersUpdated = new Subject<any>();

  private dateRangeSet = new Subject<DateRange>();
  private sortSet = new Subject<any>();
  private detailDocumentSet = new Subject<any>();
  private addingSet = new Subject<any>();

  private stagedConfig;
  private loadingTimeout: number | undefined;

  private initialized: boolean = false;

  constructor(
    private router: Router,
    private ioService: IoService,
    private screenService: ScreenService,
    private locationService: LocationService
  ) { }

  async init() {
console.log('*** report service init');

    // Initialize date range to current month
    this.dateRange = new DateRange( moment().startOf('month'), moment().endOf('month'));


    let loadReportAfter = true;

    if (this.activeReportConfig) {
      this.router.navigate(['reports', this.activeReportConfig.key]);
      loadReportAfter = false;
    }

    // If a location is already set, load reports
    if (this.locationService.getActiveLocation()) {
      console.log('******  location already set');
      await this.loadReports(loadReportAfter);
    }

    if (!this.initialized) {

      // When the location is set, load a list of the available reports for that location
      this.locationService.activeLocationSetObservable().subscribe(async activeLocation =>  {
        // Make sure the reports screen is active before we bother updating its contents
        if (this.screenService.getActiveScreen().key == 'reports') {
          console.log('******  location changed');
          await this.loadReports(loadReportAfter);
        }
      });

    }

    this.initialized = true;
  }

  loadReports(loadReportAfter = true): Promise<void> {
    return this.ioService.post('/report/getLocationReports', {
      locationId: this.locationService.getActiveLocation()._id
    }).then((reportsData: any) => {

      // Build List of Category Names
      const categoryNames = [];
      for (const report of reportsData) {
        this.reportsByKey[report.key] = report;
        if (report.category && categoryNames.indexOf(report.category) == -1) {
          categoryNames.push(report.category);
        }
      }

      // Sort Category Names
      categoryNames.sort();

      // Add Other Category to the end
      categoryNames.push('Other');

      const categories = [];
      for (const categoryName of categoryNames) {
        categories.push({
          name: categoryName,
          options: [],
          active: true
        });
      }

      // Sort Reports By Name
      reportsData.sort((a, b) => (a.name > b.name) ? 1 : -1);

      let defaultBias = 0;

      for (const report of reportsData) {

        if (report.type != 'document') {

          if (!report.category) {
            report.category = 'Other';
          }

          // See if this report should be the default
          if (report.default >= defaultBias) {
            this.defaultReport = report;
            defaultBias = report.default;
          }

          for (const category of categories) {
            if (report.category == category.name) {
              category.options.push(report);
            }
          }
        }
      }

      this.reportCategories = categories;

      this.reportsLoaded.next({
        reportCategories: this.reportCategories
      });

      if (loadReportAfter) {
        // See if the activeReport is still available
        if (!this.activeReportConfig || !this.reportsByKey[this.activeReportConfig.key]) {
          // Load the active reportKey or the default report if no reportKey is pending
          if (this.pendingReportKey) {
            this.setReportByKey(this.pendingReportKey);
          } else {
            this.router.navigate(['reports', this.defaultReport.key]);
          }
        } else {
          this.setActiveReport(this.activeReportConfig);
        }
      }
    });
  }

  async getReports(reportTypes, contextType) {
    if ( !this.reportsByKey ||  Object.entries(this.reportsByKey).length === 0) {
      await this.loadReports(false);
    }
    const reports = [];

    for (const [reportKey, reportConfig] of Object.entries(this.reportsByKey)) {
      console.log(reportConfig);
      if (reportTypes.indexOf(reportConfig['type']) != -1) {
        if (!contextType || (reportConfig['contextType'] && reportConfig['contextType'].indexOf(contextType) != -1)) {
          // TODO: Check permission ?
          reports.push(reportConfig);
        }
      }
    }
    return reports;
  }

  getReportCategories() {
    return this.reportCategories;
  }

  reportsLoadedObservable(): Observable<any> {
    return this.reportsLoaded.asObservable();
  }

  loadFilteredReport(key, valueFilters) {
    const pathParts = this.router.url.split('/');

    this.stagedConfig = {
      valueFilters
    };

    if (this.activeReportConfig.key == key) {
      // this.loadReport(null);
      this.setActiveReport(this.activeReportConfig);
    } else {
      this.router.navigate(['/reports/' + key + '/' + pathParts[3] + '/' + pathParts[4]]);
    }
  }

  setReportByKey(key) {
    if (!this.reportsByKey[key]) {
      this.pendingReportKey = key;
    } else {
      this.setActiveReport(this.reportsByKey[key]);
    }
  }

  getReportIdByKey(key) {
    if (this.reportsByKey[key]) {
      return this.reportsByKey[key]._id;
    } else {
      return false;
    }
  }

  setActiveReport(reportConfig, valueFilters = []) {
    this.activeReportConfig = reportConfig;
    this.activeFields = null;
    this.records = null;
    this.totals = null;
    this.search = null;

    this.valueFilters = valueFilters;

    if (this.stagedConfig) {
      if (this.stagedConfig.valueFilters) {
        this.valueFilters = this.valueFilters.concat(this.stagedConfig.valueFilters);
      }

      this.stagedConfig = null;
    }

    this.valueFiltersUpdated.next(this.valueFilters);
    this.reportSelected.next(this.activeReportConfig);
    // this.recordsUpdated.next(this.records);
    this.loadReport(null);
  }

  getActiveReportConfig() {
    return this.activeReportConfig;
  }

  filterByFieldValue(field, value) {
    console.log('filterByFieldValue:');
    console.log('field: ', field);
    console.log('value: ', value);

    this.valueFilters.push({
      name: field.name,
      path: field.path,
      type: field.type,
      value
    });

    this.reportSelected.next(this.activeReportConfig);
    this.records = null;
    this.recordsUpdated.next(this.records);
    this.loadReport(null);
    this.valueFiltersUpdated.next(this.valueFilters);
  }

  removeValueFilter(filterIndex) {
    this.valueFilters.splice(filterIndex, 1);
    this.reportSelected.next(this.activeReportConfig);
    this.records = null;
    this.recordsUpdated.next(this.records);
    this.loadReport(null);
    this.valueFiltersUpdated.next(this.valueFilters);
  }

  loadReport(skip, chunkSize = null) {
      // If loading takes longer than a few seconds, show spinner
      if ( !this.loadingTimeout ) {
        this.loadingTimeout = window.setTimeout(() => LoadingComponent.showLoading(), 1000 );
      }

      this.setAdding(false);
      const activeLocation = this.locationService.getActiveLocation();

      if (this.activeReportConfig && activeLocation) {

      let clientFilters = [];

      // Add Location Filter
      const activeLocationId = activeLocation._id;
      if (activeLocationId) {
        clientFilters.push({
          path: 'locationId',
          type: 'id',
          value: [
            activeLocationId
          ]
        });
      }

      // Add Date Range
      if (this.activeReportConfig.datePath) {
        clientFilters.push({
          path: this.activeReportConfig.datePath,
          type: 'dateTime',
          value: {
            start: this.dateRange.startDate,
            end: this.dateRange.endDate
          }
        });
      }

      // Add Value Filters
      if (this.valueFilters) {
        clientFilters = clientFilters.concat(this.valueFilters);
      }

      const clientConfig: any = {
        skip,
        clientFilters,
        sort: this.sorts,
        search: this.search,
        activeFields: null
      };

      if (this.activeReportConfig.supportsIncrementalLoading) {
        clientConfig.limit = 50;
      }

      if (chunkSize) {
        clientConfig.limit = chunkSize;
      }

      // Optionaly define a custom set of optional fields to be returned from the server
      if (this.activeFields) {
        clientConfig.activeFields = [];
        for (const activeField of this.activeFields) {
          clientConfig.activeFields.push(activeField.path);
        }
      }

      // Retrieve data from the server
      this.ioService.post('/report/loadRecords', {
        reportId: this.activeReportConfig._id,
        utcOffset: new Date().getTimezoneOffset(),
        clientConfig
      }).then((reportData: any) => {

        if (skip == null) {

          // This is initial load of this report config
          this.activeFields = [];
          reportData.template.activeFields.forEach((fieldPath, fp) => {
            reportData.template.fields.forEach((fieldConfig, fc) => {
              if (fieldPath == fieldConfig.path) {

                if (this.sorts && this.sorts[0].path == fieldPath) {
                  fieldConfig.sort = this.sorts[0].direction;
                } else {
                  fieldConfig.sort = null;
                }

                this.activeFields.push(fieldConfig);
                return false;
              }
            });
          });

          this.totals = reportData.totals;

          this.reportLoaded.next({
            activeFields: this.activeFields,
            totals: this.totals,
            template: reportData.template,
            templateOptions: reportData.templateOptions,
          });

          // This is the initial load, set the records to an array of empty objects
          if (this.totals) {
            this.records = Array(this.totals.count).fill({});
          }
        }

        // Put returned records into the placeholder array
        let index = 0;
        if (! reportData || !reportData.skip) {
          reportData.skip = 0;
        }
        if (!this.records) {
          this.records = [];
        }

        for (const record of reportData.records) {
          this.records[reportData.skip + index] = record;
          index++;
        }

        // console.log('records$: ', this.records);
        let records = [];
        if (this.records) {
          records = [...this.records];
        }

        this.recordsUpdated.next(records);
      })
      .catch( err => {
        alert( err.message );
        this.recordsUpdated.next(this.records);
      })
      .finally(() => {
        if ( this.loadingTimeout ) {
          clearTimeout(this.loadingTimeout);
          this.loadingTimeout = undefined;
        }
        LoadingComponent.hideLoading();
      });
    }
  }

  refreshRecord(index, data) {
    console.log('refreshRecord: ', index, data);

    if (this.records && this.records[index]) {
      console.log('non-refreshed record: ', this.records[index]);
      console.log('prev: '+this.records[index].name, this.records[index].timeUpdated);
      console.log('new: '+data.name, data.timeUpdated);

      // console.log('previous value: ', this.records[index].name);
      // console.log('found record to update:', index, data.name);
      this.records[index] = data;
      console.log('new value: ', this.records[index]);
      this.recordsUpdated.next(this.records);
    }
  }

  saveField(record, field) {

    // console.log('saveField: ', record, field);

    delete record.editingField;
    record.saving = true;

    switch (field.type) {
      case 'currency':
        record[field.path] = record[field.path]*100;
        break;
    }

    // See if the value actually changed
    if (record.initialValue != record[field.path]) {
      this.ioService.post(field.instantEditSavePath, {
        _id: record._id,
        field: field.path,
        value: record[field.path],
        locationId: this.locationService.getActiveLocation()._id,
        reportId: this.activeReportConfig._id,
        activeFields: this.activeFields.map((field) => field.path)
      }).then((response: any) => {
        record.saving = false;
        let records = [...this.records];
        let recordIndex = records.findIndex(r => r._id == response.updatedRow._id);
        records[recordIndex] = response.updatedRow;
        this.recordsUpdated.next(records);
      });
    } else {
      record.saving = false;
    }
  }

  loadChunk(skip, chunkSize = null) {
    if (this.activeReportConfig) {
      if (this.chunkLoaderTimeout) {
        clearTimeout(this.chunkLoaderTimeout);
      }

      this.chunkLoaderTimeout = setTimeout(() => {
        // See if we need to load more data yet
        let needMore = true;

        if (chunkSize) {
          let checkSpan = 100;
          needMore = false;

          if (this.records && this.records.length > 0) {
            for (let i = skip; i < skip + checkSpan; i++) {
              if (!this.records[i] || !this.records[i]._id) {
                needMore = true;
                break;
              }
            }
          }
        }

        if (needMore) {
          this.loadReport(skip, chunkSize);
        }
      }, 200);

    } else {
      console.log('Not ready to load chunks');
    }
  }

  reportSelectedObservable(): Observable<any> {
    return this.reportSelected.asObservable();
  }

  reportLoadedObservable(): Observable<any> {
    return this.reportLoaded.asObservable();
  }

  recordsUpdatedObservable(): Observable<any> {
    return this.recordsUpdated.asObservable();
  }

  valueFiltersUpdatedObservable(): Observable<any> {
    return this.valueFiltersUpdated.asObservable();
  }

  // Date Range
  setDateRange(dateRange: DateRange) {
    this.dateRange = dateRange;
    this.reportSelected.next(this.activeReportConfig);
    this.records = null;
    this.recordsUpdated.next(this.records);
    this.loadReport(null);
    this.dateRangeSet.next(this.dateRange);
  }

  getDateRange() {
    return this.dateRange;
  }

  dateRangeSetObservable(): Observable<DateRange> {
    return this.dateRangeSet.asObservable();
  }

  // Sort
  setSort(field) {
    // if (!this.search) {
      let direction = 1;

      if (this.sorts && this.sorts[0] && field.path === this.sorts[0].path) {
        direction = this.sorts[0].direction * -1;
      }

      this.sorts = [{
        path: field.path,
        direction
      }];

      this.sortSet.next(this.sorts);
      this.records = null;
      this.loadReport(null);
    // }
  }

  sortSetObservable(): Observable<any> {
    return this.sortSet.asObservable();
  }

  setFieldsSequence(fields) {
    this.activeFields = fields;
    this.loadReport(null);
  }

  addField(field) {
    this.activeFields.push(field);
    this.loadReport(null);
  }

  removeField(field) {
    const activeIndex = this.activeFields.indexOf(field);
    if (activeIndex > -1) {
      this.activeFields.splice(activeIndex, 1);
    }
    this.loadReport(null);
  }


  // Search
  setSearch(searchText) {
    this.totals = null;
    this.records = null;
    this.recordsUpdated.next(this.records);
    this.sorts = null;
    this.sortSet.next(this.sorts);

    if (this.activeReportConfig) {
      if (this.searchTextTimeout) {
        clearTimeout(this.searchTextTimeout);
      }

      this.searchTextTimeout = setTimeout(() => {
        this.search = searchText;
        this.totals = null;
        this.records = null;
        this.recordsUpdated.next(this.records);
        this.loadReport(null);
      }, 1000);
    }
  }



  // Active Detail
  // setDetailDocumentId(id) {
  //   console.log('setDetailDocumentId: ', this.activeReportConfig);
  //   this.setDetailDocument({
  //     id: id,
  //     name: this.activeReportConfig.name,
  //     type: this.activeReportConfig.itemType
  //   });
  // }
  setDetailDocumentId(id) {
    this.detailDocumentId = id;
  }

  getDetailDocumentId() {
    return this.detailDocumentId;
  }

  // setDetailDocument(documentMeta) {
  //   this.detailDocumentMeta = documentMeta;
  //   this.detailDocumentSet.next(this.detailDocumentMeta);
  // }

  // getDetailDocumentId() {
  //   if (this.detailDocumentMeta) {
  //     return this.detailDocumentMeta.id;
  //   } else {
  //     return null;
  //   }
  // }

  detailDocumentSetObservable(): Observable<any> {
    return this.detailDocumentSet.asObservable();
  }


  setAdding(adding) {
    this.adding = adding;
    this.addingSet.next(this.adding);
  }

  addingSetObservable(): Observable<any> {
    return this.addingSet.asObservable();
  }

  getLoadRecordsInput() {
    let clientFilters = [];

    // Add Location Filter
    const activeLocationId = this.locationService.getActiveLocation()._id;
    if (activeLocationId) {
      clientFilters.push({
        path: 'locationId',
        type: 'id',
        value: [
          activeLocationId
        ]
      });
    }

    // Add Date Range
    if (this.activeReportConfig.datePath) {
      clientFilters.push({
        path: this.activeReportConfig.datePath,
        type: 'dateTime',
        value: {
          start: this.dateRange.startDate,
          end: this.dateRange.endDate
        }
      });
    }

    // Add Value Filters
    if (this.valueFilters) {
      clientFilters = clientFilters.concat(this.valueFilters);
    }

    const clientConfig = {
      clientFilters,
      sort: this.sorts,
      search: this.search,
      activeFields: null,
      download: false,
    };

    // Optionaly define a custom set of optional fields to be returned from the server
    if (this.activeFields) {
      clientConfig.activeFields = [];
      for (const activeField of this.activeFields) {
        clientConfig.activeFields.push(activeField.path);
      }
    }

    return {
      reportId: this.activeReportConfig._id,
      utcOffset: new Date().getTimezoneOffset(),
      clientConfig
    };
  }


  download() {
    if (this.activeReportConfig) {

      let loadRecordsInput = this.getLoadRecordsInput();
      loadRecordsInput.clientConfig.download = true;

      // Retrieve data from the server
      this.ioService.download('/report/loadRecords', loadRecordsInput, this.activeReportConfig.name + '.csv');
    } else {
      console.log('No active report to download');
    }
  }

  printReport(templateKey) {
    if (this.activeReportConfig) {

      let loadRecordsInput:any = this.getLoadRecordsInput();
      loadRecordsInput.clientConfig.download = true;
      loadRecordsInput.templateKey = templateKey;

      // Retrieve data from the server
      this.ioService.print('/report/loadRecords', loadRecordsInput);
    } else {
      console.log('No active report to print');
    }
  }


  refresh() {
    this.totals = null;
    this.records = null;
    this.recordsUpdated.next(this.records);
    this.loadReport(null);
    // this.sortSet.next(this.sorts);
  }

}
