import { Component, OnInit, OnDestroy, Input, HostListener, ElementRef, ViewChild, ViewChildren } from '@angular/core';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { IoService } from '../../services/io/io.service';
import { ReportService } from '../../services/report/report.service';
import { LocationService } from '../../services/location/location.service';
import { switchMap } from 'rxjs/operators';
import { AuthorizationService } from '../../services/authorization/authorization.service';
import { Location } from '@angular/common';
import { CdkVirtualScrollViewport, ScrollDispatcher } from '@angular/cdk/scrolling';
import { FileDetector } from 'protractor';
import packageJson from '../../../../package.json';



@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss']
})
export class TransferComponent implements OnInit {

  purchaseId$: Observable<any>;
  purchaseId;

  locationsSet: Subscription;

  // purchase location is the from location
  purchase = {
    _id: null,
    key: null,
    status: 'open',
    type: 'retail-retail',
    subtype: 'null',
    typeObject: null,
    typeName: '-',
    name: 'New Transfer',
    locationId: null,
    customerId: null,
    timeUpdated: null,
    items: [],
    searching: false,
    savingUpdates: false,
    searchTimeout: null,
    searchText: null,
    searchResults: null,
    locationName: null,
    customerName: null,
    locationOptions: null,
    customerOptions: null,
    note: [],
    saving: null,
    itemSaving: null,
    adding: null,
    errors: null,
    countDateTime: {
      startDate: new Date(),
      endDate: new Date()
    },
    effectiveDate: {
      startDate: new Date(),
      endDate: new Date()
    },
    exportOptions: null,
    costTotal: null,
    qtyTotal: null,
    authorization: {
      view: false,
      update: false,
      complete: false,
      upload: false,
      editPrice: false,
      retry: false,
    },
    // editingCostIndex: null,
    selectedCategory: null,
    barcodeIndexes: {},
    lastRetrieved: null,
    countComplete: false,
    inputTargetField: 'qty',
    inputTargetStartValue: null,
    loading: true,
    uncounted: null,
    containsEditableFields: false,
    settingsEdit: null,
    costEdit: null,
    modeSelect: null,
    statusEdit: null,
    missingBarcode: null,
    strictInventoryCountable: null,
    editSessionKey: null,
  };

  purchaseTypes: any = [];

  purchaseTypeOptions = [];

  availableCategories = null;

  activeItem;

  validKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
  lastKey;
  keyboardBuffer: String;
  barcode: String;
  keyboardIsShifted: Boolean;

  resizeTimeout;

  permissionsLoaded: Subscription;

  autoSyncTimeout;
  autoSyncDelay;
  autoSyncDelayDefault = 5; // 5 seconds
  autoSyncDelayMultiple = 1.2; // 20% increase
  autoSyncDelayMax = 1800; // 30 minutes

  public version: string = packageJson.version;

  @ViewChild('purchasecsv', { static: false }) importEl: ElementRef<HTMLElement>;
  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(() => {
      if (this.purchaseId) {
        this.loadPurchase();
      }
    }, 1000);
  }


  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.target.toString() == "[object HTMLBodyElement]") {
      console.log('KEY Unmodified: ', event.key);

      // if (event.key == "Shift") {
      //   this.keyboardIsShifted = true;
      // }

      if (this.validKeys.indexOf(event.key.toLowerCase()) !== -1) {
        // if (this.keyboardIsShifted) {
          // this.keyboardBuffer += event.key.toUpperCase();
        // } else {
          this.keyboardBuffer += event.key;
        // }

      } else {
        if (event.key == "Enter") {
          this.barcodeEntered(this.keyboardBuffer);
          this.keyboardBuffer = "";
          this.keyboardIsShifted = false;
        }
      }
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ioService: IoService,
    private locationService: LocationService,
    private authorizationService: AuthorizationService,
    private locationR: Location,
    private reportService: ReportService,
  ) { }

  ngOnInit(): void {
    // Make sure permissions are loaded
    this.permissionsLoaded = this.authorizationService.permissionsLoadedObservable().subscribe(loaded => {
      this.checkPrerequisites();
    });

    // Make sure locations are loaded
    this.locationsSet = this.locationService.locationsSetObservable().subscribe(availableLocations => {
      this.checkPrerequisites();
    });

    this.checkPrerequisites();
  }

  checkPrerequisites() {
    if (
      this.authorizationService.permissionsAreLoaded() &&
      this.locationService.getLocations() != null
    ) {
      this.init();
    }
  }

  ngOnDestroy(): void {
    if (this.permissionsLoaded) {
      this.permissionsLoaded.unsubscribe();
    }
      
    if (this.locationsSet) {
      this.locationsSet.unsubscribe();
    }

    if (this.autoSyncTimeout) {
      clearTimeout(this.autoSyncTimeout);
    }
  }

  init = async () => {
    this.purchaseTypes = await this.ioService.post('/transfer/getPurchaseTypes');
    this.purchaseId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('purchaseId'))));
    this.purchaseId$.subscribe(purchaseId => {
      if (purchaseId) {
        this.purchaseId = purchaseId;
        this.loadPurchase();
      } else {
        this.setPurchaseTypeOptions();

        // If this is not an existing purchase, set the type to the first option
        if (!purchaseId) {
          this.purchase.type = this.purchaseTypeOptions[0].key;
        }

        this.purchaseTypeChange(this.purchase.type);
        this.purchase.loading = false;
      }
    });
  }

  newPurchase = async () => {
    console.log('newPurchase: ');

    // On a Make Order, the location and the customer are the same
    if (this.purchase.type == 'make-order') {
      this.purchase.locationId = this.purchase.customerId;
      this.purchase.locationName = this.purchase.customerName;
    }

    let purchaseUpdateResponse: any = await this.ioService.post('/transfer/updateTransfer', {
      purchase: this.purchase
    });

    this.router.navigate(['transfers', purchaseUpdateResponse.purchaseId]);
  }

  setPurchaseTypeOptions = async () => {
    this.purchaseTypeOptions = [];

    for (let purchaseType of this.purchaseTypes) {
      // Check for edit permission
      if (this.authorizationService.checkPermission('purchase.'+purchaseType.key+'.update', 'any') || this.authorizationService.checkPermission('purchase.'+purchaseType.key+'.update', 'general')) {
        this.purchaseTypeOptions.push(purchaseType);
      }
    }

    return;
  }

  loadPurchase = async (purchaseResponse: any = null) => {
    console.log('loadPurchase: ', this.purchaseId);

    if (this.autoSyncTimeout) {
      clearTimeout(this.autoSyncTimeout);
    }

    this.autoSyncDelay = this.autoSyncDelayDefault;

    this.activeItem = null;

    if (purchaseResponse === null) {
      purchaseResponse = await this.ioService.post('/transfer/getTransfer', {
        purchaseId: this.purchaseId
      });
    }

    this.availableCategories = null;
    purchaseResponse.searchText = null;
    purchaseResponse.searchResults = null;
    purchaseResponse.activeCategoryId = null;

    for (let typeOb of this.purchaseTypes) {
      if (typeOb.key == purchaseResponse.type) {

        let containsEditableFields = false;
        for (let field of typeOb.fields[purchaseResponse.status]) {
          if (field.editable) {
            containsEditableFields = true;
          }
        }

        purchaseResponse.containsEditableFields = containsEditableFields;

        purchaseResponse.typeObject = typeOb;
      }
    }

    if (purchaseResponse.effectiveDate) {
      console.log('existing effectiveDate found: ', purchaseResponse.effectiveDate);
      purchaseResponse.effectiveDate = {
        startDate: new Date(purchaseResponse.effectiveDate),
        endDate: new Date(purchaseResponse.effectiveDate)
      };
    }

    // purchaseResponse.canExport = this.authorizationService.checkPermission('purchase.export', purchaseResponse.customerId);

    if (purchaseResponse.typeObject.authContext === 'general') {
      purchaseResponse.authorization = {
        view: this.authorizationService.checkPermission('purchase.'+purchaseResponse.type+'.view', 'general'),
        update: this.authorizationService.checkPermission('purchase.'+purchaseResponse.type+'.update', 'general'),
        complete: this.authorizationService.checkPermission('purchase.'+purchaseResponse.type+'.complete', 'general'),
      };
    } else {

      purchaseResponse.authorization = {};
      for (let authorizationKey of [
        'view',
        'update',
        'complete',
      ]) {
        purchaseResponse.authorization[authorizationKey] = this.authorizationService.checkPermission('purchase.'+purchaseResponse.type+'.'+authorizationKey, purchaseResponse[purchaseResponse.typeObject.authContext]);
      }

    }

    purchaseResponse.authorization.retry = this.authorizationService.checkPermission('Developer', 'general');
    purchaseResponse.authorization.upload = this.authorizationService.checkPermission('Upload', 'general');
console.log('AUTHORIZATION: ', purchaseResponse.authorization);
    // If this is a PO and the user is allowed to edit Vendor Items, allow them to change the price on the items
    if (purchaseResponse.type == 'vendor-retail') {
      purchaseResponse.authorization.editPrice = this.authorizationService.checkPermission('Vendor.manage', 'general');
      if (!purchaseResponse.authorization.complete) {
        purchaseResponse.authorization.editPrice = false;
      }
    }

    let itemIndex = 0;
    let barcodeIndexes = {};
    for (let item of purchaseResponse.items) {
      item.index = itemIndex;
      item.serverQty = item.qty;

      if (!item.count) {
        item.count = 0;
      }

      if (item.barcode) {
        if (Array.isArray(item.barcode)) {
          for (let barcode of item.barcode) {
            barcodeIndexes[String(barcode)] = itemIndex;
          }
        } else {
          barcodeIndexes[String(item.barcode)] = itemIndex;
        }
      }

      itemIndex++;
    }

    purchaseResponse.barcodeIndexes = barcodeIndexes;

    // Select which field is selected by default
    purchaseResponse.inputTargetField = "qty";
    for (let field of purchaseResponse.typeObject.fields[purchaseResponse.status]) {
      if (field.default) {
        purchaseResponse.inputTargetField = field.key;
      }
    }
    

    if (window.innerWidth < 700) {
      purchaseResponse.isMobile = true;
    }

    console.log('barcodeIndexes:', barcodeIndexes);
    this.keyboardBuffer = "";
    this.purchase = purchaseResponse;

    this.updateCalculations();

    await this.loadExportOptions();

    if (this.purchase.status != 'complete') {
      await this.loadCategories();
      this.loadPurchaseUpdates(null);
    }

    return;
  }

  loadCategories = async () => {
    let categoriesFindInput = {
      type: 'inventory'
    };

    if (this.purchase.customerId) {
      categoriesFindInput['locationId'] = this.purchase.customerId;
    } else {
      categoriesFindInput['anyLocation'] = true;
    }

    let availableCategories:any = await this.ioService.post('/group/getGroups', categoriesFindInput);

    if (availableCategories) {
      availableCategories.unshift({
        _id: null,
        name: 'All Categories',
        subtype: ''
      });
  
      this.availableCategories = availableCategories;

      this.purchase.selectedCategory = this.availableCategories[0];
    }

    return;
  }

  loadExportOptions = async () => {
    console.log('loadExportOptions: ');
    // let exportOptions: any = await this.reportService.getReports(['document'], this.purchase.type);

    let exportOptions: any = await this.ioService.post('/report/getLocationReports', {
      locationId: this.purchase.customerId,
      contextType: this.purchase.type,
      unlisted: true
    });


    console.log('exportOptions: ', exportOptions);
    this.purchase.exportOptions = exportOptions;
  }


  async purchaseTypeChange(purchaseType) {
    console.log('purchaseType: ', purchaseType);
    this.purchase.type = purchaseType;

    this.purchase.locationId = null;
    this.purchase.customerId = null;
    this.purchase.locationName = null;
    this.purchase.customerName = null;

    for (const typeOb of this.purchaseTypes) {
      if (typeOb.key === this.purchase.type) {
        this.purchase.typeObject = typeOb;
      }
    }

    await this.updateLocationOptions();
    await this.updateCustomerOptions();
  }

  async updateLocationOptions() {
    if (this.purchase.typeObject.sourceType) {
      let locationOptions = await this.locationService.getLocationsByType(this.purchase.typeObject.sourceType);
      let allowedLocationOptions = [];
      for (let locationOption of locationOptions) {
        if (this.authorizationService.checkPermission('purchase.'+this.purchase.type+'.update', locationOption._id) || this.purchase.typeObject.sourceType == 'vendor') {
          allowedLocationOptions.push(locationOption);
        }
      }
  
      this.purchase.locationOptions = allowedLocationOptions;
    } else {
      this.purchase.locationOptions = [];
      this.purchase.locationId = null;
    }

    if (!this.purchase.locationId && this.purchase.locationOptions.length > 0) {
      this.purchaseLocationChange(this.purchase.locationOptions[0]._id);
    }
  }

  async updateCustomerOptions() {
    if (this.purchase.typeObject.targetType) {
      let customerOptions = await this.locationService.getLocationsByType(this.purchase.typeObject.targetType);
      let allowedCustomerOptions = [];
      for (let customerOption of customerOptions) {
        if (this.authorizationService.checkPermission('purchase.'+this.purchase.type+'.update', customerOption._id) || this.purchase.typeObject.targetType == 'vendor') {
          allowedCustomerOptions.push(customerOption);
        }
      }

      this.purchase.customerOptions = allowedCustomerOptions;
    } else {
      this.purchase.customerOptions = [];
      this.purchase.customerId = null;
    }

    if (!this.purchase.customerId && this.purchase.customerOptions.length > 0) {
      this.purchaseCustomerChange(this.purchase.customerOptions[0]._id);
    }
  }

  purchaseLocationChange(locationId) {
    this.purchase.locationId = locationId;
    this.purchase.locationName = this.locationService.getLocation(locationId).name;
  }

  purchaseCustomerChange(locationId) {
    this.purchase.customerId = locationId;
    this.purchase.customerName = this.locationService.getLocation(locationId).name;
  }

  purchaseSubtypeChange(subtype) {
    this.purchase.subtype = subtype;
  }


  itemQtyChange(item, fieldKey) {
    this.updateCalculations();
  }

  removePurchaseItem = async (itemIndex) => {
    this.activeItem = null;
    const item = this.purchase.items[itemIndex];
    item.removing = true;

    this.purchase.items.splice(this.purchase.items.indexOf(item), 1);
    this.purchase.items = [...this.purchase.items];

    this.updateBarcodeIndexes();

    this.updateCalculations();

    // Send Update to Server
    let purchaseUpdate: any = await this.ioService.post('/transfer/removeTransferItem', {
      purchaseId: this.purchase._id,
      type: this.purchase.type,
      locationId: this.purchase.locationId,
      customerId: this.purchase.customerId,
      itemIndex,
      item,
      costTotal: this.purchase.costTotal,
      editSessionKey: this.purchase.editSessionKey,
    });

    this.purchase.timeUpdated = new Date(purchaseUpdate.timeUpdated);
  }

  updateBarcodeIndexes() {
    let itemIndex = 0;
    let barcodeIndexes = {};
    for (let item of this.purchase.items) {
      item.index = itemIndex;

      if (item.barcode) {
        if (Array.isArray(item.barcode)) {
          for (let barcode of item.barcode) {
            barcodeIndexes[String(barcode)] = itemIndex;
          }
        } else {
          barcodeIndexes[String(item.barcode)] = itemIndex;
        }
      }

      itemIndex++;
    }
    this.purchase.barcodeIndexes = barcodeIndexes;
    return;
  }


  fieldClick(field, item) {
    // console.log('fieldClick: ', field);
    switch (field.key) {
      case 'cost':
        this.startEditItemCost(field, item);
        break;
    }
  }


  startEditItemCost(field, item) {
    // Verify permission and purchase type
    if (this.purchase.authorization.editPrice && this.purchase.type == 'vendor-retail' && (this.purchase.status == 'open' || this.purchase.status == 'pending')) {
      console.log('START EDIT COST!: ', item);
      console.log('field: ', field);
      this.purchase.costEdit = {
        item,
        cost: (item.cost/100).toFixed(2),
        fieldLabel: field.label
      };
    }
  }

  cancelCostEdit() {
    this.purchase.costEdit = null;
  }

  saveCostEdit = async () => {
    // Update Vendor Product
    let vendorProductPriceUpdate: any = await this.ioService.post('/inventory/updateVendorProductPrice', {
      accountId: this.purchase.costEdit.item.accountId,
      price: this.purchase.costEdit.cost,
    });

    // Refresh line item cost
    this.purchase.costEdit.item.cost = vendorProductPriceUpdate.vendorProductPrice;
    this.saveItem(false);
    this.cancelCostEdit();

    await this.updateCalculations();
  }

  numberRound = (value, decimals) => {
    return Number(Math.round(Number(value+'e'+decimals))+'e-'+decimals);
  };

  selectSearchResult(searchResult) {
    this.addPurchaseItem(searchResult);
    // this.purchase.searchResults = null;
  }

  addPurchaseItem = async (item, updateCalculations=true, defaultZeros=false) => {

// console.log('addPurchaseItem: ', item);
    let sourceQty = 0;
    let targetQty = 0;
    let adjustedQty = 0;
    let qty = 0;
    let childAccountId;
    let childQty;
    


    for (let field of this.purchase.typeObject.fields[this.purchase.status]) {
      if (field.default) {
        if (field.key === 'qty') {
          qty = field.default;
        }
      }
    }



    if (item.balance && item.balance[this.purchase.locationId]) {
      sourceQty = item.balance[this.purchase.locationId];
    }
    
    if (item.balance && item.balance[this.purchase.customerId]) {
      targetQty = item.balance[this.purchase.customerId];
    }

    if (this.purchase.type == 'adjustment-retail' && !this.purchase.strictInventoryCountable) {

      if (item.descendantAccountIds && item.descendantAccountIds.length > 0 && (item.descendantAccountIds[0] != item._id || item.descendantAccountIds.length > 1)) {
        targetQty = 0;
        childAccountId = item.options[0].options[0].accountId;
        childQty = item.options[0].options[0].qty;
      }

      if (defaultZeros) {
        qty = targetQty*-1;
      } else {
        adjustedQty = targetQty;
      }
      
    }

    if (item.subtype == 'vendor-product') {
      item.cost = item.price;
    } else {
      if (this.purchase.type == 'vendor-retail' || this.purchase.type == 'retail-vendor' || !item.cost) {
        if (item.calculatedCost && item.calculatedCost.default) {
          item.cost = item.calculatedCost.default;
        }
      }
    }

    let newItem:any = {
      accountId: item._id,
      name: item.name,
      categoryName: item.categoryName,
      barcode: item.barcode,
      remoteId: item.remoteId,
      unitOfMeasure: item.unitOfMeasure,
      unitDescription: item.unitDescription,
      cost: item.cost,
      price: item.price,
      sourceQty: this.numberRound(sourceQty, 4),
      targetQty: this.numberRound(targetQty, 4),
      adjustedQty: this.numberRound(adjustedQty, 4),
      qty: this.numberRound(qty, 4),
      serverQty: this.numberRound(qty, 4),
      sku: item.sku,
      // uiCost: (item.cost / 100).toFixed(2),
      index: this.purchase.items.length,
    };

    if (childAccountId) {
      newItem.childAccountId = childAccountId;
      newItem.childQty = childQty;
    }

    this.purchase.items = [...this.purchase.items, newItem];


    if (updateCalculations) {

      if (newItem.barcode) {
        if (Array.isArray(newItem.barcode)) {
          for (let barcode of item.barcode) {
            this.purchase.barcodeIndexes[String(barcode)] = newItem.index;
          }
        } else {
          this.purchase.barcodeIndexes[String(item.barcode)] = newItem.index;
        }
      }
  
      if (this.purchase.searchResults && this.purchase.searchResults.length > 0) {
        this.purchase.searchResults.splice(this.purchase.searchResults.indexOf(item), 1);
      }

      this.purchase.savingUpdates = true;

      // Send Update to Server
      let purchaseUpdate: any = await this.ioService.post('/transfer/updateTransferItem', {
        purchaseId: this.purchase._id,
        type: this.purchase.type,
        locationId: this.purchase.locationId,
        customerId: this.purchase.customerId,
        itemIndex: newItem.index,
        item: newItem,
        new: true,
        editSessionKey: this.purchase.editSessionKey,
      });

      this.purchase.timeUpdated = new Date(purchaseUpdate.timeUpdated);

      this.purchase.savingUpdates = false;

      this.updateCalculations();
    }
    // this.save();
    return newItem;
  }

  addAll = async () => {

    // console.log(this.purchase);

    if (!this.purchase.adding) {
      this.purchase.adding = true;
      this.purchase.loading = true;

      let existingInventoryIds = this.purchase.items.map(item => {
        return item.accountId;
      });

      let accountFind: any = {
        purchaseId: this.purchase._id,
        // omit: existingInventoryIds,
        // categoryId: this.purchase.selectedCategory._id,
      };

      console.log('selectedCategory: ', this.purchase.selectedCategory);
      console.log('selectedCategory._id: ', this.purchase.selectedCategory._id);
      console.log('selectedCategory.name: ', this.purchase.selectedCategory.name);

      if (this.purchase.selectedCategory.subtype == 'category') {
        accountFind.categoryId = this.purchase.selectedCategory._id;
      }

      if (this.purchase.selectedCategory.subtype == 'group') {
        accountFind.groupId = this.purchase.selectedCategory._id;
      }

      let allItems:any = await this.ioService.post('/transfer/getInventoryAccounts', accountFind);

      for (let item of allItems) {
        await this.addPurchaseItem(item, false, true);
      }

      // this.updateCalculations();

      // this.purchase.adding = false;

      let purchaseUpdateResponse: any = await this.ioService.post('/transfer/updateTransfer', {
        purchase: this.purchase
      });

      this.loadPurchase();
    }

    return;
  }

  clearSearch() {
    this.purchase.searchText = null;
    this.searchInventory();
  }

  searchInventory = async () => {
    this.purchase.searchResults = null;

    if (this.purchase.searchTimeout) {
      clearTimeout(this.purchase.searchTimeout);
    }

    if (this.purchase.searchText != '' && this.purchase.searchText != null) {
      this.purchase.searching = true;

      this.purchase.searchTimeout = setTimeout(async () => {
        let existingInventoryIds = this.purchase.items.map(item => {
          return item.accountId;
        });
    
        let inventory = await this.ioService.post('/transfer/getInventoryAccounts', {
          purchaseId: this.purchase._id,
          search: this.purchase.searchText,
          limit: 10,
          // omit: existingInventoryIds
        });
    
        this.purchase.searchResults = inventory;
        this.purchase.searching = false;
      }, 750);


    } else {
      this.purchase.searching = false;
    }
  }

  searchKeyDown(event: any) {
    if (event.key === "Enter" && this.purchase && this.purchase.searchResults && this.purchase.searchResults.length > 0) {
      this.selectSearchResult(this.purchase.searchResults[0]);
      this.searchInventory();
    }
  }

  removeAll = async () => {
    this.purchase.loading = true;
    this.purchase.items = [];
    let purchaseUpdateResponse: any = await this.ioService.post('/transfer/updateTransfer', {
      purchase: this.purchase
    });
    this.updateBarcodeIndexes();
    this.purchase.loading = false;
  }

  markAllCounted = async () => {
    this.purchase.loading = true;
    for (let purchaseItem of this.purchase.items) {
      purchaseItem.counted = true;
    }
    await this.updateCalculations();
    this.purchase.loading = false;
  }

  markAllReceived = async () => {
    this.purchase.loading = true;
    for (let purchaseItem of this.purchase.items) {
      purchaseItem.qty = purchaseItem.expectedQty;
    }
    
    let purchaseUpdateResponse: any = await this.ioService.post('/transfer/updateTransfer', {
      purchase: this.purchase
    });

    this.loadPurchase();
  }

  updateCalculations = async () => {
    console.log('updateCalculations: ');
    // console.log(this.purchase);

    let qtyTotal = 0;
    let costTotal = 0;
    let uncounted = 0;
    
    for (let purchaseItem of this.purchase.items) {
console.log('updating item: ', purchaseItem);
      if (purchaseItem.price && !purchaseItem.cost && this.purchase.typeObject.defaultCostFromPrice === true) {
        purchaseItem.cost = purchaseItem.price;
      }

      if (!purchaseItem.qty) {
        purchaseItem.qty = 0;
      } else {
        if (this.purchase.typeObject.allowNegativeQty === false && purchaseItem.qty < 0) {
          console.log('negative qty not allowed');
          if (this.purchase.typeObject.invertNegativeQty === true) {
            purchaseItem.qty *= -1;
          } else {
            purchaseItem.qty = 0;
          }
        }
      }

      let previousCount = 0;
      if (purchaseItem.previousCount) {
        previousCount = purchaseItem.previousCount;
      }

      purchaseItem.sourceQtyCalculated = purchaseItem.sourceQty - purchaseItem.qty - previousCount;
      purchaseItem.targetQtyCalculated = purchaseItem.targetQty + purchaseItem.qty - previousCount;
      purchaseItem.lineCost = purchaseItem.qty*purchaseItem.cost;
      purchaseItem.differenceQty = purchaseItem.qty - purchaseItem.expectedQty;

      costTotal += purchaseItem.lineCost;
      qtyTotal += purchaseItem.qty;

      if (!purchaseItem.counted) {
        uncounted++;
      }

// console.log('item updated: ', purchaseItem);
    }

    this.purchase.costTotal = costTotal;
    this.purchase.qtyTotal = qtyTotal;
    this.purchase.uncounted = uncounted;

    console.log('uncounted: ', this.purchase.uncounted);
    // console.log('COST TOTAL: ', costTotal);
  }

  startEditStatus(status) {
    let msg = "Are you sure?"
    switch (status) {
      case 'pending':
        msg = "Are you sure you are done building this transfer?";
        break;
    }

    this.purchase.statusEdit = {
      status,
      msg
    };
  }

  cancelEditStatus() {
    this.purchase.statusEdit = null;
  }

  updateTransferStatus = async (status) => {
    this.purchase.loading = true;

    setTimeout(async () => {
      try {
        let purchaseStatusResponse: any = await this.ioService.post('/transfer/updateTransferStatus', {
          purchaseId: this.purchase._id,
          status,
          returnPurchase: true,
          defaultPendingQty: this.purchase.typeObject.defaultPendingQty,
        });
    
        await this.loadPurchase(purchaseStatusResponse.purchase);
      } catch(e) {
        alert('ERROR UPDATING PURCHASE STATUS: '+e);
        this.purchase.loading = false;
      }
    }, 1000);

    return;
  }

  save = async () => {
    console.log('save: ', this.purchase);

    let purchaseUpdateResponse: any = await this.ioService.post('/transfer/updateTransfer', {
      purchase: this.purchase
    });

    if (this.purchase.status != 'deleted') {
      this.loadPurchase();
    }
  }

  delete = async () => {
    this.purchase.status = 'deleted';
    await this.save();
    this.locationR.back();
  }

  // send = async () => {
  //   if (this.purchase.items.length > 0) {
  //     this.purchase.status = 'pending';
  //     this.save();
  //   } else {
  //     this.purchase.errors = ['Transfer must contain at least 1 item.'];
  //   }
  // }

  // revoke = async () => {
  //   this.purchase.status = 'open';
  //   this.save();
  // }

  complete = async () => {
    console.log('complete: ', this.purchase);

    if (!this.purchase.itemSaving) {

      this.purchase.saving = true;
      this.purchase.loading = true;

      try {
        let purchaseCompleteResponse: any = await this.ioService.post('/transfer/completeTransfer', {
          purchaseId: this.purchase._id
        });
        this.loadPurchase();
      } catch(purchaseCompleteError) {
        console.log('purchaseCompleteError: ', purchaseCompleteError.error);

        if (purchaseCompleteError.error && purchaseCompleteError.error.msg && purchaseCompleteError.error.detail) {
          await this.loadPurchase();

          let errorMsg = 'Vendor item with bad configuration: '+purchaseCompleteError.error.detail.name+'. Transfer not completed.';

          this.purchase.errors = [
            {
              msg: errorMsg
            }
          ];


          console.log('purchaseWithErrors: ', this.purchase);
        }
      }
    }
  }


  retry = async () => {
    console.log('retry: ', this.purchase);
    this.purchase.saving = true;

    try {
      let purchaseCompleteResponse: any = await this.ioService.post('/transfer/completeTransfer', {
        purchaseId: this.purchase._id,
        retry: true,
      });
      this.loadPurchase();
    } catch(purchaseCompleteError) {
      console.log('purchaseCompleteError: ', purchaseCompleteError.error);

      if (purchaseCompleteError.error && purchaseCompleteError.error.msg && purchaseCompleteError.error.detail) {
        await this.loadPurchase();

        let errorMsg = 'Vendor item with bad configuration: '+purchaseCompleteError.error.detail.name+'. Transfer not completed.';

        this.purchase.errors = [
          {
            msg: errorMsg
          }
        ];

      }
    }
  }





  // itemCostChange(item) {
  //   item.cost = item.uiCost*100;
  //   this.updateCalculations();
  // }




  importPurchase() {
    let el: HTMLElement = this.importEl.nativeElement;
    el.click();
  }

  onCsvFileChange = async (event) => {
    if (event.target.files.length > 0) {
      this.purchase.loading = true;
      const file = event.target.files[0];
      this.ioService.upload('transfer/import', {
        purchaseId: this.purchase._id
      }, file).subscribe(async event => {
        console.log(event);
        if (event.type == 4) {
          await this.loadPurchase();
          if (event['body'] && event['body'].errors) {
            console.log('ERRORS: ', event['body'].errors);
            this.purchase.errors = event['body'].errors;
          }
        }
      });
    }
  }

  downloadPurchase = async (reportKey) => {

    let fileExtension = '.csv';
    try {
      let templateConfig:any = await this.ioService.post('/template/getTemplate', {
        key: reportKey
      });
  
      if (templateConfig && templateConfig.fileExtension) {
        fileExtension = templateConfig.fileExtension;
      }
    } catch(e) {

    }

    let fileName = this.purchase.key+fileExtension;

    if (this.purchase.customerName) {
      fileName = this.purchase.customerName+'-'+fileName;
    }

    if (this.purchase.locationName) {
      fileName = this.purchase.locationName+'-'+fileName;
    }

    this.ioService.download('/report/loadRecords', {
      key: reportKey,
      utcOffset: new Date().getTimezoneOffset(),
      clientConfig: {
        download: true,
        clientFilters: [
          {
            type: 'id',
            path: '_id',
            value: [
              this.purchase._id
            ]
          }
        ]
      }
    }, fileName);

  }

  printReport(reportKey, templateKey) {
    // Retrieve data from the server
    this.ioService.print('/report/loadRecords', {
      key: reportKey,
      templateKey: templateKey,
      utcOffset: new Date().getTimezoneOffset(),
      clientConfig: {
        download: true,
        clientFilters: [
          {
            type: 'id',
            path: '_id',
            value: [
              this.purchase._id
            ]
          }
        ]
      }
    });
  }

  barcodeEntered = async (barcode, statusOverride = false) => {


    console.log("BARCODE INDEXES:");
    console.log(this.purchase.barcodeIndexes);

    await this.loadPurchaseUpdates(null);

    let barcodeIndex = this.purchase.barcodeIndexes[barcode];
console.log('barcodeIndexes: ', this.purchase.barcodeIndexes);
// alert('barcodeIndex: '+barcodeIndex);
    
    if (barcodeIndex !== undefined) {
      // This item is found on the active purchase
      console.log('barcodeIndex: '+barcodeIndex);
      setTimeout(() => {
        this.setActiveItem(barcodeIndex);

        if (window.innerWidth >= 700) {
          this.viewPort.scrollToIndex(barcodeIndex);
        }

      }, 0);
    } else {
      if (this.purchase.status == 'open' || statusOverride) {
        // Search the database for this barcode
        let existingInventoryIds = this.purchase.items.map(item => {
          return item.accountId;
        });
    
        let barcodeItems:any = await this.ioService.post('/transfer/getInventoryAccounts', {
          purchaseId: this.purchase._id,
          // omit: existingInventoryIds,
          barcode,
        });

        console.log('barcodeItems: ', barcodeItems);

        if (barcodeItems && barcodeItems.length > 0) {
          for (let barcodeItem of barcodeItems) {
            await this.addPurchaseItem(barcodeItem);
            setTimeout(() => {
              barcodeIndex = this.purchase.barcodeIndexes[barcode];
              this.setActiveItem(barcodeIndex);
            }, 10);
          }
        } else {
          alert('ITEM NOT FOUND: *'+barcode+'*');
        }

        // if (barcodeItems && barcodeItems.length == 1) {
        //   await this.addPurchaseItem(barcodeItems[0]);
        //   setTimeout(() => {
        //     barcodeIndex = this.purchase.barcodeIndexes[barcode];
        //     this.setActiveItem(barcodeIndex);
        //   }, 10);
        // } else {
        //   alert('Multiple items found: *'+barcode+'*');
        // }

      } else {
        // alert('ITEM NOT FOUND: *'+barcode+'*');
        if (this.purchase.status != 'complete') {
          this.purchase.missingBarcode = barcode;
        }
        
      }
      
    }
  }

  fieldTab = () => {
    console.log('FIELD TAB');
    console.log('activeItemIndex: ', this.activeItem.index);

    this.confirmChange(this.activeItem);

    if (this.activeItem.index+1 < this.purchase.items.length) {
      this.setActiveItem(this.activeItem.index+1);
    }
    
    setTimeout(() => {
      const targetInput = document.getElementById('item-'+this.activeItem.index+'-'+this.purchase.inputTargetField) as HTMLInputElement;
      targetInput.focus();
      targetInput.select();
    }, 0);

  }

  saveButton = () => {
    if (this.activeItem) {
     this.confirmChange(this.activeItem);
     this.activeItem = null;
    }
  }

  setActiveItem = async (itemIndex) => {
    if (this.purchase.status != 'complete') {
      let item = this.purchase.items[itemIndex];

      if (window.innerWidth < 700) {

        if (this.purchase.containsEditableFields) {
          if (this.activeItem) {
            if (itemIndex === this.activeItem.index) {
              // This is the active item, add to its count
      
              this.changePurchaseItemValue(this.activeItem, 1);
            } else {
              // An item is already active
  
              // Prompt for save?
  

              this.viewPort.scrollToIndex(itemIndex);

              // Load Purchase Updates for this item only
              await this.loadPurchaseUpdates(null, [itemIndex]);

              let activeItem = this.purchase.items[itemIndex];
              this.activeItem = activeItem;
              console.log('activeItem: ', this.activeItem);
            }
      
          } else {
            this.viewPort.scrollToIndex(itemIndex);

            // If item is counted, prompt for add to count or change count
            if (item.counted && this.purchase.inputTargetField == 'targetQtyCalculated') {
            //   this.purchase.modeSelect = true;
              item.previousCount = item.targetQtyCalculated;
              item.targetQtyCalculated = 0;
            }

            this.activeItem = item;
            console.log('activeItem: ', this.activeItem);
          }
        }

      } else {
        if (this.activeItem == item) {
          // console.log('this is already the active item');
        } else {
          console.log('this is NOW the active item');
          this.activeItem = item;
        }
      }
    }

    return;
  }

  qtyChange(newValue) {
    let change = newValue - this.purchase.inputTargetStartValue;

    // console.log('purchase.typeObject: ', this.purchase.typeObject);



    // console.log('startValue: ', this.purchase.inputTargetStartValue);
    // console.log('newValue: ', newValue);
    // console.log('change: ', change);

    this.purchase.inputTargetStartValue = newValue;

    switch (this.purchase.inputTargetField) {
      case 'sourceQtyCalculated':
        this.activeItem.qty -= change;
        break;
      
      case 'targetQtyCalculated':
        this.activeItem.qty += change;
        break;
      
      case 'expectedQty':

        break;
    }




    this.itemQtyChange(this.activeItem, this.purchase.inputTargetField);
  }

  confirmChange = async (item) => {
    console.log('Save change!');

    // if (item.qty != item.serverQty) {
      this.purchase.itemSaving = true;
      item.saving = true;

      this.purchase.savingUpdates = true;

      let countUpdate: any = await this.ioService.post('/transfer/updateTransferItem', {
        purchaseId: this.purchase._id,
        editSessionKey: this.purchase.editSessionKey,
        type: this.purchase.type,
        locationId: this.purchase.locationId,
        customerId: this.purchase.customerId,
        itemIndex: item.index,
        item: item,
        costTotal: this.purchase.costTotal,
      });

      this.purchase.savingUpdates = false;
  
      this.purchase.timeUpdated = new Date(countUpdate.timeUpdated);

      this.autoSyncDelay = this.autoSyncDelayDefault;
      await this.loadPurchaseUpdates(item.index);

      item.saving = false;
      this.purchase.itemSaving = false;
  
      console.log('change saved');
    // } else {
    //   console.log('no change to save');
    // }

  }

  setInputTargetField(fieldKey, editable = true) {
    console.log('setInputTargetField: ', fieldKey);
    if (editable) {
      if (fieldKey == this.purchase.inputTargetField && window.innerWidth < 700) {
        // this.activeItem[this.purchase.inputTargetField]++;
       this.changePurchaseItemValue(this.activeItem, 1);
      } else {
        this.purchase.inputTargetField = fieldKey;
        this.purchase.inputTargetStartValue = this.activeItem[fieldKey];
      }
    }
  }

  screenBtnPress(value) {
    if (!this.activeItem.saving) {

      if (this.activeItem[this.purchase.inputTargetField] == undefined) {
        this.activeItem[this.purchase.inputTargetField] = 0;
      }

      let targetField = this.activeItem[this.purchase.inputTargetField];
      let targetStartValue = targetField;

      console.log('targetFieldKey: ', this.purchase.inputTargetField);
      console.log('targetFieldValue: ', targetField);

      let stringValue = targetField.toString();


      if (Number.isInteger(value)) {
        if (stringValue == "0") {
          stringValue = "";
        }


        if (this.activeItem.edited) {
          stringValue = stringValue+value;
        } else {
          stringValue = value;
          this.activeItem.edited = true;
        }

        
      } else {
        switch (value) {
          case "d":
            stringValue = stringValue.slice(0, -1);
            break;
  
          case "c":
            stringValue = "0";
            break;
        }
      }

      if (stringValue == "") {
        stringValue = "0";
      }


      let targetNewValue = parseInt(stringValue);

      console.log('targetStartValue: ', targetStartValue);
      console.log('targetNewValue: ', targetNewValue);
      let change = targetNewValue - targetStartValue;
      console.log('change: ', change);

      this.changePurchaseItemValue(this.activeItem, change);
    }
  }

  buildUpdateQueue() {

  }

  changePurchaseItemValue(item, change) {
    console.log('changePurchaseItemValue: ', change);

    switch (this.purchase.inputTargetField) {
      case 'sourceQtyCalculated':
        item.qty -= change;
        break;

      case 'qty':
        item.qty += change;
        break;
      
      case 'targetQtyCalculated':
        item.qty += change;
        break;
      
      case 'expectedQty':

        break;
    }

    this.itemQtyChange(this.activeItem, this.purchase.inputTargetField);
  }

  cancel = async (markUncounted = false) => {
    this.purchase.loading = true;

    delete this.activeItem.edited;
    delete this.activeItem.previousCount;
    
    // Refresh this item
    await this.loadPurchaseUpdates(null, [this.activeItem.index]);

    try {


      //      await this.saveItem(!markUncounted);
      this.activeItem = null;


      this.purchase.loading = false;
      return;
    } catch(e) {
      console.log('item not found. reload purchase.');
      this.loadPurchase();
    }

  }

  saveItem = async (counted = true) => {
    let activeItem = this.activeItem;

    activeItem.saving = true;

    delete this.activeItem.edited;

    if (this.purchase.status == 'pending') {
      activeItem.counted = counted;
    }

    if (activeItem.previousCount) {
      delete activeItem.previousCount;
    }

    try {

      this.purchase.savingUpdates = true;

      // Send Update to Server
      let countUpdate: any = await this.ioService.post('/transfer/updateTransferItem', {
        purchaseId: this.purchase._id,
        editSessionKey: this.purchase.editSessionKey,
        type: this.purchase.type,
        locationId: this.purchase.locationId,
        customerId: this.purchase.customerId,
        itemIndex: activeItem.index,
        item: activeItem,
        costTotal: this.purchase.costTotal,
      });

      this.updateCalculations();


      this.purchase.savingUpdates = false;

      activeItem.saving = false;

      this.loadPurchaseUpdates(null);

      this.activeItem = null;
      return;
    } catch(e) {
      console.log('error saving update: ', e);
      this.loadPurchase();
    }

  }

  loadPurchaseUpdates = async (omitIndex, includeIndexes = []) => {
    if (this.autoSyncTimeout) {
      clearTimeout(this.autoSyncTimeout);
    }

    let purchaseUpdatesResponse;

    if (!this.purchase.savingUpdates) {

      purchaseUpdatesResponse = await this.ioService.post('/transfer/getTransferUpdates', {
        purchaseId: this.purchase._id,
        editSessionKey: this.purchase.editSessionKey,
        lastRetrieved: this.purchase.lastRetrieved,
        omitIndex,
        includeIndexes,
      });

    } else {
      console.log('!!! do not load purchase updates while saving updates');
    }


    if (this.autoSyncTimeout) {
      clearTimeout(this.autoSyncTimeout);
    }

    // Prevent autoSync if transfer is complete
    if (this.purchase.status != 'complete') {
      this.autoSyncTimeout = setTimeout(() => {
        console.log('setting autoSyncTimeout: ', this.autoSyncDelay+' seconds');
        this.loadPurchaseUpdates(null);
      }, this.autoSyncDelay*1000);
      this.autoSyncDelay *= this.autoSyncDelayMultiple;
      if (this.autoSyncDelay > this.autoSyncDelayMax) {
        this.autoSyncDelay = this.autoSyncDelayMax;
      }
    }



    if (purchaseUpdatesResponse && purchaseUpdatesResponse.updatedItems && purchaseUpdatesResponse.updatedItems.length > 0 && !this.purchase.savingUpdates) {
      try {
        for (let updatedItem of purchaseUpdatesResponse.updatedItems) {
          if (this.purchase.items[updatedItem.index].accountId == updatedItem.item.accountId) {
            this.purchase.items[updatedItem.index].qty = updatedItem.item.qty;
            this.purchase.items[updatedItem.index].expectedQty = updatedItem.item.expectedQty;
            this.purchase.items[updatedItem.index].counted = updatedItem.item.counted;
            if (this.purchase.items[updatedItem.index].cost !== null) {
              this.purchase.items[updatedItem.index].cost = updatedItem.item.cost;
            }
            if (this.purchase.items[updatedItem.index].price !== null) {
              this.purchase.items[updatedItem.index].price = updatedItem.item.price;
            }
          } else {
      
            console.log('item index mismatch. reload purchase.');

            throw {
              message: 'accountId mismatch'
            };
          }

        }

        this.updateBarcodeIndexes();
        console.log('barcode indexes updated');
        this.checkCountComplete();

        this.purchase.lastRetrieved = purchaseUpdatesResponse.lastRetrieved;
    
        this.updateCalculations();
        return;
      } catch (error) {
        this.loadPurchase();
      }

    }
  }

  checkCountComplete() {
    let total = 0;
    let counted = 0;
    for (let item of this.purchase.items) {
      total ++;
      if (item.counted) {
        counted++;
      } else {
        if (item.targetQty == 0) {
          counted++;
        }
      }
    }

    if (counted == total) {
      this.purchase.countComplete = true;
    } else {
      this.purchase.countComplete = false;
    }

    return {
      total,
      counted,
    };
  }



  stopPropagation(event) {
    event.stopPropagation();
  }

  setMode(mode) {
    switch(mode) {
      case 'cancel':
        this.cancel();
        break;

      case 'change':
        this.activeItem.counted = false;
        break;
      
      case 'add':
        this.activeItem.previousCount = this.activeItem.targetQtyCalculated;
        this.activeItem.targetQtyCalculated = 0;
        break;
    }

    this.purchase.modeSelect = false;
  }

  startEditSettings() {
    if ((this.purchase.status == 'open' || this.purchase.status == 'pending') && this.purchase.authorization.update) {
      this.purchase.settingsEdit = {
        name: this.purchase.name,
        note: this.purchase.note[0]
      };
    }
  }

  cancelSettingsEdit() {
    this.purchase.settingsEdit = null;
  }

  saveSettingsEdit = async () => {
    this.purchase.name = this.purchase.settingsEdit.name;
    this.purchase.note[0] = this.purchase.settingsEdit.note;
    this.save();
  }

  cancelMissingBarcode() {
    this.purchase.missingBarcode = null;
  }

  acknowledgeErrors() {
    this.purchase.errors = null;
  }

}
