import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { HistoryService } from '../../../services/history/history.service';
import { IoService } from '../../../services/io/io.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';
import { MessageService } from '../../../services/message/message.service';
import { LoadingComponent } from '../../shared/loading/loading.component';
import { AppComponent } from 'src/app/app.component';
import { ReportService } from 'src/app/services/report/report.service';

@Component({
  selector: 'app-detail-purchase',
  templateUrl: './detail-purchase.component.html',
  styleUrls: ['./detail-purchase.component.scss']
})
export class DetailPurchaseComponent implements OnInit {

  documentId$: Observable<any>;
  documentId;

  showBackButton;

  purchase;

  conversationActive;
  conversationActiveUpdated: Subscription;

  permissionsLoaded: Subscription;
  permissions;

  sendingReceipt;
  receiptText = '';

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private historyService: HistoryService,
    private ioService: IoService,
    private authorizationService: AuthorizationService,
    private messageService: MessageService,
    private reportService: ReportService,
  ) { }

  ngOnInit() {
    this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
    this.documentId$.subscribe(documentId => {
      if (documentId) {
        this.documentId = documentId;
        this.loadPurchaseDetail();
      }
    });

    if (this.historyService.getPreviousLinkDepth() > 3) {
      this.showBackButton = true;
    }

    this.conversationActiveUpdated = this.messageService.conversationActiveUpdatedObservable().subscribe(conversationActive => {
      this.conversationActive = conversationActive;
    });
    this.conversationActive = this.messageService.getConversationActive();
  }

  ngOnDestroy() {
    if (this.conversationActiveUpdated) {
      this.conversationActiveUpdated.unsubscribe();
    }
    if (this.permissionsLoaded) {
      this.permissionsLoaded.unsubscribe();
    }
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  back() {
    this.location.back();
  }

  monitorPermissions() {
    if (this.authorizationService.permissionsAreLoaded()) {
      this.checkPermissions();
    } else {
      this.permissionsLoaded = this.authorizationService.permissionsLoadedObservable().subscribe(() => {
        this.checkPermissions();
      });
    }
  }

  checkPermissions() {

    this.permissions.canManageInventory = this.authorizationService.checkPermission('account.inventory.manage', this.purchase.locationId, false);
    this.permissions.canCancel = this.authorizationService.checkPermission('purchase', this.purchase.locationId);
    this.permissions.canReturn = this.authorizationService.checkPermission('return', this.purchase.locationId);
    this.permissions.canEdit = this.authorizationService.checkPermission('view.purchase', this.purchase.locationId);
    this.permissions.canDelete = this.authorizationService.checkPermission('purchase.delete', this.purchase.locationId);
    this.permissions.canSendReceipt = this.authorizationService.checkPermission('purchase.receipt', this.purchase.locationId);
    this.permissions.canComplete = this.authorizationService.checkPermission('purchase.complete', this.purchase.locationId)
                                   && this.purchase.status === 'open' && this.purchase.subtotal !== 0 && this.purchase.due === 0;
  }

  loadDetail(type, id) {
    if (type != 'inventory' || this.permissions.canManageInventory) {
      this.router.navigate(['../../'+type+'/'+id], { relativeTo: this.route });
    }
  }

  loadPurchaseDetail() {
    this.receiptText = '';
    return new Promise((resolve, reject) => {
      if (this.permissionsLoaded) {
        this.permissionsLoaded.unsubscribe();
      }
      this.permissions = {
        canCancel: false,
        canReturn: false,
        canEdit: false
      };
      this.purchase = null;
      this.ioService.post('/purchase/getPurchase', {
        purchaseId: this.documentId,
        detail: true,
      }).then((purchase: any) => {

        let transactions = [];

        let inventoryTransactions = [];

        let taxTransactions = [];

        let discountTransactions = [];


        let addValues = [];
        let pendingTransactions = false;
        for (let transaction of purchase.transactions) {
          if (transaction.type == 'gift' || transaction.type == 'Gift') {
            transaction.cardType = 'Gift Card';
          }

          if (transaction.type == 'cash') {
            transaction.cardType = 'Cash';
          }

          if (transaction.type == 'check') {
            transaction.cardType = 'Check';
          }

          if (transaction.type == 'invoice') {
            transaction.cardType = 'Invoice';
          }

          if (transaction.type == 'reward') {
            transaction.cardType = 'Reward';
          }

          if (transaction.type == 'billingAccount') {
            transaction.cardType = 'Billing Account';
            if (transaction.accountName) {
              transaction.cardType = transaction.accountName;
            }
          }

          if (transaction.type == 'credit') {
            transaction.cardType = 'Credit';
            if (transaction.cardBrand) {
              transaction.cardType = transaction.cardBrand;
            }
          }

          if (transaction.status == 'pending') {
            pendingTransactions = true;
          }

          switch (transaction.type) {
            case 'cash':
            case 'gift':
            case 'check':
            case 'credit':
            case 'invoice':
            case 'reward':
            case 'billingAccount':
              transactions.push(transaction);
              break;

            case 'inventory':
              inventoryTransactions.push(transaction);
              break;

            case 'discount':
              discountTransactions.push(transaction);
              break;

            case 'tax':
              taxTransactions.push(transaction);
              break;
          }

          // if (transaction.amount <= 0 && !transaction.omit) {
          //   transactions.push(transaction);
          // } else {
          //   if (transaction.type == 'gift' || transaction.type == 'Gift') {
          //     addValues.push(transaction);
          //   }
          // }


        }


        purchase.pendingTransactions = pendingTransactions;
        purchase.transactions = transactions;

        purchase.inventoryTransactions = inventoryTransactions;
        purchase.discountTransactions = discountTransactions;
        purchase.taxTransactions = taxTransactions;

        purchase.addValues = addValues;

        if (!purchase.due) {
          purchase.due = 0;
        }

        this.purchase = purchase;
        console.log(this.purchase);

        this.monitorPermissions();
      });
    });
  }

  cancelPurchase() {
    console.log('cancel purchase');
    this.ioService.post('/purchase/cancel', {
      purchaseId: this.documentId
    }).then((cancelResponse: any) => {
      this.loadPurchaseDetail();
    }).catch(err => {

    });
  }

  sendReceipt() {
    if (!this.sendingReceipt) {
      LoadingComponent.showLoading();
      this.sendingReceipt = true;
      this.ioService.post('/receipt/send', {
        purchaseId: this.purchase._id,
        email: this.purchase.email
      }).then((receiptResponse: any) => {
        console.log('receiptResponse: ', receiptResponse);
        this.sendingReceipt = false;
        this.receiptText = 'Receipt Sent Successfully';
      })
      .finally(() => {
        LoadingComponent.hideLoading();
      });
    }
  }

  deletePurchase() {
    console.log('deletePurchase');
  }

  async completePurchase() {
    await this.ioService.post('purchase/completePurchase', { purchaseId: this.purchase._id } )
    .then( (response: any) => {
      this.reportService.loadReport(null);
      this.router.navigate(['../../'], { relativeTo: this.route });
      AppComponent.showSnack( 'Purchase is ' + response.purchaseStatus, 'OK', 3000 );
    });
  }
}
