<div class="wizard-wrapper">
    <div *ngIf="campaign" class="wizard">
        <div class="step">
            <div class="campaign-name" (click)="goToStep('name')">{{campaign.name}}</div>
            <div class="step-header">
                <!-- <div class="toggle-wrapper">
                    <app-toggle (valueChanged)="toggleLive()" [checked]="campaign.live"></app-toggle>
                </div> -->
                <div>{{campaign.step.name}}</div>
                <!-- <div class="debug">{{campaign.step.key}}</div> -->
                <div class="spacer"></div>
                <div class="close" (click)="close()"></div>
            </div>

            <div class="step-body">

                <div *ngIf="campaign.step.fields?.length" class="fields" [class.horizontal]="campaign.step.fieldsDirection == 'horizontal'">
                    <ng-container *ngFor="let field of campaign.step.fields">

                        <div *ngIf="field.type == 'text'" class="input-field" [class]="field.class">
                            <input type="text" [placeholder]="field.placeholder" [(ngModel)]="campaign[field.key]" (ngModelChange)="fieldUpdated(field, $event)" />
                        </div>

                        <div *ngIf="field.type == 'number'" class="input-field" [class]="field.class">
                            <div *ngIf="field.name" class="label">{{field.name}}</div>
                            <input type="number" [(ngModel)]="campaign[field.key]" (ngModelChange)="fieldUpdated(field, $event)" />
                        </div>

                        <div *ngIf="field.type == 'currency'" class="input-field" [class]="field.class">
                            <div *ngIf="field.name" class="label">{{field.name}}</div>
                            <input type="number" [(ngModel)]="campaign[field.key]" (ngModelChange)="fieldUpdated(field, $event)" />
                        </div>

                        <div *ngIf="field.type == 'checkbox'" class="checkbox" [class]="field.class">
                            <input type="checkbox" [(ngModel)]="campaign[field.key]" (ngModelChange)="fieldUpdated(field, $event)" />
                            {{field.name}}
                        </div>

                        <div *ngIf="field.type == 'select'" class="input-field" [class]="field.class">
                            <ng-container *ngFor="let option of field.options">
                                <div class="option" [class.active]="campaign[field.key] == option.key" (click)="selectOption(field.key, option.key)">{{option.name}}</div>
                            </ng-container>
                        </div>

                        <div *ngIf="field.type == 'selectMultiple'" class="input-field" [class]="field.class">
                            <ng-container *ngFor="let option of field.options">
                                <div class="option" [class.active]="campaign[field.key]?.includes(option._id)" (click)="toggleOption(field.key, option._id)">{{option.name}}</div>
                            </ng-container>
                        </div>

                        <div *ngIf="field.type == 'timeRange'" class="input-field time-range" [class]="field.class">
                            <input type="text"
                              ngxDaterangepickerMd
                              [alwaysShowCalendars]="true"
                              [keepCalendarOpeningWithRange]="true"
                              [autoApply]="false"
                              [(ngModel)]="campaign[field.key]"
                              [showDropdowns]="true"
                              [timePicker]="true"
                              (ngModelChange)="rangeChanged($event)"
                            />
                        </div>

                        <div *ngIf="field.type == 'selectMany'" class="select-many" [class]="field.class">
                            <div class="filters">
                                <div class="column-header">Search</div>
                                <div class="search-box">
                                    <input type="text" placeholder="Search" [(ngModel)]="searchText" (keyup)="updateSearch($event)" />
                                </div>

                                <ng-container *ngIf="categories">
                                    <div class="column-header">Category</div>
                                    <select [(ngModel)]="selectedCategory" (change)="getItems()">
                                        <option *ngFor="let category of categories" [value]="category._id">{{category.name}}</option>
                                    </select>
                                </ng-container>
                                
                                <ng-container *ngIf="groups">
                                    <div class="column-header">Group</div>
                                    <select [(ngModel)]="selectedGroup" (change)="getItems()">
                                        <option *ngFor="let group of groups" [value]="group._id">{{group.name}}</option>
                                    </select>
                                </ng-container>

                                <ng-container *ngIf="unitDescription?.length > 0">
                                    <div class="column-header">Unit Description</div>
                                    <select [(ngModel)]="selectedUnitDescription" (change)="getItems()">
                                        <option *ngFor="let unitDescription of unitDescription" [value]="unitDescription">{{unitDescription}}</option>
                                    </select>
                                </ng-container>

                            </div>
                            <div class="results">
                                <div class="column-header">
                                    Results<span>({{results?.length}})</span>
                                    <div *ngIf="results?.length > 0" class="action" (click)="addAll()">Add All</div>
                                </div>
                                <div class="options">
                                    <div *ngFor="let option of results" class="option" [class.active]="option.active" (click)="addOption(option)">
                                        {{option.name}}
                                        <span *ngIf="option.unitDescription" class="unit-description">{{option.unitDescription}}</span>
                                        <span *ngIf="option.categoryName" class="category-name">({{option.categoryName}})</span>
                                    </div>
                                </div>
                            </div>
                            <div class="selected">
                                <div class="column-header">
                                    Selected<span>({{campaign.inventoryAccounts?.length}})</span>
                                    <div *ngIf="campaign.inventoryAccounts?.length > 0" class="action" (click)="clearSelected()">Clear</div>
                                </div>
                                <div class="options">
                                    <div *ngFor="let option of campaign.inventoryAccounts" class="option" (click)="removeOption(option)">
                                        {{option.name}}
                                        <span *ngIf="option.unitDescription" class="unit-description">{{option.unitDescription}}</span>
                                        <span *ngIf="option.categoryName" class="category-name">({{option.categoryName}})</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-container>
                </div>

                <div *ngIf="campaign.step.columns" class="amount">

                    <div class="item-row item-header">
                        <div *ngFor="let field of campaign.step.columns" [class]="field.class">{{field.name}}</div>
                        <div class="item-actions"></div>
                    </div>

                    <div class="item-row-wrapper">
                        <div class="item-row" *ngFor="let item of this.campaign.inventoryAccounts; let itemIndex = index;">

                            <div *ngFor="let field of campaign.step.columns" [class]="field.class">
                                <ng-container *ngIf="!field.editable">
                                    <div *ngIf="field.path" [class.negative]="item[field.path][field.key] < 0">{{item[field.path][field.key] | rf:field.pipe}}</div>
                                    <div *ngIf="!field.path" [class.negative]="item[field.key] < 0">{{item[field.key] | rf:field.pipe}}</div>
                                </ng-container>
                                <ng-container *ngIf="field.editable">
                                    <input
                                        type="text"
                                        id="item-{{itemIndex}}-{{field.key}}"
                                        [(ngModel)]="item.discountSettings[field.key]"
                                        (ngModelChange)="itemFieldUpdated(item, field, $event)"
                                        (keydown.Tab)="fieldTab(field.key, itemIndex);false"
                                        [class.negative]="item[field.path] && item[field.path][field.key] < 0"
                                        />
                                </ng-container>
                            </div>

                            <div class="item-actions">
                                <div class="action delete" (click)="removeItem(item)">X</div>
                            </div>

                        </div>
                    </div>

                </div>

                <div *ngIf="campaign.step.key == 'review'" class="summary">
                    <div class="summary-row" (click)="goToStep('name')">
                        <div class="label">Name</div>
                        <div class="value">{{campaign.name}}</div>
                    </div>
                    <div class="summary-row" (click)="goToStep('subtype')">
                        <div class="label">Type</div>
                        <div class="value">{{discountTypes[campaign.subtype].name}}</div>
                    </div>
                    <!-- <div class="summary-row" (click)="goToStep('locationId')">
                        <div class="label">Location</div>
                        <div class="value">{{campaign.locationId?.name}}</div>
                    </div> -->
                    <div class="summary-row" (click)="goToStep('when')">
                        <div class="label">Start Date</div>
                        <div class="value">{{campaign.timeRange.startDate | date:'MM/dd/yyyy hh:mm a'}}</div>
                    </div>
                    <div class="summary-row" (click)="goToStep('when')">
                        <div class="label">End Date</div>
                        <div class="value">{{campaign.timeRange.endDate | date:'MM/dd/yyyy hh:mm a'}}</div>
                    </div>
                    <div class="summary-row" (click)="goToStep('items')">
                        <div class="label">Items</div>
                        <div class="value capped-height">
                            <ng-container *ngIf="campaign.inventoryAccounts.length > 1000">{{campaign.inventoryAccounts.length}}</ng-container>
                            <ng-container *ngIf="campaign.inventoryAccounts.length <= 1000">
                                <div *ngFor="let item of campaign.inventoryAccounts">
                                    {{item.name}}
                                    <span *ngIf="item.unitDescription" class="unit-description">{{item.unitDescription}}</span>
                                    <span *ngIf="item.categoryName" class="category-name">({{item.categoryName}})</span>
                                    <div *ngIf="campaign.collisions?.[item._id]?.length > 0" class="collision">
                                        <div *ngFor="let collision of campaign.collisions[item._id]">
                                            {{collision.name}}
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div *ngIf="campaign.subtype == 'salePrice'" class="summary-row">
                        <div class="label"></div>
                        <div class="value">
                            <div class="button" (click)="printTags()">Print Tags</div>
                        </div>
                    </div>
                    <div class="summary-row">
                        <div class="label">Campaign Active</div>
                        <div class="value">
                            <app-toggle (valueChanged)="toggleLive()" [checked]="campaign.live"></app-toggle>
                        </div>
                        <div class="button red" (click)="deleteSaleCampaign()">Delete Campaign</div>
                    </div>
                </div>

            </div>

            <div class="step-footer">
                <div *ngIf="campaign.activeStep != 'name'" class="button" (click)="prevStep()">Back</div>
                <div class="stretcher"></div>
                <div *ngIf="campaign.step.showSaveButton" (click)="reset()" class="button yellow">Reset</div>
                <div *ngIf="campaign.step.showSaveButton" (click)="saveUpdates()" class="button green save">Save</div>
                <div *ngIf="campaign.activeStep != 'review'" class="button" (click)="nextStep()">Next</div>
                <div *ngIf="campaign.activeStep == 'review'" class="button green" (click)="close()">Done</div>
            </div>
        </div>
    </div>
</div>
