<div class="detail" [class.visible]="account" [class.shoved]="account && conversationActive">
    <header>
      <div class="close" (click)="close()"></div>
      <div *ngIf="showBackButton" class="back" (click)="back()"></div>
      <h2>Discount</h2>
    </header>
    <section>
      <div *ngIf="!account" class="loading">Loading . . .</div>
      <div *ngIf="account" class="account">      
        <div class="field">
          <label>Discount Name</label>
          <input type="text" [(ngModel)]="account.name" />
        </div>
  
        <div class="field">
          <label>Description</label>
          <textarea class="note-input" [(ngModel)]="account.description"></textarea>
        </div>
  
        <ng-container *ngIf="!account.subtype || account.subtype == 'advanced'">
  
          <div class="line-field">
            <div class="label-large">Auto Apply</div>
            <app-toggle (valueChanged)="toggleBoolean('autoApply')" [checked]="account.autoApply"></app-toggle>
          </div>
          
          <div class="line-field">
            <div class="label-large">Variable Amount</div>
            <app-toggle (valueChanged)="toggleBoolean('variableAmount')" [checked]="account.variableAmount"></app-toggle>
          </div>
  
          <div class="line-field">
            <div class="label-large">Targeted</div>
            <app-toggle (valueChanged)="toggleBoolean('targeted')" [checked]="account.targeted"></app-toggle>
          </div>
  
          <div class="line-field">
            <div class="label-large">Display on Item</div>
            <app-toggle (valueChanged)="toggleBoolean('displayOnItem')" [checked]="account.displayOnItem"></app-toggle>
          </div>
  
          <div class="line-field">
            <div class="label-large">Max Purchase Applications</div>
            <input type="number" [(ngModel)]="account.maxPurchaseApplications" />
          </div>
  
          <div class="line-field">
            <div class="label-large">Barcode</div>
            <input type="text" [(ngModel)]="account.barcode" />
          </div>     
    
          <!-- Purchase Qualifier -->
          <div class="field">
            <label>Purchase Qualifier</label>
          </div>
          <div class="line-field">
            <div class="label-large condensed">Start Date</div>
            <app-toggle (valueChanged)="togglePurchaseQualifierStart()" [checked]="account.purchaseQualifier.timeStart"></app-toggle>
            <app-filter-date-range *ngIf="account.purchaseQualifier.timeStart" class="w-100" [skewed]="true" [rangeConfig]="startDateSettings" (rangeChanged)="startDateChanged($event)"></app-filter-date-range>
          </div>
          <div class="line-field">
            <div class="label-large condensed">End Date</div>
            <app-toggle (valueChanged)="togglePurchaseQualifierEnd()" [checked]="account.purchaseQualifier.timeEnd"></app-toggle>
            <app-filter-date-range *ngIf="account.purchaseQualifier.timeEnd" class="w-100" [skewed]="true" [rangeConfig]="endDateSettings" (rangeChanged)="endDateChanged($event)"></app-filter-date-range>
          </div>
          <div class="line-field">
            <div class="label-large">Stacking Group</div>
            <input type="text" [(ngModel)]="account.stackingGroup">
          </div>
          <div class="line-field">
            <div class="label-large">Filters</div>
            <button class="button" style="margin: 0 8px" (click)="addPurchaseQualifierFilter()">Add Filter</button>
          </div>
          <div class="filter-container" *ngFor="let filter of account.purchaseQualifier.filters; let i = index">
            <app-discount-filter [(filter)]="account.purchaseQualifier.filters[i]"></app-discount-filter>
            <button (click)="removePurchaseQualifierFilter(filter)">DELETE</button>
          </div>
  
          <!-- Item Qualifier -->
          <div class="field">
            <label>Item Qualifier</label>
          </div>
          <div class="line-field">
            <div class="label-large">Unit</div>
            <select [(ngModel)]="account.itemQualifier.unit">
              <option *ngFor="let option of unitOptionsQualifier" [value]="option._id" [selected]="account.itemQualifier.unit === option._id">{{option.name}}</option>
            </select>
          </div>
          <div class="line-field">
            <div class="label-large">Threshold</div>
            <input type="number" [(ngModel)]="account.itemQualifier.threshold" />
          </div>
          <div class="line-field">
            <div class="label-large">Allow Partial</div>
            <app-toggle (valueChanged)="toggleAllowPartial()" [checked]="account.itemQualifier.allowPartial"></app-toggle>
          </div>
          <div class="line-field">
            <div class="label-large">Filters</div>
            <button class="button" style="margin: 0 8px" (click)="addItemQualifierFilter()">Add Filter</button>
          </div>
          <div class="filter-container" *ngFor="let filter of account.itemQualifier.filters; let i = index">
            <app-discount-filter [(filter)]="account.itemQualifier.filters[i]"></app-discount-filter>
            <button (click)="removeItemQualifierFilter(filter)">DELETE</button>
          </div>
  
          <!-- Qualified -->
          <div class="field">
            <label>Qualified</label>
          </div>
          <div class="line-field">
            <div class="label-large">Unit</div>
            <select [(ngModel)]="account.itemQualified.unit">
              <option *ngFor="let option of unitOptionsQualified" [value]="option._id" [selected]="account.itemQualified.unit===option._id">{{option.name}}</option>
            </select>
          </div>
          <div class="line-field">
            <div class="label-large">Value</div>
            <input type="number" [(ngModel)]="account.itemQualified.value" />
          </div>
          <div class="line-field">
            <div class="label-large">Filters</div>
            <button class="button" style="margin: 0 8px" (click)="addQualifiedFilter()">Add Filter</button>
          </div>
          <div class="filter-container" *ngFor="let filter of account.itemQualified.filters; let i = index">
            <app-discount-filter [(filter)]="account.itemQualified.filters[i]"></app-discount-filter>
            <button (click)="removeQualifiedFilter(filter)">DELETE</button>
          </div>
  
        </ng-container>
  
        <!-- Dropdown Selections -->
        <ng-container *ngFor="let optionGroup of optionFields; let i = index">
          <div *ngIf="!optionGroup.opposingIdKey || !account[optionGroup.opposingIdKey] || account[optionGroup.opposingIdKey].length == 0" class="field">
            <app-dropdown [(optionGroup)]="optionFields[i]"
                (searchChanged)="search(optionGroup, $event)">
            </app-dropdown>
          </div>        
        </ng-container>
  
        <div class="buttons">
          <button class="button red" (click)="deleteDiscount()">Delete Discount</button>
          <button class="button green" (click)="saveDiscount()">Save Discount</button>
        </div>
      </div>
    </section>
  </div>
  