import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

// import * as moment from 'moment';

import { HistoryService } from '../../../services/history/history.service';
import { IoService } from '../../../services/io/io.service';
import { AuthorizationService } from '../../../services/authorization/authorization.service';
import { ReportService } from '../../../services/report/report.service';
import { MessageService } from '../../../services/message/message.service';

@Component({
  selector: 'app-detail-transaction',
  templateUrl: './detail-transaction.component.html',
  styleUrls: ['./detail-transaction.component.scss']
})
export class DetailTransactionComponent implements OnInit, OnDestroy {

    documentId$: Observable<any>;
    documentId;

    showBackButton;

    transaction;

    refunding;
    refundAmount;
    refundRequestAmount;

    sendingReceipt;

    invoiceLinkCopied;
    invoiceLinkCopyText = 'Copy Invoice Link';

    showStatusDetails;

    avsResponseCodes = {
      X: 'Match of address and 9-digit zip code',
      Y: 'Match of address and 5-digit zip code',
      W: 'Match of 9-digit zip code; address does not match',
      Z: 'Match of 5-digit zip code; address does not match',
      A: 'Address: Address Matches ZIP Does Not Match',
      N: 'No: Address and ZIP Do Not Match',
      G: 'Address information not verified',
      S: 'Service Not Supported: Issuer does not support address verification',
      U: 'Address information is unavailable',
      E: 'Error: Transaction ineligible for address verification',
      R: 'Retry: System Unavailable or Timeout'
    };

    cscResponseCodes = {
      M: 'The CSC matches the issuing bank’s records',
      N: 'The CSC does not match the issuing bank’s records',
      P: 'The CSC was not processed',
      S: 'The card should have a CSC, but merchant indicated it was not present',
      U: 'Card issuing bank does not participate',
      X: 'Unknown / No response'
    };

    types = {
      gift: {
        name: 'Gift Card'
      },
      billingAccount: {
        name: 'Billing Account'
      },
      credit: {
        name: 'Credit Card'
      },
      ach: {
        name: 'ACH'
      },
      invoice: {
        name: 'Invoice'
      },
      cash: {
        name: 'Cash'
      },
      check: {
        name: 'Check'
      },
      loyalty: {
        name: 'Loyalty'
      },
      resource: {
        name: 'Resource'
      },
      agreement: {
        name: 'Agreement'
      },
      inventory: {
        name: 'Inventory'
      },
      tax: {
        name: 'Tax'
      },
      discount: {
        name: 'Discount'
      }
    };

    private permissionsLoaded: Subscription;

    conversationActive;
    conversationActiveUpdated: Subscription;

    constructor(
      private location: Location,
      private route: ActivatedRoute,
      private router: Router,
      private historyService: HistoryService,
      private ioService: IoService,
      private authorizationService: AuthorizationService,
      private reportService: ReportService,
      private messageService: MessageService
    ) { }

    ngOnInit() {
      this.documentId$ = this.route.paramMap.pipe(switchMap(params => of(params.get('documentId'))));
      this.documentId$.subscribe(documentId => {
        if (documentId) {
          this.documentId = documentId;
          this.loadTransactionDetail();
        }
      });

      if (this.historyService.getPreviousLinkDepth() > 3) {
        this.showBackButton = true;
      }

      this.conversationActiveUpdated = this.messageService.conversationActiveUpdatedObservable().subscribe(conversationActive => {
        this.conversationActive = conversationActive;
      });
      this.conversationActive = this.messageService.getConversationActive();
    }

    ngOnDestroy() {
      if (this.permissionsLoaded) {
        this.permissionsLoaded.unsubscribe();
      }

      if (this.conversationActiveUpdated) {
        this.conversationActiveUpdated.unsubscribe();
      }
    }

    close() {
      this.router.navigate(['../../'], { relativeTo: this.route });
    }

    back() {
      this.location.back();
    }

    loadTransactionDetail() {
      console.log('loadTransactionDetail: ');
      this.transaction = null;
      // this.showStatusDetails = null;
      this.refunding = null;
      this.refundAmount = null;
      this.refundRequestAmount = null;

      this.ioService.post('/transaction/getTransaction', {
        transactionId: this.documentId
      }).then((transaction: any) => {
        console.log('transactionResponse: ', transaction);

        // if (transaction.responseDetails && transaction.responseDetails.avsResultCode) {
        //   transaction.responseDetails.avsResultDescription = this.avsResponseCodes[transaction.responseDetails.avsResultCode];
        // }
        //
        // if (transaction.responseDetails && transaction.responseDetails.cscResultCode) {
        //   transaction.responseDetails.cscResultDescription = this.cscResponseCodes[transaction.responseDetails.cscResultCode];
        // }

        this.transaction = transaction;


          // Make sure this user can refund credit card transactions here
          if (this.authorizationService.permissionsAreLoaded()) {
            this.checkTransactionPermissions();
          } else {
            this.permissionsLoaded = this.authorizationService.permissionsLoadedObservable().subscribe(() => {
              this.checkTransactionPermissions();
            });
          }

      });
    }

    checkTransactionPermissions() {


      if (this.authorizationService.checkPermission('admin', this.transaction.locationId) && this.transaction.type == 'inventory') {
        this.transaction.canNegate = true;
      }

      if (!this.transaction.canNegate && this.transaction.amount < 0 && (!this.transaction.relatedTransactionId || this.authorizationService.checkPermission('admin', this.transaction.locationId))) {
        this.transaction.canRefund = this.authorizationService.checkPermission('transaction.'+this.transaction.type+'.add', this.transaction.locationId);
        console.log('CAN REFUND!');
      }

      this.transaction.canSendReceipt = this.authorizationService.checkPermission('transaction.'+this.transaction.type+'.receipt', this.transaction.locationId);

    }

    showAccountDetails() {
      console.log('showAccountDetails');
      // if (this.transaction.viewAccountDetails && !this.transaction.fullCardNumber) {
      //   console.log('User is allowed to view account details');
      //   this.transaction.cardNumberLoading = true;
      //   this.ioService.post('/account/getAccountDetail', {
      //     accountId: this.transaction.accountId
      //   }).then((accountResponse: any) => {
      //     console.log('accountResponse: ', accountResponse);
      //     this.transaction.cardNumberLoading = false;
      //     if (accountResponse.account.cardNumbers && accountResponse.account.cardNumbers.length > 0) {
      //       this.transaction.fullCardNumber = accountResponse.account.cardNumbers[0];
      //     }
      //
      //     this.transaction.accountTransactions = accountResponse.account.transactions;
      //
      //   });
      // }
    }

    loadDetail(type, id) {
      this.router.navigate(['../../'+type+'/'+id], { relativeTo: this.route });
    }

    viewTransfer(purchaseId) {
      this.router.navigate(['../../../../transfers/'+purchaseId], { relativeTo: this.route });
    }


    toggleStatusDetails() {
      this.showStatusDetails = !this.showStatusDetails;
    }


    dateDiffDays(dt1, dt2) {
      return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
    }

    voidClick() {
      this.ioService.post('/transaction/'+this.transaction.type+'/void', {
        transactionId: this.transaction._id,
        locationId: this.transaction.locationId
      }).then((voidResponse: any) => {
        console.log('voidResponse: ', voidResponse);
        this.router.navigate(['../../'], { relativeTo: this.route });
        this.reportService.loadReport(null);
      });
    }

    checkPaymentStatus = async () => {
      let recheckResponse = this.ioService.post('/clearent/checkPaymentStatus', {
        transactionId: this.transaction._id
      });

      console.log('recheckResponse: ', recheckResponse);

      this.reportService.loadReport(null);
      this.loadTransactionDetail();
    }

    refundClick() {
      this.refundAmount = (this.transaction.amount*-1/100).toFixed(2);

    }

    refundRequest() {
      this.verifyRefundAmount();
      this.refundRequestAmount = Number(this.refundAmount).toFixed(2);
    }

    refundCancel() {
      this.refundRequestAmount = null;
    }

    refund() {
      if (!this.refunding) {
        this.refunding = true;

        let refundTransaction = {
          transactionId: this.transaction._id,
          locationId: this.transaction.locationId,
          amount: this.refundRequestAmount,
          terminalId: null
        };

        if (this.transaction.terminalId && this.transaction.type == 'cash') {
          refundTransaction.terminalId = this.transaction.terminalId;
        }

        this.ioService.post('/transaction/'+this.transaction.type+'/add', refundTransaction).then((refundResponse: any) => {
          console.log('refundResponse: ', refundResponse);
          this.router.navigate(['../../'], { relativeTo: this.route });
          this.reportService.loadReport(null);
        });
      }
    }

    negateTransaction = async () => {
      if (!this.refunding) {
        this.refunding = true;
        let action = 'subtract';
        if (this.transaction.amount < 0) {
          action = 'add';
        }


        let negateTransaction = {
          transactionId: this.transaction._id,
          locationId: this.transaction.locationId,
          amount: this.transaction.amount*-1,
          accountSubtype: this.transaction.accountSubtype,
          subtype: 'manual',
          purchaseId: this.transaction.purchaseId,
          purchaseKey: this.transaction.purchaseKey,
          parentAccountId: this.transaction.parentAccountId,
          name: this.transaction.name,
          purchaseType: this.transaction.purchaseType,
        };

        for (let fieldKey of [
          'cost',
          'directCost',
          'price',
          'directPrice'
        ]) {
          if (this.isNumeric(this.transaction[fieldKey])) {
            negateTransaction[fieldKey] = this.transaction[fieldKey]*-1;
          }
        }

        let negateResponse = await this.ioService.post('/transaction/'+this.transaction.type+'/'+action, negateTransaction);

        this.router.navigate(['../../'], { relativeTo: this.route });
        this.reportService.loadReport(null);
      }
    }

    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    }

    verifyRefundAmount() {
      if (this.refundAmount > this.transaction.amount*-1/100) {
        this.refundAmount = this.transaction.amount*-1/100;
      }
      if (this.refundAmount <= 0) {
        this.refundAmount = this.transaction.amount*-1/100;
      }
    }

    sendReceipt() {
      if (!this.sendingReceipt) {
        this.sendingReceipt = true;
        this.ioService.post('/receipt/send', {
          transactionId: this.transaction._id,
          email: this.transaction.email
        }).then((receiptResponse: any) => {
          console.log('receiptResponse: ', receiptResponse);
          this.sendingReceipt = false;
        });
      }
    }

    cancelInvoice() {
      this.ioService.post('/transaction/invoice/subtract', {
        transactionId: this.transaction._id,
        locationId: this.transaction.locationId,
        amount: -1
      }).then((transactionResponse: any) => {
        console.log('transactionResponse: ', transactionResponse);
        this.loadTransactionDetail();

      }).catch((transactionError: any) => {
        console.log('transactionError: ', transactionError);
      });
    }

    resendInvoice() {
      this.ioService.post('/transaction/invoice/add', {
        transactionId: this.transaction._id,
        locationId: this.transaction.locationId,
        amount: this.transaction.attemptedAmount
      }).then((transactionResponse: any) => {
        console.log('transactionResponse: ', transactionResponse);
        this.close();
      }).catch((transactionError: any) => {
        console.log('transactionError: ', transactionError);
      });
    }

    openInvoiceExternally() {
      window.open("https://invoice.redfirepay.com?locationId="+this.transaction.locationId+"&invoiceKey="+this.transaction.invoiceKey, "_blank");
    }

    copyInvoiceLink() {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = "https://invoice.redfirepay.com?locationId="+this.transaction.locationId+"&invoiceKey="+this.transaction.invoiceKey;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);

      this.invoiceLinkCopied = true;
      let originalBtnText = this.invoiceLinkCopyText;
      this.invoiceLinkCopyText = 'Copied!';
      setTimeout(() => {
        this.invoiceLinkCopied = false;
        this.invoiceLinkCopyText = originalBtnText;
      }, 3000);
    }

    viewHistory() {
      // this.reportService.setActiveReport(reportConfig, false);
      // this.reportService.setReportByKey('transactons', false);
      this.reportService.loadFilteredReport('gift-history', [
        {
          name: 'Account ID',
          path: 'accountId',
          type: 'id',
          value: this.transaction.accountId
        }
      ]);
    }

}
