import { NgModule } from '@angular/core';
import { CommonModule, JsonPipe } from '@angular/common';

// import { MatDialogModule, MatFormFieldModule, MatButtonModule, MatInputModule } from '@angular/material';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { QuickToggleComponent } from './toggle/toggle.component';

// Router
import { AppRoutingModule } from '../../app-routing.module';

// Pipes
import { RfPipe } from '../../pipes/rf/rf.pipe';

// Components
import { HeaderComponent } from './header/header.component';
import { SupportComponent } from './support/support.component';
import { MessagingComponent } from './messaging/messaging.component';

import { ConversationComponent } from './../report/conversation/conversation.component';
import { DropdownComponent } from './ui/dropdown/dropdown.component';
import { LoadingComponent } from './loading/loading.component';
import { ToggleComponent } from './ui/toggle/toggle.component';
import { DialogComponent } from './ui/dialog/dialog.component';
import { TabpageComponent } from './ui/tabs/tabpage/tabpage.component';
import { TabsComponent } from './ui/tabs/tabs.component';
import { AuditComponent } from './audit/audit.component';
import { ScrollingModule, ScrollDispatcher, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AuditItemComponent } from './audit/audit-item/audit-item.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ValueCompareComponent } from './audit/value-compare/value-compare.component';
import {ClipboardModule} from '@angular/cdk/clipboard';

@NgModule({
  declarations: [
    RfPipe,
    HeaderComponent,
    SupportComponent,
    MessagingComponent,
    ConversationComponent,
    DropdownComponent,
    LoadingComponent,
    ToggleComponent,
    DialogComponent,
    TabpageComponent,
    TabsComponent,
    AuditComponent,
    AuditItemComponent,
    ValueCompareComponent,
    QuickToggleComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ScrollingModule,
    MatTableModule,
    MatTooltipModule,
    ClipboardModule,
  ],
  exports: [
    RfPipe,
    HeaderComponent,
    ConversationComponent,
    DropdownComponent,
    LoadingComponent,
    ToggleComponent,
    TabsComponent,
    TabpageComponent,
    AuditComponent,
    ScrollingModule,
    MatTableModule,
    MatTooltipModule,
    ClipboardModule,
    QuickToggleComponent
  ],
  providers: [
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {disableClose: true, hasBackdrop: true}},
    RfPipe,
    JsonPipe
  ]
})
export class SharedModule { }
