import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from '../../app-routing.module';

import { FormsModule } from '@angular/forms';

import { SignInComponent } from './sign-in/sign-in.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RegisterComponent } from './register/register.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { SetClientComponent } from './set-client/set-client.component';
import { RemoteAccessComponent } from './remote-access/remote-access.component';

@NgModule({
  declarations: [
    SignInComponent,
    ResetPasswordComponent,
    RegisterComponent,
    SetPasswordComponent,
    SetClientComponent,
    RemoteAccessComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FormsModule
  ]
})
export class AuthenticationModule { }
