import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';

import { IoService } from '../../services/io/io.service';
import { LocationService } from '../../services/location/location.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  testRoute = '/transaction/gift/check';
  permission = '';

  conditionalPermissions = {
    live: false,
    action: 'conditionalGrant',
    findKey: 'preauth',
    changeKey: 'pinin'
  };

  // importConfig = {
  //   minId: 0,
  //   limit: 1
  // };

  // promoConfig = {
  //   name: 'Loyalty Reward',
  //   description: 'Automatic Loyalty Reward',
  //   amount: 68,
  //   validDayCount: 365,
  //   locationId: null
  // };

  // unimportingTransactions;

  socketInput = '';
  continuousTesting = false;
  continuousTestingTimeout;
  socketSpeed = 1;

  trafficSimulator = {
    purchaseCount: 0,
    averageSalesPerMinute: 30,
    taxes: [],
    inventoryItems: [],
    purchaseTimeout: null
  };

  @ViewChild('unimport') unimportEl: ElementRef<HTMLElement>;

  constructor(
    private ioService: IoService,
    private locationService: LocationService
  ) { }

  ngOnInit() {
  }


  conditionalPermissionsUpdate(live = false) {
    this.conditionalPermissions.live = live;
    console.log('conditionalPermissionsUpdate: ');
    console.log(this.conditionalPermissions);

    this.ioService.post('/authorization/'+this.conditionalPermissions.action, this.conditionalPermissions).then((result: any) => {
      console.log(result);
    }).catch((err: any) => {
      alert(err.error ? err.error.msg : JSON.stringify(err));
    });
  }

  postToRoute() {
    console.log(this.testRoute);
    this.ioService.post(this.testRoute, {
      locationId: this.locationService.getActiveLocation()._id,
      cardNumber: '7784510002457151992',
      amount: 1
    }).then((result: any) => {
      console.log(result);
    }).catch((err: any) => {
      console.log(err.error.msg);
    });
  }

  testSocket = async () => {
    console.log(this.socketInput);

    let inputJson;
    try {
      inputJson = JSON.parse(this.socketInput);
    } catch (err) {
      inputJson = {
        message: this.socketInput
      };
    }

    console.log('inputJson: ', inputJson);

    let testResponse = this.ioService.post('/socket/testEvent', inputJson);
    console.log('testResponse: ', testResponse);
  }

  continuousSocketTest = () => {
    this.continuousTesting = !this.continuousTesting;

    if (this.continuousTesting) {
      this.testSocket();
      this.runRepeatedTest();
    } else {
      this.stopRepeatedTest();
    }
  }

  runRepeatedTest = () => {
    if (this.continuousTestingTimeout) {
      clearTimeout(this.continuousTestingTimeout);
    }

    let socketSpeed;
    try {
      socketSpeed = parseFloat(String(this.socketSpeed));
    } catch (err) {
      socketSpeed = 1;
    }

    if (socketSpeed < 0.1) {
      socketSpeed = 1;
    }

    let waitDuration = Math.random() * 1000 / socketSpeed;

    this.continuousTestingTimeout = setTimeout(() => {
      this.testSocket();
      this.runRepeatedTest();
    }, waitDuration);
  }

  stopRepeatedTest = () => {
    if (this.continuousTestingTimeout) {
      clearTimeout(this.continuousTestingTimeout);
    }
  }

  startTrafficSimulator = async () => {
    let locationId = this.locationService.getActiveLocationId();

    if (!locationId) {
      console.log('No active location, using first location');

      let locations = this.locationService.getLocations().filter(location => {
        return location.type === 'retail';
      });

      locationId = locations[0]._id;
    }

    let terminal = await this.registerTerminal(locationId);

    await this.loadTaxes(locationId);

    await this.loadInventoryItems(locationId);
    console.log('loaded inventory items: ', this.trafficSimulator.inventoryItems.length);

    // await this.buildSamplePurchase(terminal);
    this.simulatePurchase(terminal);
  }

  stopTrafficSimulator = () => {
    if (this.trafficSimulator.purchaseTimeout) {
      clearTimeout(this.trafficSimulator.purchaseTimeout);
    }
  }

  simulatePurchase = async (terminal) => {
    try {
      await this.buildSamplePurchase(terminal);
    } catch (err) {
      console.log('Error building purchase: ', err);
    }

    let waitDuration = Math.random() * 1000 * 60 / this.trafficSimulator.averageSalesPerMinute;
    this.trafficSimulator.purchaseTimeout = setTimeout(async () => {
      await this.simulatePurchase(terminal);
      this.trafficSimulator.purchaseCount++;
    }, waitDuration);
  }

  registerTerminal = async (locationId) => {
    let deviceUUID = Math.random().toString(36).substring(7);

    let terminal: any = await this.ioService.post('/terminal/registerTerminal', {
      locationId,
      type: "simulator",
      terminalUUID: deviceUUID,
      deviceInfo: {
        name: 'Traffic Simulator',
      }
    });

    terminal.deviceUUID = deviceUUID;

    return terminal;
  }

  loadTaxes = async (locationId) => {
    let taxes: any = await this.ioService.post('/account/getAccounts', {
      type: 'tax',
      locationId
    });

    this.trafficSimulator.taxes = taxes;
  }

  loadInventoryItems = async (locationId) => {
    let inventoryAccounts: any = await this.ioService.post('/inventory/getInventoryAccounts', {
      locationId,
      subtype: 'retail'
    });
    this.trafficSimulator.inventoryItems = inventoryAccounts.filter(account => {
      return account.ise;
    });
  }

  buildSamplePurchase = async (terminal) => {
    console.log('terminal: ', terminal);
    let purchase: any = await this.ioService.post('/purchase/updatePurchase', {
      locationId: terminal.locationId,
      loadNewAfterSync: false,
      terminalId: terminal._id,
      terminalUUID: terminal.deviceUUID,
    });

    purchase.taxable = {};
    purchase.taxDetail = {};

    // console.log('purchase: ', purchase);

    let numItems = Math.floor(Math.random() * 5) + 1;
    // numItems = 1;

    for (let i=0; i<numItems; i++) {
      let item = this.trafficSimulator.inventoryItems[Math.floor(Math.random() * this.trafficSimulator.inventoryItems.length)];
      let qty = Math.floor(Math.random() * 2) + 1;
      // qty = 1;

      let itemRecord = {
        accountId: item._id,
        name: item.name,
        price: item.price,
        qty,
        taxDetail: {},
        taxable: {},
      };

      for (let tax of this.trafficSimulator.taxes) {
        if (item.taxId.indexOf(tax._id) !== -1) {
          itemRecord.taxable[tax._id] = item.price * qty;
          itemRecord.taxDetail[tax._id] = item.price * qty * tax.percentage;

          if (!purchase.taxable[tax._id]) {
            purchase.taxable[tax._id] = 0;
          }

          if (!purchase.taxDetail[tax._id]) {
            purchase.taxDetail[tax._id] = 0;
          }

          purchase.taxable[tax._id] += itemRecord.taxable[tax._id];
          purchase.taxDetail[tax._id] += itemRecord.taxDetail[tax._id];

          purchase.taxes += itemRecord.taxDetail[tax._id];
        }
      }

      purchase.items.push(itemRecord);

      purchase.subtotal += item.price * qty;
    }

    if (purchase.taxes > 0) {
      //Round taxes up
      purchase.taxes = Math.ceil(purchase.taxes);
    }

    purchase.total = purchase.subtotal + purchase.taxes;
    purchase.due = purchase.total;

    console.log('purchase built: ', purchase);

    let result = await this.ioService.post('/purchase/updatePurchase', {
      locationId: terminal.locationId,
      loadNewAfterSync: false,
      purchase
    });

    // Apply a cash payment

    let paymentResponse = await this.ioService.post('/transaction/cash/subtract', {
      amount: purchase.due/100,
      change: "0.00",
      locationId: terminal.locationId,
      purchaseId: purchase._id,
      softwareType: "Red Fire Traffic Simulator",
      softwareTypeVersion: "0.2409.0",
      terminalId: terminal._id,
      terminalUUID: terminal.deviceUUID,
    });

    return purchase;
  }


  // patchAccountsType() {
  //   console.log('patchAccountsType: ');
  //   this.ioService.post('/account/patchAccountsType', {}).then((result: any) => {
  //     console.log(result);
  //   });
  // }

  // patchAccountsLocationId() {
  //   console.log('patchAccountsLocationId: ');
  //   this.ioService.post('/account/patchAccountsLocationId', {}).then((result: any) => {
  //     console.log(result);
  //   });
  // }

  // patchCustomerPhone() {
  //   console.log('patchCustomerPhone: ');
  //   this.ioService.post('/customer/patchCustomerPhone', {}).then((result: any) => {
  //     console.log(result);
  //   });
  // }

  // authorizeRoot() {
  //   console.log('authorizeRoot: '+this.permission);
  //   this.ioService.post('/authorization/authorizeRoot', {
  //     permission: this.permission
  //   }).then((result: any) => {
  //     console.log(result);
  //   }).catch((err: any) => {
  //     console.log(err);
  //   });
  // }



  // postToRepeatedly() {
  //   setInterval(() => {
  //     this.ioService.post(this.testRoute, {
  //       locationId: this.locationService.getActiveLocation()._id,
  //       cardNumber: '7784510002457151992',
  //       amount: Math.random()
  //     }).then((result: any) => {
  //       console.log(result);
  //     }).catch((err: any) => {
  //       console.log(err.error.msg);
  //     });
  //   }, 500);
  // }

  // generateMonthEnd() {
  //   let d = new Date();
  //   let newMonth = d.getMonth() - 1;
  //   if (newMonth < 0){
  //       newMonth += 12;
  //       d.setFullYear(d.getFullYear() - 1);
  //   }
  //   d.setMonth(newMonth);

  //   this.ioService.post('/boardandbrush/generateMonthEndCombined', {
  //     date: d,
  //     locationId: this.locationService.getActiveLocation()._id,
  //   }).then((result: any) => {
  //     console.log(result);
  //   }).catch((err: any) => {
  //     console.log(err);
  //   });
  // }

  // remoteExport() {
  //   this.ioService.post('/boardandbrush/remoteExport', {
  //     locationId: this.locationService.getActiveLocation()._id,
  //     minId: this.importConfig.minId,
  //     limit: this.importConfig.limit
  //   }).then((result: any) => {
  //     console.log(result);
  //   }).catch((err: any) => {
  //     console.log(err);
  //   });
  // }

  // addRemotePromo() {
  //   this.promoConfig.locationId = this.locationService.getActiveLocation()._id;
  //   this.ioService.post('/boardandbrush/addRemotePromo', this.promoConfig).then((result: any) => {
  //     console.log(result);
  //   }).catch((err: any) => {
  //     console.log(err);
  //   });
  // }

  // closeBatch() {
  //   this.ioService.post('/batch/closeBatch', {
  //     locationId: this.locationService.getActiveLocation()._id
  //     // locationId: '5c9d35f658fc3c0324a2eeca' // Gotham City
  //   }).then((result: any) => {
  //     console.log(result);
  //   }).catch((err: any) => {
  //     console.log(err);
  //   });
  // }


  // unimportTransactions() {
  //   let el: HTMLElement = this.unimportEl.nativeElement;
  //   el.click();
  // }

  // onFileChange(event) {
  //   this.unimportingTransactions = true;
  //   if (event.target.files.length > 0) {
  //     const file = event.target.files[0];
  //     this.ioService.upload('/import/unimport-transactions', {
  //       locationId: this.locationService.getActiveLocation()._id
  //     }, file).subscribe(event => {
  //       console.log(event);
  //     });
  //   }
  // }

}
